import { Dropdown, Space, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  ApprovalStatusEnum,
  GmbPostType,
  MenuActionContent,
  PermissionSocial,
  PlanUsageType,
  PlatformStatusType,
  PostStatusType,
  SocialExtendReelType,
  SocialReelType,
  SocialType,
} from "../../../../../../constants/app.enums";
import { ROUTE_PATHS } from "../../../../../../constants/router.constants";
import useClient from "../../../../../../hooks/useClient";
import useClientPlan from "../../../../../../hooks/useClientPlan";
import {
  ContentModel,
  SocialContentModel,
  TagModel,
} from "../../../../../../models/content.model";
import { NetworkModel } from "../../../../../../models/social.model";
import { GeneralSettingItems } from "../../../../../../pages/general-settings";
import { planState } from "../../../../../../states/plan";
import { listProfileSelector } from "../../../../../../states/social";
import DateUtils from "../../../../../../utils/date.utils";
import SVGIcons from "../../../../../icons/svgs";
import AvatarSocial from "../avatar-social";
import ListMedia from "../list-media";
import TagSocial from "../tag-social";
import "./index.scss";

interface SocialContentModelExtend extends SocialContentModel {
  userImage: string;
  platform: SocialType;
  platformExtenReelType: SocialExtendReelType;
  profile?: NetworkModel;
}

interface ContentCardProps {
  onAction?: (action: MenuActionContent) => void;
  contentInfo: ContentModel;
  isApprovalView?: boolean;
}

const ContentCard = (props: ContentCardProps) => {
  const { onAction, contentInfo, isApprovalView } = props;
  const { hasPermissionSocial, getRouter } = useClient();
  const listProfile = useRecoilValue(listProfileSelector);
  const [contentSelected, setContentSelected] =
    useState<SocialContentModelExtend>();
  const [listSocialContent, setListSocialContent] = useState<
    SocialContentModelExtend[]
  >([]);
  const { showUpgradeModal, isPlanExpired, checkPremiumPlan } = useClientPlan();

  const {
    isSyncContent,
    platformContents,
    tags,
    date,
    status,
    approvalStatus,
    errorMessage: errorMessageContent,
  } = contentInfo;
  const { client } = useClient();

  const onClickMenu = (menuItem: any) => {
    const { key } = menuItem;
    if (key === MenuActionContent.SHARE && isPlanExpired) {
      showUpgradeModal();
    } else if (
      key === MenuActionContent.PUBLISH ||
      key === MenuActionContent.SCHEDULE
    ) {
      checkPremiumPlan(() => {
        onAction && onAction(key);
      }, PlanUsageType.PostPerCom);
    } else {
      onAction && onAction(key);
    }
  };

  const isHiddenScheduleAndPublish = (): boolean => {
    return (
      !listProfile?.length ||
      [PostStatusType.Error, PostStatusType.Posted].includes(status)
    );
  };

  const isContentFailWithoutApproval =
    errorMessageContent ===
    "Content failed to publish as scheduled date has passed without approval";

  const menuAction = {
    items: [
      { key: MenuActionContent.EDIT, label: "Edit Content" },
      {
        key: MenuActionContent.PUBLISH,
        label: "Publish Now",
        ishidden: (
          isHiddenScheduleAndPublish() ||
          !hasPermissionSocial(PermissionSocial.CanManage) ||
          (approvalStatus !== ApprovalStatusEnum.None &&
            approvalStatus !== ApprovalStatusEnum.Approved)
        ).toString(),
      },
      {
        key: MenuActionContent.SCHEDULE,
        label: "Schedule",
        ishidden: (
          isHiddenScheduleAndPublish() ||
          !hasPermissionSocial(PermissionSocial.CanManage)
        ).toString(),
      },
      { key: MenuActionContent.SHARE, label: "Share Link" },
      {
        key: MenuActionContent.DELETE,
        label: "Delete Content",
        danger: true,
        ishidden: (!hasPermissionSocial()).toString(),
      },
    ].filter((item) => item.ishidden !== "true"),
    onClick: onClickMenu,
  };

  const {
    post,
    medias,
    errorMessage,
    status: statusPlatform,
    platform,
    isReels,
    gmbPostType,
    gmbStartDate,
    gmbEndDate,
    gmbTitle,
  } = { ...contentSelected };

  const notRequireMedias =
    (platform === SocialType.Facebook && !isReels) ||
    platform === SocialType.Google;

  const renderListMedia = useCallback(() => {
    return !!medias?.length ? (
      <div className="content-card-bottom__media">
        <ListMedia.ListMediaContentCard
          listMedia={medias || []}
          isContentCard={true}
        />
      </div>
    ) : (
      <div className="content-card-bottom__media">
        {notRequireMedias ? (
          <div className="empty">No media yet</div>
        ) : (
          <div className="empty bg-warning">
            <SVGIcons.AlertCircle />
            <span>Requires at least 1 media.</span>
          </div>
        )}
      </div>
    );
  }, [medias]);

  const renderPostStatus = useCallback(() => {
    let statusIcon = null;
    let cln = "";
    let tooltip = "";
    let text = "";
    switch (status) {
      case PostStatusType.Drafted:
        statusIcon = <SVGIcons.DraftIcon />;
        cln = "draft";
        tooltip = "This content has been saved as draft";
        text = "Draft";
        break;
      case PostStatusType.Scheduled:
        statusIcon = <SVGIcons.ClockHour4 />;
        cln = "schedule";
        tooltip = "This content is scheduled";
        text = DateUtils.getDateWithTimezone(
          date,
          client?.timeZoneInfo?.id
        ).format("ddd, MMM DD, h:mma");
        break;
      case PostStatusType.Posted:
        statusIcon = <SVGIcons.SendIcon />;
        cln = "post";
        tooltip = "This content was published";
        text = DateUtils.getDateWithTimezone(
          date,
          client?.timeZoneInfo?.id
        ).format("ddd, MMM DD, h:mma");
        break;
      case PostStatusType.Error:
        statusIcon = <SVGIcons.AlertTriangleIcon />;
        cln = "error";
        if (isContentFailWithoutApproval) {
          statusIcon = <SVGIcons.ClockHour4 />;
          text = DateUtils.getDateWithTimezone(
            date,
            client?.timeZoneInfo?.id
          ).format("ddd, MMM DD, h:mma");
          tooltip = "Scheduled date has already passed without an approval";
        } else text = "Error";
        break;
      default:
        break;
    }

    return (
      <Tooltip title={tooltip}>
        <div className={`post-status ${cln}`}>
          {statusIcon}
          {text}
        </div>
      </Tooltip>
    );
  }, [contentInfo]);

  const renderApprovalStatus = useCallback(() => {
    let statusIcon = null;
    let cln = "";
    let tooltip = "";
    let text = "";
    switch (approvalStatus) {
      case ApprovalStatusEnum.None:
        statusIcon = <SVGIcons.UserMinusIcon />;
        cln = "not_required";
        tooltip = "Approval not required";
        text = "Not Required";
        break;
      case ApprovalStatusEnum.Pending:
        statusIcon = <SVGIcons.UserExclamationIcon />;
        cln = "pending";
        tooltip = "Pending approval";
        text = "Pending";
        break;
      case ApprovalStatusEnum.Approved:
        statusIcon = <SVGIcons.UserCheckIcon />;
        cln = "approved";
        tooltip = "Content approved";
        text = "Approved";
        break;
      case ApprovalStatusEnum.Declined:
        statusIcon = <SVGIcons.UserXIcon />;
        cln = "declined";
        tooltip = "Content declined";
        text = "Declined";
        break;
      default:
        break;
    }
    return (
      <Tooltip title={tooltip}>
        <div className={`approval-status ${cln}`}>
          {statusIcon}
          {text}
        </div>
      </Tooltip>
    );
  }, [contentInfo]);
  const renderPost = () => {
    if (
      platform === SocialType.Google &&
      (gmbPostType === GmbPostType.EVENT || gmbPostType === GmbPostType.OFFER)
    ) {
      return (
        <>
          <div className="gmb-event-title">{gmbTitle}</div>
          <div>
            {moment(gmbStartDate).format(
              gmbPostType === GmbPostType.EVENT ? "MMM DD, hh:mm a" : "MMM DD"
            )}{" "}
            -{" "}
            {moment(gmbEndDate).format(
              gmbPostType === GmbPostType.EVENT ? "MMM DD, hh:mm a" : "MMM DD"
            )}
          </div>
        </>
      );
    }
    if (!post) return <span className="no-content">No content</span>;
    return post?.split(/\n/).map((line: any, index) => {
      let str = line;
      str = str.replace(/(<)/gi, "&lt;");
      str = str.replace(/(<)/gi, "&lg;");
      str = str.replace(/(?:\r\n|\n\r|\r|\n)/g, " <br />");
      str = str.replace(/#(.+?)(?=[\s.,:,]|$)/g, "<a>#$1</a>");
      str = str.replace(/@(.+?)(?=[\s.,:,]|$)/g, "<a>@$1</a>");
      str = str.replace(
        /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/g,
        "<a>$1</a>"
      );
      return (
        <React.Fragment key={index}>
          <span dangerouslySetInnerHTML={{ __html: str }}></span>
          <br />
        </React.Fragment>
      );
    });
  };

  const getProfileSettingLink = () => {
    const link = getRouter(ROUTE_PATHS.GeneralSettings);
    return `${link}?tab=${GeneralSettingItems.socialProfiles}`;
  };

  useEffect(() => {
    const listContent: SocialContentModelExtend[] = platformContents.map(
      (content) => {
        const profile = listProfile?.find(
          (profile) => profile.platform === content.platform
        );
        return {
          ...content,
          profile,
          userImage: profile?.userImage || "",
          status: profile ? content.status : PlatformStatusType.Error,
          platformExtenReelType: content.isReels
            ? content.platform === SocialType.Facebook
              ? SocialReelType.FacebookReel
              : SocialReelType.InstagramReel
            : content.platform,
        };
      }
    );
    const firstContentError = listContent.find(
      (c) => c.status === PlatformStatusType.Error
    );
    setContentSelected(firstContentError || listContent[0]);
    setListSocialContent(listContent);
  }, [contentInfo]);
  return (
    <div
      className={`content-card ${
        status === PostStatusType.Error && !isContentFailWithoutApproval
          ? "content-card-error"
          : ""
      }`}
    >
      <div className="content-card-top">
        <div className="content-card-top__action">
          <div className="content-card-top__action-left">
            {renderPostStatus()}
            {!isApprovalView && renderApprovalStatus()}
          </div>
          {!isApprovalView && (
            <div className="content-card-top__action-right">
              <Dropdown
                menu={menuAction}
                trigger={["click"]}
                placement="bottomRight"
              >
                <div>
                  <SVGIcons.DotCircleHorizontalIcon />
                </div>
              </Dropdown>
            </div>
          )}
        </div>

        <div className="content-card-top__tags">
          {tags?.length ? (
            tags?.map((tag: TagModel) => {
              return (
                <TagSocial text={tag.name} color={tag.color} key={tag.id} />
              );
            })
          ) : (
            <TagSocial text={"No tag"} color="#9b9b9b" />
          )}
        </div>
        <div className="content-card-top__network">
          <Space>
            {listSocialContent
              .filter((content) => {
                if (isSyncContent && content.isReels) return false;
                return true;
              })
              .map((socialContent: SocialContentModelExtend, index) => {
                return (
                  <div
                    onClick={() => setContentSelected(socialContent)}
                    key={index}
                  >
                    <AvatarSocial
                      avatar={socialContent?.userImage || ""}
                      socialType={socialContent.platform}
                      disabled={
                        socialContent.platformExtenReelType !==
                        contentSelected?.platformExtenReelType
                      }
                      key={socialContent.platformExtenReelType}
                      size={39}
                    />
                  </div>
                );
              })}
          </Space>
          {statusPlatform === PlatformStatusType.Error && (
            <Tooltip
              title={
                contentSelected?.profile ? (
                  <span
                    dangerouslySetInnerHTML={{ __html: errorMessage || "" }}
                  ></span>
                ) : (
                  <span>
                    Content will be saved as draft and can only be published
                    after you{" "}
                    <Link
                      style={{ color: "#ffffff", textDecoration: "underline" }}
                      to={getProfileSettingLink()}
                    >
                      connect a profile.
                    </Link>
                  </span>
                )
              }
              placement="right"
            >
              <div className="warning-limit">
                <SVGIcons.AlertCircle />
              </div>
            </Tooltip>
          )}
        </div>
        <div className="content-card-top__caption">
          <div className="caption">{renderPost()}</div>
        </div>
      </div>
      <div className="content-card-bottom">
        {renderListMedia()}
        {!isApprovalView && (
          <div className="content-card-bottom__metric">
            <Tooltip title={"Number of reactions"}>
              <div className="content-card-bottom__metric-item like">
                <SVGIcons.ThumbUpIcon /> {contentSelected?.likeCount ?? 0}
              </div>
            </Tooltip>
            <Tooltip title="Number of comments">
              <div className="content-card-bottom__metric-item comment">
                <SVGIcons.MessageCircleIcon />{" "}
                {contentSelected?.commentsCount ?? 0}
              </div>
            </Tooltip>
            <Tooltip title="Number of shares">
              <div className="content-card-bottom__metric-item share">
                <SVGIcons.ArrowBackUpIcon /> {contentSelected?.sharesCount ?? 0}
              </div>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContentCard;
