import { Table } from "antd";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { DATE_TIME_FORMAT } from "../../../../../constants/app-constants";
import useClient from "../../../../../hooks/useClient";
import useZoho from "../../../../../hooks/useZoho";
import { RecentBookedDemoModel } from "../../../../../models/zoho.model";
import DateUtils from "../../../../../utils/date.utils";
import SpinDiv from "../../../../common/spin-div";


function RecentBookedDemoTable() {
    const { recentBookedDemo } = useZoho();
    const { t } = useTranslation();
    const { client } = useClient();

    const fakeData: RecentBookedDemoModel[] = [
        {
            leadName: "",
            companyName: "",
            phone: "",
            bookingDate: "",
            demoDate: "",
        }
    ];

    const isEmpty = recentBookedDemo?.data?.length === 0;

    const columns = [
        {
            title: t("salesAnalytics.leadName"),
            dataIndex: "leadName",
            key: "leadName",
            render: (value: string) => {
                if (!value) return "-";
                return value
            },
        },
        {
            title: t("salesAnalytics.company"),
            dataIndex: "companyName",
            key: "companyName",
            render: (value: string) => {
                if (!value) return "-";
                return value
            },
        },
        {
            title: t("salesAnalytics.phone"),
            dataIndex: "phone",
            key: "phone",
            render: (value: string) => {
                if (!value) return "-";
                return value
            },
        },
        {
            title: t("salesAnalytics.bookingDate"),
            dataIndex: "bookingDate",
            key: "bookingDate",
            render: (value: string) => {
                if (!value) return "-";
                return DateUtils.getDateWithTimezone(
                    value,
                    client?.timeZoneInfo?.id
                ).format(DATE_TIME_FORMAT.dateTimeViewFormatPM);
            },
        },
        {
            title: t("salesAnalytics.demoAppointmentDate"),
            dataIndex: "demoDate",
            key: "demoDate",
            render: (value: string) => {
                if (!value) return "-";
                return DateUtils.getDateWithTimezone(
                    value,
                    client?.timeZoneInfo?.id
                ).format(DATE_TIME_FORMAT.dateTimeViewFormatPM);
            },
        },
    ];

    return (
        <SpinDiv loading={recentBookedDemo.loading} style={{ height: 230 }}>
            <Table
                className="main-table"
                columns={columns}
                dataSource={isEmpty ? fakeData : recentBookedDemo.data}
                pagination={false}
                rowKey={(record: RecentBookedDemoModel) => `${record.leadName}-${record.companyName}-${record.phone}-${record.bookingDate}-${record.demoDate}`}
            />
        </SpinDiv>
    )
}

export default memo(RecentBookedDemoTable);
