import { Modal, Pagination, Spin, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SocialApi } from "../../../../apis/social.api";
import useClient from "../../../../hooks/useClient";
import { GetListMediaParams, MediaModel } from "../../../../models/content.model";
import SVGIcons from "../../../icons/svgs";
import MediaSocial from "../../social/social-content/components/media-social";
import UploadMedia, { getExtensionIcon } from "../components/upload-media";
import "./index.scss";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  FileSelectedState,
  ListMediaSelectedState,
  MediaLibraryState,
} from "../../../../states/media-library";
import { MediaLibraryApi } from "../../../../apis/media-library.api";
import showNotification from "../../../common/notification";
import { CommonUtils } from "../../../../utils/common";
import MediaUtils from "../../../../utils/media.utils";
import { MediaType } from "../components/media-library-header";
import AppLightbox from "../../../common/light-box";
import { contentValueState } from "../../../../states/social";
import { useTriggerReloadUsageStorage } from "../../../../hooks/useTriggerReloadUsageStorage";
import { MediaExtensions, MediaFileType } from "../../../../constants/app.enums";

const MEDIA_ITEM_HEIGHT = 150;
const MEDIA_ITEM_PADDING = 10;
const MEDIA_ITEM_MIN_WIDTH = 100;
const MEDIA_LIST_WIDTH = 710;

interface Props {
  mediaType: MediaType;
  searchMediaKeyword: string;
}

const MyFile = (props: Props) => {
  const { mediaType, searchMediaKeyword } = props;
  const { t } = useTranslation();
  const { clientId } = useClient();
  const [listMediaSelected, setListMediaSelected] = useRecoilState(ListMediaSelectedState);
  const { triggerReloadStorage } = useTriggerReloadUsageStorage()
  const [loadingDelete, setLoadingDelete] = useState(false);
  const setFileSelected = useSetRecoilState(FileSelectedState);
  const [mediaLibraryState, setMediaLibraryState] =
    useRecoilState(MediaLibraryState);
  const {
    data: { records: listMedia, totalRecords },
    loading,
  } = mediaLibraryState;
  const [pageIndex, setPageIndex] = useState(1);
  const pageSize = 15;
  const [totalRecordsPagination, setTotalRecordsPagination] = useState(0);
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [lightBoxStartIndex, setLightBoxStartIndex] = useState(0);
  const listMediaRef = useRef<HTMLDivElement>(null);
  const [contentValue, setContentValue] = useRecoilState(contentValueState);

  const getListMedia = async (page: number, keyword = "") => {
    setMediaLibraryState({ ...mediaLibraryState, loading: true });
    try {
      const params: GetListMediaParams = {
        pageIndex: page,
        pageSize: pageSize,
        clientId: clientId,
        keyword: keyword
      }

      switch (mediaType) {
        case MediaType.videos:
          params.mediaType = MediaFileType.Videos;
          break;
        case MediaType.images:
          params.mediaType = MediaFileType.Images;
          break;
        case MediaType.other:
          params.mediaType = MediaFileType.Docs;
          break;

        default:
          break;
      }

      const { data } = await SocialApi.getMedia(params);

      resetWidthCalculation();
      for (let index = 0; index < data.records.length; index++) {
        updateListImageWidth(index, data.records[index]);
      }
      setMediaLibraryState({ ...mediaLibraryState, data, loading: false });
      setPageIndex(page);

      setTotalRecordsPagination(data.totalRecords);

      setListMediaSelected(data.records.filter(x => listMediaSelected.some(y => y.id === x.id)));

    } catch (error) {
      resetWidthCalculation();
      setMediaLibraryState({ ...mediaLibraryState, loading: false });
    }
  };

  function resetWidthCalculation() {
    currentSumWith.current = 0;
    listWidth.current = [];
  }

  const listWidth = useRef<any[]>([]);
  const currentSumWith = useRef(0);
  function updateListImageWidth(index: number, mediaItem: MediaModel) {
    if (listWidth.current.length > index) {
      return;
    }

    let w = 0;
    if (mediaItem.width || mediaItem.height) {
      w = (MEDIA_ITEM_HEIGHT * mediaItem.width) / mediaItem.height;
    } else {
      w = MEDIA_ITEM_HEIGHT;
    }

    w = Math.round(w);
    if (w < MEDIA_ITEM_MIN_WIDTH) {
      w = MEDIA_ITEM_MIN_WIDTH;
    }

    const listMediaWidth = listMediaRef.current?.offsetWidth || MEDIA_LIST_WIDTH;

    if (currentSumWith.current >= listMediaWidth) {
      currentSumWith.current = w + MEDIA_ITEM_PADDING;
    } else if (currentSumWith.current + w + MEDIA_ITEM_MIN_WIDTH < listMediaWidth) {
      currentSumWith.current = currentSumWith.current + w + MEDIA_ITEM_PADDING;
    } else {
      w = listMediaWidth - currentSumWith.current;
      currentSumWith.current = 0;
    }

    listWidth.current.push(w);
  }

  const onSelected = (media: MediaModel) => {
    const mediaIndex = listMediaSelected.findIndex((m) => m.id === media.id);
    if (mediaIndex === -1) setListMediaSelected([...listMediaSelected, media]);
    else {
      let newListSelected = [...listMediaSelected];
      newListSelected.splice(mediaIndex, 1);
      setListMediaSelected(newListSelected);
    }
  };

  const selectAll = () => {
    setListMediaSelected(listMedia);
  };

  const deselectAll = () => {
    setListMediaSelected([]);
    setFileSelected(undefined);
  };

  const deleteSelected = async () => {
    if (!listMediaSelected.length) {
      return;
    }
    setLoadingDelete(true);
    try {
      const listMediaIds = listMediaSelected.map((e) => e.id);
      const { data } = await MediaLibraryApi.checkMediaInContent({
        ids: listMediaIds,
      });
      const numberMediasInPost = data.reduce((result: number[], item) => {
        return [...result, ...item.inContentIds];
      }, []);
      const uniqueMedia = [...Array.from(new Set(numberMediasInPost))];

      Modal.confirm({
        title: t("mediaLibrary.deleteMediaTitle"),
        content: (
          <>
            {uniqueMedia.length > 0 ? (
              <div>
                <p>
                  Selected files are being used in <b>{uniqueMedia.length}</b>{" "}
                  posts. Deleting them will also:
                </p>
                <ul>
                  <li>
                    Remove them from the posts if the posts are scheduled or
                    draft
                  </li>
                  <li>
                    Delete the posts permanently if the posts have been
                    published
                  </li>
                </ul>
                <span>
                  Alternately, you can remove the media files from the posts
                  then try deleting again.
                </span>
              </div>
            ) : (
              <span>
                This action can’t be undone. However, content associated with
                the files will remain intact. Are you sure you want to continue?
              </span>
            )}
          </>
        ),
        okText: uniqueMedia.length ? "I understand and still want to delete" : t("common.delete"),
        cancelText: t("common.cancel"),
        cancelButtonProps: { type: "text" },
        icon: "",
        className: "confirm-modal confirm-delete-modal",
        centered: true,
        onOk: async () => {
          await MediaLibraryApi.deleteMedia({ ids: listMediaIds });
          await getListMedia(pageIndex, searchMediaKeyword);
          setListMediaSelected([]);
          showNotification("delete", "Selected files deleted successfuly");
          triggerReloadStorage();

          if (contentValue && contentValue.platformContents && contentValue.platformContents.length) {
            const newContent = CommonUtils.deepCopy(contentValue);
            newContent.platformContents.forEach(pc => {
              if (pc.medias && pc.medias.length) {
                pc.medias = pc.medias.filter(x => x.id && !listMediaIds.includes(x.id));
              }
            });
            setContentValue(newContent);
          }
        },
      });
    } catch (error) { }
    setLoadingDelete(false);
  };

  const itemRender = (page: any, type: any, originalElement: any) => {
    if (type === "prev") {
      return (
        <>
          <SVGIcons.ChevronLeft /> {t("clientSites.prev")}
        </>
      );
    }
    if (type === "next") {
      return (
        <>
          {t("clientSites.next")} <SVGIcons.ChevronRight />
        </>
      );
    }
    return originalElement;
  };

  function onChangePagination(page: number) {
    getListMedia(page, searchMediaKeyword);
    setListMediaSelected([]);
  }

  function renderMedia(media: MediaModel) {
    const extensionFile = MediaUtils.getExtension(media.url);

    if ((!media.isVideo && ["mp4", "mov"].includes(extensionFile)) || (media.isVideo && !media.thumbUrl)) {
      return <MediaSocial url={media.url} />
    }
    else if (extensionFile && Object.values(MediaExtensions).includes(extensionFile as MediaExtensions)) {
      return <span className="media-social-source document-img" >{getExtensionIcon(extensionFile as MediaExtensions)}</span>
    }

    return <img src={media.isVideo ? media.thumbUrl : (media.urlSmall || media.thumbUrl || media.url)} alt={media.name} className="media-social-source" />;
  }

  const openLightBox = (index: number) => {
    setLightBoxOpen(true);
    setLightBoxStartIndex(index);
  };

  useEffect(() => {

    getListMedia(1, searchMediaKeyword);
  }, [searchMediaKeyword, mediaType]);

  useEffect(() => {
    if (mediaLibraryState.triggerReloadMediaList) {
      getListMedia(pageIndex, searchMediaKeyword);
    }
  }, [mediaLibraryState.triggerReloadMediaList]);

  useEffect(() => {
    if (listMediaSelected && listMediaSelected.length === 1) {
      setFileSelected(listMediaSelected[0]);
    } else {
      setFileSelected(undefined);
    }
  }, [listMediaSelected])

  useEffect(() => {
    return () => {
      setListMediaSelected([]);
      setFileSelected(undefined);
    }
  }, [])

  // function updateSize() {
  //   resetWidthCalculation();
  //   for (let index = 0; index < listMedia.length; index++) {
  //     updateListImageWidth(index, listMedia[index]);
  //   }

  //   setMediaLibraryState({ ...mediaLibraryState, data: { ...mediaLibraryState.data, records: [...listMedia] }, loading: false });
  // }

  // useEffect(() => {
  //   window.addEventListener('resize', updateSize);
  //   return () => window.removeEventListener('resize', updateSize);
  // }, [])

  return (
    <div className="media-library-container">
      <div className="title">My Files</div>
      <div className="description">
        <span>{totalRecords} total files</span>{" "}
        <span>{listMediaSelected.length} file(s) selected</span>
        <span className={`select-all`} onClick={selectAll}>
          {t("social.approval.selectAll")}
        </span>
        <span className={`deselect-all`} onClick={deselectAll}>
          {t("social.approval.deselectAll")}
        </span>
        <Spin spinning={loadingDelete} wrapperClassName="delete-selected">
          <span onClick={deleteSelected}>Delete selected</span>
        </Spin>
      </div>

      <Spin spinning={loading} wrapperClassName="media-loading-container" >
        <div className="list-media" ref={listMediaRef}>
          {(listMediaSelected && !!listMediaSelected.length) && (
            <AppLightbox
              listMedia={listMedia}
              index={lightBoxStartIndex}
              open={lightBoxOpen}
              close={() => setLightBoxOpen(false)}
            />
          )}
          {!listMedia.length ? (
            <div className="media-empty">
              {!loading && <UploadMedia></UploadMedia>}
            </div>
          ) : (
            listMedia.map((media, index) => {
              return (
                <Tooltip title={media.name} overlayClassName="custom-tooltip media-library" placement="top" key={media.id}>
                  <div
                    className={`media-item ${listMediaSelected.find((m) => m.id === media.id)
                      ? "selected"
                      : ""
                      }`}
                    key={media.id}
                    onClick={() => onSelected(media)}
                    style={{ width: listWidth.current[index] }}
                  >
                    {
                      renderMedia(media)
                    }

                    {media.isVideo && <span className="video-duration">{CommonUtils.formatDuration(media.duration ?? 0)}</span>}

                    <div className="overlay" onClick={(e) => {
                      e.detail === 2 && openLightBox(index);
                    }}>
                      <div className="status">
                        <SVGIcons.CheckedNotRoundedIcon />
                      </div>
                    </div>
                  </div>
                </Tooltip>
              );
            })
          )}
        </div>
      </Spin>
      {((listMedia && !!listMedia.length) || pageIndex !== 1) && (
        <Pagination
          current={pageIndex}
          total={totalRecordsPagination}
          pageSize={pageSize}
          className="media-library-pagination"
          itemRender={itemRender}
          onChange={onChangePagination}
        />
      )}
    </div>
  );
};

export default MyFile;
