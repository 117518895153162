import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { ZohoApi } from "../apis/zoho.api";
import showNotification from "../components/common/notification";
import { ZohoCardTypes, ZohoRedirectUrl } from "../constants/app-constants";
import { IntegrationStatus, ShouldOpenDrawer, ShouldOpenModal, ZohoReportType, ZohoSyncStatus } from "../constants/app.enums";
import { ROUTE_PATHS } from "../constants/router.constants";
import { SalesAnalyticRequestModel } from "../models/zoho.model";
import { clientSitesState } from "../states/clientSites";
import { reportCardState, saleAnalyticsCallStatusState, saleAnalyticsConnectedBookingDateState, saleAnalyticsConnectionRateState, saleAnalyticsDateRangeState, saleAnalyticsRecentBookedDemoState, saleAnalyticsTotalCallsState, saleAnalyticsTotalDemoBookedState, saleAnalyticsTotalLeadWorkedState, zohoClientsState } from "../states/zoho";
import useAdminSite from "./useAdminSite";
import useClient from "./useClient";

type ZohoReportData = {
  detail: {
    reportItem?: any;
    [key: string]: any;
  };
};

interface ReportState<T> {
  loading: boolean;
  data: T | undefined;
}

export const ChartMockupData = {
  CallStatus: [
    { name: "None", value: 0, chartValue: 0 },
    { name: "Conversation", value: 0, chartValue: 0 },
    { name: "Dead Line", value: 0, chartValue: 0 },
    { name: "Do Not Call", value: 0, chartValue: 0 },
    { name: "Hung Up", value: 0, chartValue: 0 },
    { name: "No Answer", value: 0, chartValue: 0 },
    { name: "Not Interested", value: 0, chartValue: 0 },
    { name: "Voice Mail", value: 0, chartValue: 0 },
    { name: "Wrong ICP", value: 0, chartValue: 0 },
    { name: "Wrong Number", value: 0, chartValue: 0 },
  ],
  CardSum: {
    currentValue: 0,
    previousValue: 0,
  }
}

function useZoho() {
  const { pathname } = useLocation();
  const [zohoClients, setZohoClients] = useRecoilState(zohoClientsState);
  const [reports, setReports] = useRecoilState(reportCardState);

  const { client } = useClient();
  const [usersInfoLoading, setUsersInfoLoading] = useState(false);
  const [connectZohoLoading, setConnectZohoLoading] = useState(false);
  const [checkConnectedLoading, setCheckConnectedLoading] = useState(false);
  const [status, setStatus] = useState<IntegrationStatus | ZohoSyncStatus>(IntegrationStatus.None);

  const [totalLeadWorked, setTotalLeadWorked] = useRecoilState(
    saleAnalyticsTotalLeadWorkedState
  );

  const dateRange = useRecoilValue(saleAnalyticsDateRangeState);
  const { isAdminViewSite ,clientId } = useClient();
  const isAdmin = useAdminSite();


  const [callStatus, setCallStatus] = useRecoilState(saleAnalyticsCallStatusState);
  const [recentBookedDemo, setRecentBookedDemo] = useRecoilState(saleAnalyticsRecentBookedDemoState);
  const [totalCalls, setTotalCalls] = useRecoilState(saleAnalyticsTotalCallsState);
  const [totalDemoBook, setTotalDemoBooked] = useRecoilState(
    saleAnalyticsTotalDemoBookedState
  );


  const [connectedBookingDate, setConnectedBookingDate] = useRecoilState(
    saleAnalyticsConnectedBookingDateState
  );

  const [connectionRate, setConnectionRate] = useRecoilState(saleAnalyticsConnectionRateState);
  const {clientSelected} = useRecoilValue(clientSitesState);

  async function getZohoClient(clientId: number) {
    if (!clientId) return;

    try {
      setUsersInfoLoading(true);
      const { data } = await ZohoApi.getAllZohoClient(clientId);
      data.map(x=> {
        if(x.statusMessage === ZohoSyncStatus.ZohoSynced) {
          x.statusMessage = ZohoSyncStatus.None;
        }
        return x;
      })
      setZohoClients(data ?? []);
    } finally {
      setUsersInfoLoading(false);
    }
  }

  const findReportByType = (type: ZohoReportType) => {
    if (reports.data && reports?.data?.length > 0) {
      return reports.data?.find((report) => report.type === type);
    }
    return null;
  };

  async function getReports() {
    if (!client?.id) return;

    try {
      resetStates();
      setReports({ loading: true, data: undefined });
      const { data } = await ZohoApi.getZohoReports(client?.id);
      setReports({
        data,
        loading: false,
      });
    }

    catch (error) {
      resetStates();
      setReports({ loading: false, data: undefined });
    }
  }


  const connectZoho = async (isReAuthen = false, shouldOpenModal?: ShouldOpenModal, shouldOpenDrawer?: ShouldOpenDrawer ) => {
    try {
      if (!isReAuthen && zohoClients.length === 3) {
        showNotification("error", "Only 3 Zoho accounts are allowed. Please remove one account then try again.")
        return;
      }

      setConnectZohoLoading(true);
      let cId = clientId;
      if(isAdmin && !isAdminViewSite) {
        if(!clientSelected || !clientSelected.id) {
          return;
        }
        cId = clientSelected.id
      }

      let customRedirect = "";

    if(shouldOpenModal === ShouldOpenModal.Zoho) {
        customRedirect = ZohoRedirectUrl.AdminEditSiteShowModal;
      } else if(pathname.includes(ROUTE_PATHS.ClientSite)) {
        customRedirect = ZohoRedirectUrl.AdminEditSite
      } else if(pathname.includes(ROUTE_PATHS.GeneralSettings)) {
        customRedirect = isAdminViewSite ? ZohoRedirectUrl.AdminViewGeneralSettings : ZohoRedirectUrl.GeneralSettings;
      } else if(pathname.includes(ROUTE_PATHS.SalesAnalytics)) {
        customRedirect = isAdminViewSite ? ZohoRedirectUrl.AdminViewSaleAnalytic : ZohoRedirectUrl.SaleAnalytic;
      }

      if(!customRedirect || !cId) {
        return;
      }

      if(isReAuthen) {
        customRedirect = `${customRedirect}_${ZohoRedirectUrl.ReAuthenticate}`;
      }

      const { data } = await ZohoApi.getConnectUrl(isAdmin, cId, customRedirect);
      if (data) {
        window.location.href = data;
      }

    } finally {
      setConnectZohoLoading(false);
    }
  }

  const checkConnectedZoho = async (zohoClientId: number) => {
    try {
      setCheckConnectedLoading(true);
      await ZohoApi.checkConnectedZoho(zohoClientId);
      setStatus(IntegrationStatus.Success);
      return true;
    }
    catch (error) {
      setStatus(IntegrationStatus.ConnectionZohoLostError);
      return false
    }
    finally {
      setCheckConnectedLoading(false);
    }
  }

  async function getZohoReport<T>(
    reportType: ZohoReportType,
    setState: React.Dispatch<React.SetStateAction<ReportState<T>>>,
    detailKey: keyof ZohoReportData['detail'] | null = 'detail',
  ) {
    if (!findReportByType(reportType)) return;

    if(!client?.id) return;
    if (dateRange.startDate && dateRange.endDate) {
      const request: SalesAnalyticRequestModel = {
        endDate: dateRange.endDate,
        startDate: dateRange.startDate,
      };
      try {
        setState({ loading: true, data: undefined });
        const { data } = await ZohoApi.getZohoReport(client?.id, reportType, request);
        const reportData = detailKey ? data.detail[detailKey] : data.detail;
        setState({ loading: false, data: reportData as T });
      } catch (error) {
        setState({ loading: false, data: undefined });
      }
    }


  }

  async function getTotalLeadWorked() {
    await getZohoReport(ZohoReportType.TOTAL_LEADS_WORKED, setTotalLeadWorked, null);
  }


  async function getConnectedBookingDate() {
    await getZohoReport(ZohoReportType.CONNECTED_BOOKING_RATE, setConnectedBookingDate, 'reportItem');
  }

  async function getCallStatus() {
    await getZohoReport(ZohoReportType.CALL_STATUS, setCallStatus, null);
  }

  async function getRecentBookedDemo() {
    await getZohoReport(ZohoReportType.RECENT_BOOKED_DEMO, setRecentBookedDemo, 'reportItem');
  }

  async function getTotalCalls() {
    await getZohoReport(ZohoReportType.TOTAL_CALL, setTotalCalls, null);
  }

  async function getTotalDemoBooked() {
    await getZohoReport(ZohoReportType.TOTAL_DEMO_BOOKED, setTotalDemoBooked, null);
  }

  async function getConnectionRate() {
    await getZohoReport(ZohoReportType.CONNECTION_RATE, setConnectionRate, 'reportItem');
  }


  const fetchAllReports = () => {
    resetStates()
    if (reports.data && reports.data.length > 0) {
      getTotalLeadWorked();
      getConnectedBookingDate();
      getCallStatus();
      getRecentBookedDemo();
      getTotalCalls();
      getTotalDemoBooked();
      getConnectionRate();
    }
  }

  const resetStates = () => {
    setTotalDemoBooked({ loading: false, data: undefined });
    setTotalLeadWorked({ loading: false, data: undefined });
    setConnectedBookingDate({ loading: false, data: undefined });
    setCallStatus({ loading: false, data: undefined });
    setTotalCalls({ loading: false, data: undefined });
    setConnectionRate({ loading: false, data: undefined });
    return;
  }

  const getCardTitleByType = (type: ZohoReportType) => {
    if (type === undefined) return '';
    const reportName = reports?.data?.find((card) => card.type === type)?.name;
    const defaultReportName = ZohoCardTypes.find((card) => card.type === type)?.name;
    if (reportName) {
      return reportName;
    }
    else {
      return defaultReportName;
    }
  };

  return {
    reports,
    connectZoho,
    getZohoReport,
    fetchAllReports,
    getReports,
    totalLeadWorked,
    callStatus,
    totalCalls,
    totalDemoBook,
    connectedBookingDate,
    connectionRate,
    recentBookedDemo,
    connectZohoLoading,
    usersInfoLoading,
    checkConnectedZoho,
    checkConnectedLoading,
    status,
    setStatus,
    getCardTitleByType,
    isAllChartsEmpty: !reports?.data || reports?.data?.length === 0,
    getZohoClient,
    setZohoClients,
    zohoClients,
    isEmptyState: reports.data?.length === 0,
    findReportByType,
  };
}

export default useZoho;
