import { Button, Checkbox, Input, Tabs, Tooltip } from "antd";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import AvatarDefault from "../../../../../../assets/images/avatar-default.svg";
import { ErrorContentText } from "../../../../../../constants/app-constants";
import {
  ContentErrorType,
  PlatformStatusType,
  PostStatusType,
  SocialReelType,
  SocialType,
} from "../../../../../../constants/app.enums";
import useClient from "../../../../../../hooks/useClient";
import {
  ContentMediaModel,
  ContentModel,
  TagModel,
} from "../../../../../../models/content.model";
import { isMobileSelector } from "../../../../../../states/common";
import {
  SocialContentState,
  contentValueState,
  currentContentSelector,
  errorsContentSelector,
  listPlatformContentSelector,
  CreateContentState,
} from "../../../../../../states/social";
import DateUtils from "../../../../../../utils/date.utils";
import showNotification from "../../../../../common/notification";
import SVGIcons from "../../../../../icons/svgs";
import AddMediaModal from "../../components/add-media";
import AddTag from "../../components/add-tag";
import AvatarSocial from "../../components/avatar-social";
import EmojiPicker from "../../components/emoji-picker";
import HashtagDropdown from "../../components/hashtag";
import ListMedia from "../../components/list-media";
import TagSocial from "../../components/tag-social";
import useUpdateContent from "../../hook/useUpdateContent";
import AdvancedSettingYoutube from "./advanced-setting-youtube";
import ContentCaption from "./content-caption";
import ContentCaptionGBP from "./content-caption-gbp";
import "./index.scss";
import LimitCharacter from "./limit-character";
import WriteWithAI from "./write-with-ai";

export const contentTabRender = (
  socialType?: SocialType,
  isReels?: boolean
) => {
  switch (socialType) {
    case SocialType.Facebook:
      return (
        <span>
          <SVGIcons.FacebookImage />
          {isReels ? "Reel" : "Post"}
        </span>
      );
    case SocialType.Instagram:
      return (
        <span>
          <SVGIcons.InstagramImage />
          {isReels ? "Reel" : "Post"}
        </span>
      );
    case SocialType.Tiktok:
      return (
        <span>
          <SVGIcons.TiktokImage />
          Video
        </span>
      );
    case SocialType.Youtube:
      return (
        <span>
          <SVGIcons.YoutubeImage />
          {isReels ? "Short" : "Post"}
        </span>
      );
    case SocialType.Google:
      return (
        <span>
          <SVGIcons.GoogleImage />
          Post
        </span>
      );
    case SocialType.LinkedIn:
      return (
        <span>
          <SVGIcons.LinkedInImage />
          Post
        </span>
      );
    default:
      return "";
  }
};

interface ContentEditorProps {
  contentInfo?: ContentModel;
  isReviewing?: boolean;
  canEditContent?: boolean;
  canEditCurrentContent?: boolean;
}

const ContentEditor = (props: ContentEditorProps) => {
  const { contentInfo, isReviewing, canEditCurrentContent, canEditContent } =
    props;
  const { t } = useTranslation();
  const { updateContents, isValidMedias, getPlatformContent } =
    useUpdateContent();
  const isMobile = useRecoilValue(isMobileSelector);
  const [positionText, setPositionText] = useState(0);
  const [openModalMedia, setOpenModalMedia] = useState(false);
  const [mediaIdxSelected, setMediaIdxSelected] = useState<number | undefined>(
    undefined
  );

  const errorsContent = useRecoilValue(errorsContentSelector);
  const listPlatformContent = useRecoilValue(listPlatformContentSelector);
  const currentContent = useRecoilValue(currentContentSelector);
  const [contentValue, setContentValue] = useRecoilState(contentValueState);
  const {
    isSyncContent,
    currentSocial,
    tags,
    date,
    requireApproval,
    status,
    createdDate,
    scheduleOn,
    publishedDate,
    contentAIGenerated,
  } = contentValue;

  const captionEditorRef = useRef<any>();
  const { client } = useClient();

  const getDate = () => {
    switch (status) {
      case PostStatusType.Drafted:
        return createdDate;
      case PostStatusType.Scheduled:
        return scheduleOn;
      case PostStatusType.Posted:
        return publishedDate;
      default:
        return "";
    }
  };

  const dateContent = date || getDate();

  const updatePlatformContent = (
    typeUpdate: "title" | "post" | "medias" | "shareReelsFeed",
    value: any
  ) => {
    if (!canEditCurrentContent) return;
    if (isSyncContent) {
      updateContents("all", typeUpdate, value);
    } else {
      updateContents(currentSocial, typeUpdate, value);
    }
  };

  const onChangeTitle = (e: any) => {
    const newTitle = e.target.value;
    updatePlatformContent("title", newTitle);
    setPositionText(e.target.selectionStart);
  };

  const addContentWithAI = (contentAI: string, isNew: boolean = false) => {
    let newCaption = "";
    if (isNew) {
      newCaption = contentAI;
    } else {
      let oldCaption = currentContent?.post || "";
      newCaption = oldCaption + contentAI;
    }
    setContentValue({ ...contentValue, contentAIGenerated: contentAI });
    setTimeout(() => {
      updatePlatformContent("post", newCaption);
    }, 100);
    setPositionText(newCaption.length);
  };

  const onChangeTab = (value: any) => {
    setContentValue({ ...contentValue, currentSocial: parseInt(value) });
  };

  const onEmojiClick = (emoji: any) => {
    let oldCaption = currentContent?.post || "";
    let newCaption =
      oldCaption.slice(0, positionText) +
      emoji +
      oldCaption.slice(positionText);
    updatePlatformContent("post", newCaption);
    setPositionText(positionText + emoji.length);
  };

  const onUpdateMedia = async (
    listMediaUrl: ContentMediaModel[]
  ): Promise<boolean> => {
    listMediaUrl = listMediaUrl.map((x) => {
      return {
        ...x,
        url: x.url,
      };
    });
    // replace
    if (mediaIdxSelected !== undefined && currentContent?.medias) {
      let newMedias = [
        ...currentContent?.medias.slice(0, mediaIdxSelected),
        ...listMediaUrl,
        ...currentContent?.medias.slice(mediaIdxSelected + 1),
      ];
      if (!(await isValidMedias(newMedias))) return false;
      updatePlatformContent("medias", newMedias);
    } else {
      // add
      let newMedias = [...listMediaUrl, ...(currentContent?.medias || [])];
      if (!(await isValidMedias(newMedias))) return false;
      updatePlatformContent("medias", newMedias);
    }
    setOpenModalMedia(false);
    return true;
  };

  const onRemoveMedia = async (idx: number) => {
    const mediasClone = [...(currentContent?.medias || [])];
    mediasClone.splice(idx, 1);
    updatePlatformContent("medias", mediasClone);
  };

  const onReplaceMedia = async (idx: number) => {
    onOpenModalMedia(idx);
  };

  const onOpenModalMedia = (mediaId?: number) => {
    setOpenModalMedia(true);
    mediaId !== undefined
      ? setMediaIdxSelected(mediaId)
      : setMediaIdxSelected(undefined);
  };

  const onSelectTag = (tagSelect: TagModel, isRemove?: boolean) => {
    const listTagClone = [...tags];
    let tagSelectIndex = listTagClone.findIndex(
      (item) => item.id === tagSelect?.id
    );
    // remove current tag which deleted
    if (isRemove) {
      if (tagSelectIndex === -1) return;
      listTagClone.splice(tagSelectIndex, 1);
      setContentValue({ ...contentValue, tags: listTagClone });
      return;
    }
    // select tag
    if (tagSelectIndex !== -1) return;
    setContentValue({ ...contentValue, tags: [...tags, tagSelect] });
  };

  const onUpdateHashTags = (hashtags: string) => {
    updatePlatformContent("post", (currentContent?.post || "") + hashtags);
  };

  const onRemoveTag = (tag: TagModel) => {
    const listTagClone = [...tags];
    let tagRemoveIndex = listTagClone.findIndex((item) => item.id === tag?.id);
    listTagClone.splice(tagRemoveIndex, 1);
    setContentValue({ ...contentValue, tags: listTagClone });
  };

  const onToggleApproval = () => {
    setContentValue({ ...contentValue, requireApproval: !requireApproval });
    if (requireApproval) {
      showNotification("success", "Approval is revoked.");
    } else
      showNotification(
        "success",
        "Approval is required. Content can only be published after it’s approved."
      );
  };

  const onChangeShareToFeed = (e: any) => {
    const value = e.target.checked;
    updatePlatformContent("shareReelsFeed", value);
  };

  const getCaption = () => {
    if (isSyncContent) {
      const currentContent = listPlatformContent.find(
        (content) => content.platformExtendReel === currentSocial
      );
      return currentContent?.post;
    }

    return currentContent?.platformExtendReel !== undefined
      ? getPlatformContent(currentContent?.platformExtendReel)?.post
      : "";
  };

  const caption = useMemo(() => {
    const text = getCaption();
    converterToMask(text || "");
    return text;
  }, [isSyncContent, currentContent, contentValue, currentSocial]);

  const renderListMedia = useCallback(() => {
    const hasAlertVideoYoutube =
      contentInfo?.status !== PostStatusType.Posted &&
      currentContent?.platform === SocialType.Youtube &&
      (currentContent?.medias || []).some((m) => m.height > m.width);
    return (
      <div className="media">
        {hasAlertVideoYoutube && (
          <div className="alert-media-youtube">
            <SVGIcons.InfoTriangleFillIcon />
            Content with vertical video may be published as Youtube Short
          </div>
        )}
        <ListMedia.ListMediaContentCard
          listMedia={currentContent?.medias || []}
          hasLightbox={true}
          onRemoveMedia={onRemoveMedia}
          onReplaceMedia={onReplaceMedia}
          isEdit={canEditCurrentContent}
          isReviewing={isReviewing}
        />
      </div>
    );
  }, [currentContent?.medias]);

  const getTitleWarningLimit = (content?: SocialContentState) => {
    const errors = content?.errors;
    // warning when publish error
    if (
      !errors?.length &&
      !errorsContent.length &&
      (content || currentContent)?.status === PlatformStatusType.Error
    )
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: (content || currentContent)?.errorMessage || "",
          }}
        ></span>
      );
    // warning when validate content
    let newErrorsContent: ContentErrorType[] = [];
    if (!isSyncContent) {
      newErrorsContent = errors || [];
    } else {
      newErrorsContent = [...errorsContent];
      if (
        newErrorsContent.some((error) =>
          [
            ContentErrorType.RequiredPostFB,
            ContentErrorType.RequiredPostInsta,
            ContentErrorType.RequiredPostTiktok,
          ].includes(error)
        )
      ) {
        newErrorsContent = newErrorsContent.filter(
          (error) =>
            ![
              ContentErrorType.RequiredPostFB,
              ContentErrorType.RequiredPostInsta,
              ContentErrorType.RequiredPostTiktok,
            ].includes(error)
        );
        newErrorsContent = [ContentErrorType.RequiredPost, ...newErrorsContent];
      }
    }
    if (!newErrorsContent.length) return "";
    return (
      <div>
        {newErrorsContent.map((errorType) => {
          return <div key={errorType}>{ErrorContentText[errorType]}</div>;
        })}
      </div>
    );
  };

  const renderDatetimeTooltip = () => {
    const timeFormat = DateUtils.getDateWithTimezone(
      dateContent,
      client?.timeZoneInfo?.id
    ).format("ddd, MMM DD, h:mma");
    switch (contentInfo?.status) {
      case PostStatusType.Drafted:
      case PostStatusType.Error:
        return `Content was created on ${timeFormat}`;
      case PostStatusType.Scheduled:
        return `Content will be published on ${timeFormat}`;
      case PostStatusType.Posted:
        return `Content was published on ${timeFormat}`;
      default:
        return "";
    }
  };

  function converterToMask(value: string) {
    let str = value;
    if (contentAIGenerated) {
      str = value.split(contentAIGenerated).join("");
    }
    str = str.replace(/(<)/gi, "&lt;");
    str = str.replace(/(<)/gi, "&lg;");
    str = str.replace(/(?:\r\n|\n\r|\r|\n)/g, " <br />");
    str = str.replace(/#(\S+)(?=[\s.,:]|$)/g, "<span>#$1</span>");
    // str = str.replace(/@(.+?)(?=[\s.,:,]|$)/g, "<span>@$1</span>");
    str = str.replace(
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/g,
      "<span>$1</span>"
    );
    if (contentAIGenerated) {
      str += '<span class="highlight">' + contentAIGenerated + "</span>";
      // setContentAIGenerated("");
    }
    if (captionEditorRef.current) {
      captionEditorRef.current.innerHTML = str;
    }
  }

  const renderTab = () => {
    if (currentContent?.platform === undefined) return "";
    if (isSyncContent) return "";
    if (!isReviewing) return "";
    if (
      ![SocialType.Facebook, SocialType.Instagram, SocialType.Youtube].includes(
        currentContent.platform
      )
    )
      return "";

    const reelContent = listPlatformContent.find(
      (c) => c.isReels && c.platform === currentContent.platform
    );
    if (!reelContent?.post && !reelContent?.medias?.length) return "";
    if (isMobile) {
      return (
        <div className="content-editor-tab-mobile">
          <Button
            type="default"
            className={`tab-post ${!currentContent.isReels ? "active" : ""}`}
            onClick={() =>
              setContentValue({
                ...contentValue,
                currentSocial: currentContent.platform,
              })
            }
          >
            Post
          </Button>
          <Button
            type="default"
            className={`tab-reel ${currentContent.isReels ? "active" : ""}`}
            onClick={() =>
              setContentValue({
                ...contentValue,
                currentSocial: currentContent.platform + 10,
              })
            }
          >
            {currentContent.platform === SocialType.Youtube ? "Short" : "Reel"}
          </Button>
        </div>
      );
    } else {
      return (
        <div className="content-editor-tab">
          <Tabs
            defaultActiveKey="0"
            onChange={onChangeTab}
            activeKey={currentSocial.toString()}
            items={listPlatformContent
              .filter((content) => {
                return !content?.disabled;
              })
              .map((content: SocialContentState) => {
                // fix underline RLV2-1442
                const isHidden =
                  isReviewing && content.platform !== currentContent?.platform;
                const isIG =
                  isReviewing &&
                  currentContent?.platform === SocialType.Instagram &&
                  !listPlatformContent.find(
                    (c) => c.platform === SocialType.Facebook
                  )?.disabled;
                return {
                  label: (
                    <Tooltip
                      title={getTitleWarningLimit(content)}
                      placement="top"
                    >
                      <div
                        className={`content-editor-tab-label ${
                          isHidden ? "hidden" : ""
                        } ${isIG ? "isIG" : ""}`}
                      >
                        {contentTabRender(content?.platform, content?.isReels)}
                        {getTitleWarningLimit(content) && (
                          <SVGIcons.AlertCircle />
                        )}
                      </div>
                    </Tooltip>
                  ),
                  key: content?.platformExtendReel?.toString() || "0",
                };
              })}
          />
        </div>
      );
    }
  };

  const renderContentCaptionByPlatform = () => {
    const contentValue = contentInfo
      ? {
          isSyncContent,
          currentSocial,
          platformContents: listPlatformContent,
          currentContent: currentContent,
        }
      : undefined;
    return (
      <>
        <div
          style={{
            display:
              currentContent?.platform === SocialType.Google && !isSyncContent
                ? "unset"
                : "none",
          }}
        >
          <ContentCaptionGBP
            setPositionText={setPositionText}
            updatePlatformContent={updatePlatformContent}
            isReviewing={isReviewing}
            contentValue={contentValue}
          ></ContentCaptionGBP>
        </div>
        <div
          style={{
            display:
              isSyncContent || currentContent?.platform !== SocialType.Google
                ? "unset"
                : "none",
          }}
        >
          <ContentCaption
            setPositionText={setPositionText}
            updatePlatformContent={updatePlatformContent}
            isReviewing={isReviewing}
            contentValue={contentValue}
          />
        </div>
      </>
    );
  };

  return (
    <div className="content-editor-wrap">
      <div className="content-editor">
        {renderTab()}
        <div className="content-editor-tag">
          <div className="list-tag">
            {tags.map((tag) => (
              <TagSocial
                text={tag.name}
                color={tag.color}
                canDelete={!isReviewing && true}
                key={tag.id}
                onRemove={() => onRemoveTag(tag)}
              />
            ))}
            {!isReviewing && <AddTag onSelectTag={onSelectTag} />}
          </div>
          {isSyncContent && getTitleWarningLimit() && (
            <Tooltip title={getTitleWarningLimit()} placement="right">
              <div className="warning-limit">
                <SVGIcons.AlertCircle />
              </div>
            </Tooltip>
          )}
        </div>

        {contentInfo && (
          <div
            className={`content-editor-avatar ${
              !currentContent?.profile ? "no-profile" : ""
            }`}
          >
            <div className="avatar">
              <AvatarSocial
                avatar={currentContent?.profile?.userImage || AvatarDefault}
                size={32}
              />
            </div>
            <div className="name-and-date">
              <div className="name">
                {currentContent?.profile?.name || "Profile Name"}
              </div>
              <Tooltip title={renderDatetimeTooltip()}>
                <div className="date">
                  {DateUtils.getDateWithTimezone(
                    dateContent,
                    client?.timeZoneInfo?.id
                  ).format("ddd, MMM DD, h:mma")}
                </div>
              </Tooltip>
            </div>
          </div>
        )}

        {currentContent?.platform === SocialType.Youtube && !isSyncContent && (
          <div className="content-editor-title">
            <Input.TextArea
              placeholder="Write your title here..."
              value={currentContent?.title}
              onChange={onChangeTitle}
              rows={1}
              autoSize={true}
            />
          </div>
        )}

        {renderContentCaptionByPlatform()}

        {currentSocial === SocialReelType.InstagramReel && !isSyncContent && (
          <div className="share-to-feed-instagram">
            <Checkbox
              checked={currentContent?.shareReelsFeed}
              onChange={onChangeShareToFeed}
            />
            Also share to Feed
          </div>
        )}
        {[SocialType.Youtube, SocialReelType.YoutubeShort].includes(
          currentSocial
        ) &&
          !isSyncContent && (
            <div className="advanced-setting-youtube">
              <AdvancedSettingYoutube
                disabled={
                  isReviewing ||
                  currentContent?.status === PlatformStatusType.Success
                }
              />
            </div>
          )}
        {canEditCurrentContent && (
          <div className="content-editor-footer">
            <div className="content-editor-footer__left">
              <>
                <Tooltip title={t("social.content.uploadMediaTooltip")}>
                  <div
                    className="photo"
                    onClick={() => canEditContent && onOpenModalMedia()}
                  >
                    <SVGIcons.PhotoIcon />
                  </div>
                </Tooltip>
                <Tooltip title={t("social.content.emojiTooltip")}>
                  <EmojiPicker onEmojiChange={onEmojiClick} />
                </Tooltip>
                <Tooltip title={t("social.content.hashtagTooltip")}>
                  <div className="hashtag">
                    <HashtagDropdown onUpdateHashTags={onUpdateHashTags} />
                  </div>
                </Tooltip>
                {!contentInfo && (
                  <Tooltip title={t("social.content.approvalTooltip")}>
                    <div
                      className={`user ${requireApproval ? "active" : ""}`}
                      onClick={onToggleApproval}
                    >
                      <SVGIcons.UserCheckIcon />
                    </div>
                  </Tooltip>
                )}
                <WriteWithAI addContent={addContentWithAI} />
              </>
            </div>

            <div className="content-editor-footer__right">
              <LimitCharacter />
            </div>
          </div>
        )}

        {renderListMedia()}

        <AddMediaModal
          open={openModalMedia}
          close={() => setOpenModalMedia(false)}
          addMedia={onUpdateMedia}
        />
      </div>
    </div>
  );
};

export default ContentEditor;
