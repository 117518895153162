import TextArea from "antd/lib/input/TextArea";
import useUpdateContent from "../../../hook/useUpdateContent";
import { ContentMediaModel } from "../../../../../../../models/content.model";
import { useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import {
  contentValueState,
  currentContentSelector,
} from "../../../../../../../states/social";
import { SocialType } from "../../../../../../../constants/app.enums";

interface Props {
  media: ContentMediaModel;
  isEdit?: boolean;
  isReviewing?: boolean;
}
const MediaTitle = (props: Props) => {
  const { media, isEdit, isReviewing } = props;
  const { onValuesPlatformChange } = useUpdateContent();
  const inputRef = useRef<any>(null);
  const [titleEditing, setTitleEditing] = useState(-1);
  const currentContent = useRecoilValue(currentContentSelector);
  const contentValue = useRecoilValue(contentValueState);
  const { isSyncContent, currentSocial } = contentValue;
  if (
    !isReviewing &&
    (isSyncContent || currentSocial !== SocialType.LinkedIn)
  )
    return <></>;
  if (isReviewing && !media.title) return <></>;

  const onClickTitle = () => {
    if (!isEdit) return;
    if (titleEditing !== -1) return;
    setTitleEditing(media.id);
    setTimeout(() => {
      if (!inputRef.current) return;
      inputRef.current?.focus();
    }, 0);
  };
  const onBlurTitle = (e: any) => {
    const newListMedia = (currentContent?.medias || []).map((m) => {
      if (m.id !== media.id) return m;
      return {
        ...m,
        title: e.target.value,
        alt: e.target.value,
      };
    });
    onValuesPlatformChange({ medias: newListMedia });
    setTitleEditing(-1);
  };
  return (
    <div
      className={`media-title ${titleEditing !== -1 ? "editing" : ""}`}
      onClick={onClickTitle}
      onBlur={onBlurTitle}
    >
      {titleEditing !== -1 ? (
        <TextArea
          defaultValue={media.title}
          id="title-editing"
          ref={inputRef}
          autoSize={true}
        />
      ) : media.title ? (
        <span>
          <pre>{media.title}</pre>
        </span>
      ) : (
        <span className="no-title">Add title</span>
      )}
    </div>
  );
};

export default MediaTitle;
