import { Carousel, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import { useHorizontalScroll } from "../../../../../../hooks/useScrollHorizontal";
import MediaUtils from "../../../../../../utils/media.utils";
import AppLightbox from "../../../../../common/light-box";
import SVGIcons from "../../../../../icons/svgs";
import MediaSocial from "../media-social";
import "./index.scss";
import { ContentMediaModel, MediaModel } from "../../../../../../models/content.model";
import { getExtensionIcon } from "../../../../media-library/components/upload-media";
import { MediaExtensions } from "../../../../../../constants/app.enums";
import MediaTitle from "./media-title";

interface ListMediaProps {
  listMedia: ContentMediaModel[];
  onRemoveMedia?: (id: number) => void;
  onReplaceMedia?: (id: number) => void;
  isEdit?: boolean;
  hasLightbox?: boolean;
  isReviewing?: boolean;
  isContentCard?: boolean;
}

const ListMediaCreateContent = (props: ListMediaProps) => {
  const { listMedia, onRemoveMedia, onReplaceMedia, isEdit } = props;
  const scrollRef: any = useHorizontalScroll();
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [lightBoxStartIndex, setLightBoxStartIndex] = useState(0);

  const openLightBox = (index: number) => {
    setLightBoxOpen(true);
    setLightBoxStartIndex(index);
  };

  return listMedia.length ? (
    <div
      className={`list-media-create-content${listMedia.length === 1 ? " media-no-padding" : ""
        }`}
      ref={scrollRef}
    >
      <AppLightbox
        listMedia={listMedia}
        index={lightBoxStartIndex}
        open={lightBoxOpen}
        close={() => setLightBoxOpen(false)}
      />
      {listMedia.map((media: ContentMediaModel, index: number) => {
        return (
          <div className="media-item" key={index}>
            <MediaSocial
              url={media.url}
              openLightBox={() => openLightBox(index)}
            />

            {isEdit && (
              <div className="overlay">
                <Tooltip title="Replace media">
                  <div
                    className="replace"
                    onClick={() => onReplaceMedia && onReplaceMedia(index)}
                  >
                    <SVGIcons.EditIcon />
                  </div>
                </Tooltip>
                <Tooltip title="Remove media">
                  <div
                    className="remove"
                    onClick={() => onRemoveMedia && onRemoveMedia(index)}
                  >
                    <SVGIcons.CloseIcon />
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        );
      })}{" "}
    </div>
  ) : (
    <></>
  );
};

const ListMediaContentCard = (props: ListMediaProps) => {
  const {
    listMedia,
    hasLightbox,
    onRemoveMedia,
    onReplaceMedia,
    isEdit,
    isReviewing,
    isContentCard,
  } = props;
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [lightBoxStartIndex, setLightBoxStartIndex] = useState(0);
  const isMobile = window.innerWidth < 768;
  const videoMaxHeight = 260;
  const [videoHeight, setVideoHeight] = useState(isMobile && isReviewing ? videoMaxHeight : undefined);

  const openLightBox = (index: number, url: string) => {
    if (MediaUtils.isDocument(url)) {
      const viewerUrl = `https://docs.google.com/gview?url=${encodeURIComponent(
        url
      )}&embedded=true`;
      window.open(viewerUrl, "_blank", "noopener,noreferrer");
    } else {
      setLightBoxOpen(true);
      setLightBoxStartIndex(index);
    }
  };

  const carouselRef: any = useRef(null);

  const preSlide = (e: any) => {
    e.stopPropagation();
    if (carouselRef.current) carouselRef.current.prev();
  };
  const nextSlide = (e: any) => {
    e.stopPropagation();
    if (carouselRef.current) carouselRef.current.next();
  };

  const renderMediaTitleContentCard = (title: string) => {
    if (!title || !isContentCard) return "";
    return (
      <div className="media-title-content-card">
        <div className="title">{title}</div>
      </div>
    );
  };

  const videoRef = useRef<HTMLVideoElement>(null);

  const getVideoHeight = (media: ContentMediaModel) => {
    if (!isMobile) {
      return;
    }
    if (videoRef.current && media.width) {
      const x = (videoRef.current.offsetWidth * media.height) / media.width;
      if (x < videoMaxHeight) {
        setVideoHeight(x);
      }
    }
  };

  return (
    <div className="list-media-content-card">
      {hasLightbox && (
        <AppLightbox
          listMedia={listMedia}
          index={lightBoxStartIndex}
          open={lightBoxOpen}
          close={() => setLightBoxOpen(false)}
        />
      )}
      <Carousel dots={false} ref={carouselRef} adaptiveHeight={true}>
        {listMedia.map((media: ContentMediaModel, index: number) => {
          const extensionFile = MediaUtils.getExtension(
            media.url
          ) as MediaExtensions;
          return (
            <div key={index}>
              <div
                className="media-item"
                id="media-item"
                onClick={() => openLightBox(index, media.url)}
                style={{ height: `${videoHeight}px` }}
              >
                {!MediaUtils.isVideo(media.url) ? (
                  !MediaUtils.isDocument(media.url) ? (
                    <img className="image-list" src={media.url} alt="" />
                  ) : (
                    <div className="media-item-doc">
                      {getExtensionIcon(extensionFile)}
                      <div>{media.name}</div>
                    </div>
                  )
                ) : (
                  <div>
                    <video
                      id="media-video"
                      className={hasLightbox ? "video-with-light-box" : ""}
                      controls
                      preload="metadata"
                      key={media.url}
                      ref={videoRef}
                      onLoadedMetadata={() => getVideoHeight(media)}
                      style={{ height: videoHeight }}
                    >
                      <source src={`${media.url}#t=0.1`} type="video/mp4" />
                    </video>
                  </div>
                )}
                {listMedia.length > 1 && (
                  <div className="number-image-overlay">
                    {`${index + 1}/${listMedia.length}`}
                  </div>
                )}
                {isEdit && (
                  <div className="overlay">
                    <Tooltip title="Replace media">
                      <div
                        className="replace"
                        onClick={(e) => {
                          e.stopPropagation();
                          onReplaceMedia && onReplaceMedia(index);
                        }}
                      >
                        <SVGIcons.EditIcon />
                      </div>
                    </Tooltip>
                    <Tooltip title="Remove media">
                      <div
                        className="remove"
                        onClick={(e) => {
                          e.stopPropagation();
                          onRemoveMedia && onRemoveMedia(index);
                        }}
                      >
                        <SVGIcons.CloseIcon />
                      </div>
                    </Tooltip>
                  </div>
                )}
                {renderMediaTitleContentCard(media.title)}
                {listMedia.length > 1 && (
                  <>
                    <div className="btn-slide pre-slide" onClick={preSlide}>
                      <SVGIcons.ChevronLeft />
                    </div>
                    <div className="btn-slide next-slide" onClick={nextSlide}>
                      <SVGIcons.ChevronRight />
                    </div>
                  </>
                )}
              </div>
              <MediaTitle
                media={media}
                isEdit={isEdit}
                isReviewing={isReviewing}
              />
            </div>
          );
        })}{" "}
      </Carousel>
    </div>
  );
};

export default { ListMediaCreateContent, ListMediaContentCard };
