import { AxiosResponse } from "axios";
import qs from "qs";
import { BoardType, ServiceType } from "../constants/app.enums";
import {
  ConfirmChangeEmailModel,
  ResendConfirmChangeEmailModel,
} from "../models/change-email.model";
import {
  EmailReportSettingsModel,
  EnableEmailReportSettingRequestModel,
} from "../models/client-settings.model";
import {
  AddTeamMemberRequest,
  ClientTeamMemberModel,
  CreateAccountMemberRequest,
  UpdatePermissionTeamMemberRequest,
} from "../models/client-team-member.model";
import {
  ClientModel,
  CreateCompanyRequestModel,
  CreateSiteRequestModel,
  GetClientParams,
  IntegrationClientModel,
  UpdateClientRequestModel,
  UserCompanyModel,
} from "../models/client.model";
import { ListDataResponse } from "../models/common.model";
import {
  CretateRoleModel,
  EditRoleModel as UpdateRoleModel,
} from "../models/role.model";
import {
  AddUserServiceRequest,
  ClientServiceModel,
  DeleteUserServiceRequest,
  GetAccessServicesParams,
  GetAddableUserServiceParams,
  GetClientServiceParams,
  GetUserServiceParams,
  ToggleServiceRequest,
  UserServiceModel,
} from "../models/service.model";
import {
  AdminUpdateClientPasswordModel,
  ChangePasswordRequestModel,
  CreateUserGroupModel,
  EditUserGroupModel,
  RefreshTokenModel,
  SignInRequestModel,
  UpdatePasswordNewUserModel,
} from "../models/sign-in.model";
import { SignUpModel } from "../models/sign-up";
import {
  CreateUserRequestModel,
  DeleteClientsRequest,
  DeleteUsersRequest,
  GetUserParams,
  MoveUserRequest,
  UpdateMyUserRequestModel,
  UpdateUserRequestModel,
  UserProfileModel,
} from "../models/user";
import {
  deleteAsync,
  getAsync,
  postAsync,
  putAsync,
  putFormDataAsync,
} from "./http-client";

const baseUrl = `${process.env.REACT_APP_API_ENPOINT}/auth/api/v1`;

function getSocialPermisson(userId: number, clientId: number) {
  const url = `${baseUrl}/users/social-permission`;
  return getAsync(url, { userId, clientId }, false, false, true);
}

function getUsers(params?: GetUserParams) {
  const url = `${baseUrl}/users`;
  return getAsync(url, params);
}

function signUp(model: SignUpModel) {
  const url = `${baseUrl}/home/sign-up`;
  return postAsync(url, model, false, undefined, true);
}

function signOut() {
  const url = `${baseUrl}/home/sign-out`;
  return postAsync(url, undefined, false, false, false);
}

function signIn(model: SignInRequestModel) {
  const url = `${baseUrl}/home/sign-in`;
  return postAsync(url, model, false, false, true);
}

function getClients(params: GetClientParams) {
  const url = `${baseUrl}/clients`;
  return getAsync(url, params, undefined, false);
}

function getClientDetails(id: number) {
  const url = `${baseUrl}/clients/${id}`;
  return getAsync(url, undefined, undefined, false);
}

function getClientInfo(id: number) {
  const url = `${baseUrl}/clients/${id}/info`;
  return getAsync(url, undefined, undefined, false);
}

function createClient(model: CreateSiteRequestModel) {
  const url = `${baseUrl}/clients`;
  return postAsync(url, model, true, false);
}

function adminCreateClientForUser(
  model: CreateSiteRequestModel,
  userId: number
) {
  const url = `${baseUrl}/clients/admin-new-site-for-user/${userId}`;
  return postAsync(url, model);
}

function logAccessClient(clientId: number, isAdmin?: boolean) {
  const url = `${baseUrl}/clients/${clientId}/log-access${
    isAdmin ? "?isAdmin=true" : ""
  }`;
  return postAsync(url);
}

function updateClient(id: number, model: UpdateClientRequestModel) {
  const url = `${baseUrl}/clients/${id}`;
  return putFormDataAsync(url, model);
}

function adminUpdateClient(id: number, model: UpdateClientRequestModel) {
  const url = `${baseUrl}/clients/${id}/admin`;
  return putAsync(url, model);
}

function deleteClient(id: number) {
  const url = `${baseUrl}/clients/${id}`;
  return deleteAsync(url);
}

function selfDeleteClient(id: number) {
  const url = `${baseUrl}/clients/self-delete/${id}`;
  return deleteAsync(url);
}

function clientIntegration(model: ClientModel) {
  const url = `${baseUrl}/clients/integration/${model.id}`;
  return putAsync(url, model);
}

function getIntegrationClient(
  clientId: number
): Promise<AxiosResponse<IntegrationClientModel>> {
  const url = `${baseUrl}/clients/${clientId}/intergrations`;
  return getAsync(url);
}

function getAdminStats() {
  const url = `${baseUrl}/home/admin-stats`;
  return getAsync(url);
}

function getRoles() {
  const url = `${baseUrl}/roles`;
  return getAsync(url);
}

function createRole(model: CretateRoleModel) {
  const url = `${baseUrl}/roles`;
  return postAsync(url, model);
}

function updateRole(model: UpdateRoleModel) {
  const url = `${baseUrl}/roles`;
  return putAsync(url, model);
}

function deleteRole(id: number) {
  const url = `${baseUrl}/roles/${id}`;
  return deleteAsync(url);
}

function getUserGroups() {
  const url = `${baseUrl}/user-groups`;
  return getAsync(url);
}

function createUserGroups(request: CreateUserGroupModel) {
  const url = `${baseUrl}/user-groups`;
  return postAsync(url, request);
}

function deleteUserGroups(id: number) {
  const url = `${baseUrl}/user-groups/${id}`;
  return deleteAsync(url);
}

function editUserGroups(request: EditUserGroupModel) {
  const url = `${baseUrl}/user-groups`;
  return putAsync(url, request);
}

function refreshToken(request: RefreshTokenModel) {
  const url = `${baseUrl}/home/refresh-token`;
  return postAsync(url, request, false, false, true);
}

function changePassword(request: ChangePasswordRequestModel) {
  const url = `${baseUrl}/home/change-password`;
  return postAsync(url, request);
}

function clientSettings(clientId: number): 
Promise<AxiosResponse<EmailReportSettingsModel[]>> {
  const url = `${baseUrl}/clients/${clientId}/client-settings`;
  return getAsync(url); 
}

function updateClientSetting(model: EmailReportSettingsModel) {
  const url = `${baseUrl}/clients/save-general-settings`;
  return postAsync(url, model);
}

function enableSetting(model: EnableEmailReportSettingRequestModel) {
  const url = `${baseUrl}/clients/enable-report`;
  return postAsync(url, model);
}

function updateAverageValueOfNewCustomer(
  clientId?: number,
  averageValueOfNewCustomer?: number,
  conversionRate?: number,
  showTrendIndicator?: boolean
) {
  const url = `${baseUrl}/clients/save-addvanced-settings`;

  const request = {
    clientId,
    averageValueOfNewCustomer,
    conversionRate,
    showTrendIndicator,
  };

  return postAsync(url, request);
}

function getListCompanyUser(userId: number): Promise<AxiosResponse<any>> {
  const url = `${baseUrl}/clients/by-user/${userId}`;
  return getAsync(url);
}

function createNewSite(
  model: CreateCompanyRequestModel
): Promise<AxiosResponse<UserCompanyModel[]>> {
  const url = `${baseUrl}/clients/client-new-site`;
  return postAsync(url, model);
}

function createUser(model: CreateUserRequestModel) {
  const url = `${baseUrl}/users`;
  return postAsync(url, model);
}

function getUserProfile(): Promise<AxiosResponse<UserProfileModel>> {
  const url = `${baseUrl}/users/profile`;
  return getAsync(url);
}

function updateMyUserProfile(model: UpdateMyUserRequestModel) {
  const url = `${baseUrl}/users/user-profile`;
  return putFormDataAsync(url, model);
}

function deleteUser(id: number) {
  const url = `${baseUrl}/users?userId=${id}`;
  return deleteAsync(url);
}

function updatePasswordNewUser(model: UpdatePasswordNewUserModel) {
  const url = `${baseUrl}/home/new-user-password-change`;
  return postAsync(url, model, false, undefined, true);
}

function sendInvitationEmail(email: string) {
  const url = `${baseUrl}/home/invitation-email/${email}`;
  return getAsync(url);
}

function updateUser(model: UpdateUserRequestModel) {
  const url = `${baseUrl}/users`;
  return putAsync(url, model);
}

function updateClientProfile(model: any) {
  const url = `${baseUrl}/users/client-profile`;
  return putFormDataAsync(url, model);
}

function getPresignUrl(fileName: string) {
  const url = `${baseUrl}/users/presigned-url/${fileName}`;
  return getAsync(url);
}

function moveUsers(request: MoveUserRequest) {
  const url = `${baseUrl}/users/user-group`;
  return putAsync(url, request);
}

function deleteUsers(request: DeleteUsersRequest) {
  const params = qs.stringify(request, { arrayFormat: "repeat" });
  const url = `${baseUrl}/users/delete-request?${params}`;
  return deleteAsync(url);
}

function deleteClients(request: DeleteClientsRequest) {
  const params = qs.stringify(request, { arrayFormat: "repeat" });
  const url = `${baseUrl}/clients/delete-request?${params}`;
  return deleteAsync(url);
}

function adminChangeClientPassword(request: AdminUpdateClientPasswordModel) {
  const url = `${baseUrl}/home/admin-update-client-password`;
  return postAsync(url, request);
}

function confirmChangeEmail(request: ConfirmChangeEmailModel) {
  const url = `${baseUrl}/users/change-email`;
  return getAsync(url, request, undefined, undefined, true);
}

function resendEmailVerification(request: ResendConfirmChangeEmailModel) {
  const url = `${baseUrl}/users/resend-email-change-notification`;
  return putAsync(url, request, undefined, undefined, true);
}

/* Guest User */

function getGuestUser(uuid: string) {
  const url = `${baseUrl}/guest-users/${uuid}`;
  return getAsync(url);
}

function createGuestUser(fullName: string) {
  const url = `${baseUrl}/guest-users`;
  return postAsync(url, { fullName });
}

function putGuestUser(uuid: string, fullName: string) {
  const url = `${baseUrl}/guest-users`;
  return putAsync(url, { uuid, fullName });
}

/* Service */

function toggleService(request: ToggleServiceRequest) {
  const url = `${baseUrl}/service/toggle`;
  return putAsync(url, request);
}

function addUserService(request: AddUserServiceRequest) {
  const url = `${baseUrl}/service/add-users`;
  return postAsync(url, request);
}

function deleteUserService(request: DeleteUserServiceRequest) {
  const { serviceType, clientId, userId } = request;
  const url = `${baseUrl}/service/delete-users?serviceType=${serviceType}&clientId=${clientId}&userIds=${userId}`;
  return deleteAsync(url);
}

function getUserService(
  params: GetUserServiceParams
): Promise<AxiosResponse<UserServiceModel[], any>> {
  const url = `${baseUrl}/service/users`;
  return getAsync(url, params);
}

function getAddableUserService(
  params: GetAddableUserServiceParams
): Promise<AxiosResponse<ListDataResponse<UserServiceModel>, any>> {
  const url = `${baseUrl}/service/addable-users`;
  return getAsync(url, params);
}

function getClientService(
  params: GetClientServiceParams
): Promise<AxiosResponse<ListDataResponse<ClientServiceModel>, any>> {
  const query = qs.stringify(params, { arrayFormat: "repeat" });
  const url = `${baseUrl}/service/clients?${query}`;
  return getAsync(url);
}

function getAccessServices(
  params: GetAccessServicesParams
): Promise<AxiosResponse<ServiceType[], any>> {
  const url = `${baseUrl}/service/access-services`;
  return getAsync(url, params);
}

/* Team member */

function getTeamMember(
  clientId: number
): Promise<AxiosResponse<ClientTeamMemberModel[], any>> {
  const url = `${baseUrl}/client-team-member?clientId=${clientId}`;
  return getAsync(url);
}

function getTeamMemberDetail(
  memberId: number
): Promise<AxiosResponse<ClientTeamMemberModel, any>> {
  const url = `${baseUrl}/client-team-member/${memberId}`;
  return getAsync(url);
}

function getAcceptInvitation(
  memberId: number
): Promise<AxiosResponse<ClientTeamMemberModel, any>> {
  const url = `${baseUrl}/client-team-member/accept-invitation/${memberId}`;
  return getAsync(url);
}

function addTeamMember(
  request: AddTeamMemberRequest
): Promise<AxiosResponse<ClientTeamMemberModel, any>> {
  const url = `${baseUrl}/client-team-member`;
  return postAsync(url, request);
}

function deleteTeamMember(memberId: number) {
  const url = `${baseUrl}/client-team-member/${memberId}`;
  return deleteAsync(url);
}

function updatePermissionTeamMember(
  request: UpdatePermissionTeamMemberRequest
) {
  const url = `${baseUrl}/client-team-member/update-permission`;
  return putAsync(url, request);
}

function createAccountForMember(request: CreateAccountMemberRequest) {
  const url = `${baseUrl}/client-team-member/create-account`;
  return postAsync(url, request);
}

function verifyAccount(email: string, confirmationCode: string) {
  const url = `${baseUrl}/users/confirm-email`;
  return putAsync(url, {email, confirmationCode});
}

function saveDashboardSettings(
  clientId: number,
  boardType: BoardType,
  showTrendIndicator: boolean,
  averageValueOfNewCustomer?: number, 
  conversionRate?: number
) {
  const url = `${baseUrl}/clients/save-dashboard-settings`;

  const request = {
    clientId,
    boardType,
    showTrendIndicator,
    averageValueOfNewCustomer,
    conversionRate
  };

  return postAsync(url, request);
}

export const AuthApi = {
  getSocialPermisson,
  getClientDetails,
  getClientInfo,
  getUsers,
  getClients,
  signUp,
  clientIntegration,
  getIntegrationClient,
  updateClient,
  adminUpdateClient,
  signIn,
  signOut,
  getAdminStats,
  getRoles,
  createRole,
  updateRole,
  deleteRole,
  getUserGroups,
  createUserGroups,
  deleteUserGroups,
  editUserGroups,
  refreshToken,
  clientSettings,
  updateClientSetting,
  enableSetting,
  updateAverageValueOfNewCustomer,
  createUser,
  getUserProfile,
  deleteUser,
  updateMyUserProfile,
  updatePasswordNewUser,
  updateUser,
  updateClientProfile,
  getPresignUrl,
  changePassword,
  createClient,
  adminCreateClientForUser,
  deleteClient,
  sendInvitationEmail,
  moveUsers,
  deleteUsers,
  deleteClients,
  adminChangeClientPassword,
  confirmChangeEmail,
  resendEmailVerification,
  getTeamMember,
  getTeamMemberDetail,
  addTeamMember,
  deleteTeamMember,
  updatePermissionTeamMember,
  createAccountForMember,
  getAcceptInvitation,
  getGuestUser,
  putGuestUser,
  createGuestUser,
  getListCompanyUser,
  createNewSite,
  selfDeleteClient,
  logAccessClient,
  verifyAccount,
  saveDashboardSettings,
};

export const AuthServiceApi = {
  toggleService,
  addUserService,
  deleteUserService,
  getUserService,
  getAddableUserService,
  getClientService,
  getAccessServices,
};
