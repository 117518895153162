import { ApexOptions } from "apexcharts";
import { memo } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import useZoho, { ChartMockupData } from "../../../../../hooks/useZoho";
import SpinDiv from "../../../../common/spin-div";
import { NUMBER_UTIL } from "../../../../../utils/number.utils";


function CallStatusChart() {
    const {
        callStatus,
    } = useZoho();

    const { t } = useTranslation();

    function getCallStatusChartData() {
        if (!callStatus.data?.reportItem.length) return ChartMockupData.CallStatus;

        const updatedCallResultTypes = callStatus.data.callResultTypes.map((type) => {
            const reportItem = callStatus.data?.reportItem?.find((item) => item.result === type.value);
            return {
                ...type,
                chartValue: reportItem ? reportItem.count : 0
            };
        });
        return updatedCallResultTypes
    }

    const total =  getCallStatusChartData().reduce((sum, item) => sum + item.chartValue, 0);


    const series: ApexOptions["series"] = [
        {
            name: t("salesAnalytics.callStatus"),
            data: getCallStatusChartData().map((x) => total === 0 ? 0 : ((x.chartValue / total) * 100)),
        },
    ];

    const isEmpty = total === 0;

    const options: ApexOptions = {
        chart: {
            id: 'callStatusChart',
            toolbar: {
                show: false
            },
            type: 'bar',

        },
        plotOptions: {
            bar: {
                columnWidth: '48px',
                distributed: true,
            },
        },
        yaxis: {
            tickAmount: isEmpty ? 5 : 6,
            labels: {
                formatter: function (value) {
                    return NUMBER_UTIL.converNumberToPercentage(value);
                },
            },
            min: isEmpty ? 0 : undefined,
            max: isEmpty ? 5 : undefined,
        },
        dataLabels: {
            enabled: false,
        },
        colors: [
            "#f59f00",
            "#d899cb",
            "#738fbd",
            "#0185de",
            "#9bc13c",
            "#f35848",
            "#fac364",
            "#5cbae6",
            "#c13584",
            "#b956c2",
        ],
        legend: {
            show: false,
        },
        xaxis: {
            categories: getCallStatusChartData().map((x) => x.name?.replace(/_/g, ' ')),
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: getCallStatusChartData().length > 0,
                style: {
                    colors: '#4b546e',
                    fontSize: '10px',
                },
            },
        },
        grid: {
            strokeDashArray: 4,
            borderColor: '#e3e4e6',
            padding: {
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
            },
        },
    };



    return (
        <SpinDiv loading={callStatus.loading} style={{ height: 230 }}>
            <div className="call-status-chart" >
                <div className="chart" >
                    <div
                        className={`${isEmpty ? "chart-disabled " : ""}`}
                        style={{ marginBottom: -13, marginLeft: -16 }}
                    >
                        <Chart options={options} series={series} type="bar" height={230} />
                    </div>
                </div>
                <div className="right-panel" >
                    {
                        getCallStatusChartData()?.map((item, index) => (
                            <div className="wrapper" key={index} >
                                <div className="label">
                                    {item.name?.replace(/_/g, ' ')}
                                </div>
                                <div className="value">
                                    {item.chartValue}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </SpinDiv>

    );
}

export default memo(CallStatusChart);