import { useEffect, useState } from "react";
import { ZohoApi } from "../apis/zoho.api";
import { OrgCampaign } from "../models/zoho.model";

function useFetchCampaigns(orgId: number) {
  const [campaigns, setCampaigns] = useState<OrgCampaign[]>([]);
  const [loading, setLoading] = useState(false);


  async function fetchData() {
    if(!orgId) return;
    setLoading(true);
  
    try {
      const { data } = await ZohoApi.getCampaigns(orgId);
      setCampaigns(data);
    } finally{
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [orgId]);

  return {
    campaigns,
    loading,
    refetch: () => fetchData(),
  };
}

export default useFetchCampaigns;
