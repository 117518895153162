import { Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import EmptySalesAnalytics from "../../../../assets/images/sales-empty-state.png";
import { BoardType, ShouldOpenDrawer, ZohoReportType } from "../../../../constants/app.enums";
import useZoho, { ChartMockupData } from "../../../../hooks/useZoho";
import SpinDiv from "../../../common/spin-div";
import Volatility from "../../../common/volatility";
import SVGIcons from "../../../icons/svgs";
import CallStatusChart from "./call-status";
import ConnectedBookingRateChart from "./connected-booking-rate";
import ConnectionRateChart from "./connection-rate";
import RecentBookedDemoTable from "./recent-booked-demo";
import "./index.scss";
type Props = {
  selectedRangeName: string;
}

function SalesAnalyticsCharts(props: Props) {
  const { t } = useTranslation();
  const { selectedRangeName } = props;

  const {
    totalLeadWorked,
    totalCalls,
    totalDemoBook,
    findReportByType,
    getCardTitleByType,
    isEmptyState,
    connectZoho,
    connectZohoLoading,
  } = useZoho();

  function calculateVolatility(newNumber: number, preNumber: number): number {
    if (preNumber === 0 && newNumber > 0) {
      return 100;
    }
    return preNumber === 0 ? 0 : ((newNumber - preNumber) / preNumber) * 100;
  }


  const getTotalLeadWorkedChartData = () => {
    if (!findReportByType(ZohoReportType.TOTAL_LEADS_WORKED)) return ChartMockupData.CardSum;
    return {
      currentValue: totalLeadWorked.data?.currentValue,
      previousValue: totalLeadWorked.data?.previousValue,
    }
  }

  const getTotalCallChartData = () => {
    if (!findReportByType(ZohoReportType.TOTAL_CALL)) return ChartMockupData.CardSum;
    return {
      currentValue: totalCalls.data?.currentValue,
      previousValue: totalCalls.data?.previousValue,
    }
  }

  const getTotalDemoBookedChartData = () => {
    if (!findReportByType(ZohoReportType.TOTAL_DEMO_BOOKED)) return ChartMockupData.CardSum;
    return {
      currentValue: totalDemoBook.data?.currentValue,
      previousValue: totalDemoBook.data?.previousValue,
    }
  }

  if (isEmptyState) {
    return <div className="sales-analytics-charts empty-state" >
      <img src={EmptySalesAnalytics} alt="empty-img" width="100%" />
      <div className="empty" >
        <div>
          <SVGIcons.GraphicReportIcon />
          <div className="title" >
            {t("salesAnalytics.transformYourDataIntoInsights")}
          </div>
          <div className="desc" >
            {t("salesAnalytics.connectYourAccountNow")}
          </div>
          <div>
            <div className="connect-btn" >
              <Button
                loading={connectZohoLoading} onClick={() => connectZoho(false, undefined, ShouldOpenDrawer.SalesAnalyticsDashboard)}
                type="primary"
                icon={<SVGIcons.AppsIcon />}
              >
                {t("salesAnalytics.connectAccount")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  return (
    <div className="sales-analytics-charts">
      <Row gutter={16}>
        <Col xs={24} sm={12} xl={8} className="col-total-lead">
          <div className="rl-card">
            <div className="rl-card-title">
              {getCardTitleByType(ZohoReportType.TOTAL_LEADS_WORKED)}
            </div>

            <SpinDiv
              loading={totalLeadWorked.loading}
              style={{ height: 76 }}
            >
              <>
                <div className="sales-analytics-charts__cardSum">
                  <p>{getTotalLeadWorkedChartData().currentValue}</p>
                  <Volatility
                    boardType={BoardType.SALES_ANALYTICS}
                    value={
                      calculateVolatility(
                        getTotalLeadWorkedChartData().currentValue || 0,
                        getTotalLeadWorkedChartData().previousValue || 0
                      )
                    }
                  />
                </div>
                <div className="sales-analytics-charts__cardSum-time">
                  {selectedRangeName}:{" "}
                  {getTotalLeadWorkedChartData().previousValue}
                </div>
              </>
            </SpinDiv>
          </div>
        </Col>

        <Col xs={24} sm={12} xl={8} className="col-total-lead">
          <div className="rl-card">
            <div className="rl-card-title">
              {getCardTitleByType(ZohoReportType.TOTAL_CALL)}
            </div>

            <SpinDiv loading={totalCalls.loading} style={{ height: 76 }}>
              <>
                <div className="sales-analytics-charts__cardSum">
                  <p>{getTotalCallChartData().currentValue}</p>
                  <Volatility
                    boardType={BoardType.SALES_ANALYTICS}
                    value={
                      calculateVolatility(
                        getTotalCallChartData().currentValue || 0,
                        getTotalCallChartData().previousValue || 0
                      )
                    }
                  />
                </div>
                <div className="sales-analytics-charts__cardSum-time">
                  {selectedRangeName}:{" "}
                  {getTotalCallChartData().previousValue}
                </div>
              </>
            </SpinDiv>
          </div>
        </Col>

        <Col xs={24} sm={12} xl={8} className="col-total-lead">
          <div className="rl-card">
            <div className="rl-card-title">
              {getCardTitleByType(ZohoReportType.TOTAL_DEMO_BOOKED)}
            </div>

            <SpinDiv
              loading={totalDemoBook.loading}
              style={{ height: 76 }}
            >
              <>
                <div className="sales-analytics-charts__cardSum">
                  <p>{getTotalDemoBookedChartData().currentValue}</p>
                  <Volatility
                    boardType={BoardType.SALES_ANALYTICS}
                    value={
                      calculateVolatility(
                        getTotalDemoBookedChartData().currentValue || 0,
                        getTotalDemoBookedChartData().previousValue || 0
                      )
                    }
                  />
                </div>
                <div className="sales-analytics-charts__cardSum-time">
                  {selectedRangeName}:{" "}
                  {getTotalDemoBookedChartData().previousValue}
                </div>
              </>
            </SpinDiv>
          </div>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <div className="rl-card">
            <div className="rl-card-title mr-bottom-24">
              {getCardTitleByType(ZohoReportType.CALL_STATUS)}
            </div>
            <CallStatusChart />
          </div>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} xl={12}>
          <div className="rl-card">
            <div className="rl-card-title mr-bottom-24">
              {getCardTitleByType(ZohoReportType.CONNECTION_RATE)}
            </div>
            <ConnectionRateChart />
          </div>
        </Col>

        <Col xs={24} sm={24} md={12} xl={12}>
          <div className="rl-card">
            <div className="rl-card-title mr-bottom-24">
              {getCardTitleByType(ZohoReportType.CONNECTED_BOOKING_RATE)}
            </div>
            <ConnectedBookingRateChart />
          </div>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <div className="rl-card table">
            <div className="rl-card-title table">
              {getCardTitleByType(ZohoReportType.RECENT_BOOKED_DEMO)} 
            </div>
            <RecentBookedDemoTable />
          </div>
        </Col>
      </Row>

    </div>
  );
}

export default SalesAnalyticsCharts;
