import { Collapse, Spin } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ZClientConnectStatus } from "../../../../constants/app.enums";
import useZoho from "../../../../hooks/useZoho";
import SVGIcons from "../../../icons/svgs";
import ZohoCRMPanel from "../../../views/client-sites/intergration-form/zoho-crm";
import "./index.scss";

const { Panel } = Collapse;

interface IntegrationCollapseProps {
  clientId: number;
  isSalesAnalytics?: boolean;
}

const SaleAnalyticIntegrationCollapse = (props: IntegrationCollapseProps) => {
  const { clientId, isSalesAnalytics } = props;
  const { t } = useTranslation();

  const { getZohoClient, zohoClients, setZohoClients, usersInfoLoading } = useZoho();

  const renderStatus = (zohoClientStatus: ZClientConnectStatus) => {
    switch (zohoClientStatus) {
      case ZClientConnectStatus.Unused:
        return <span className="fw-500">Unused</span>;
      case ZClientConnectStatus.Active:
        return <span className="color-secondary fw-500">Active</span>;
      case ZClientConnectStatus.Error:
        return <span className="color-sunset fw-500">Error</span>;
      default:
        break;
    }
  };

  useEffect(() => {
    getZohoClient(clientId);
  }, [clientId]);

  return (
    <Spin spinning={usersInfoLoading}>
      <Collapse
        className="site-collapse-custom-collapse-integration"
        bordered={false}
        expandIconPosition={"end"}
      >
        {zohoClients.map((zohoClient, index) => {
          return (
            <Panel
              header={
                <>
                  <div className="title">
                    {t("clientSites.zohoCRM")}{" "}
                    {zohoClient.status === ZClientConnectStatus.Error ? (
                      <SVGIcons.AlertTriangleIcon />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="description">
                    {zohoClient.email} · {renderStatus(zohoClient.status)}
                  </div>
                </>
              }
              key={index}
              className={
                zohoClient.status === ZClientConnectStatus.Error
                  ? "invalid"
                  : ""
              }
            >
              <Spin spinning={usersInfoLoading}>
                <ZohoCRMPanel isSalesAnalytics={isSalesAnalytics} accountInfo={zohoClient} />
              </Spin>
            </Panel>
          );
        })}
      </Collapse>
    </Spin>
  );
};

export default SaleAnalyticIntegrationCollapse;
