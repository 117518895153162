import { Col, Row, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { BirdeyeApi } from "../../apis/birdeye.api";
import { CallRailApi } from "../../apis/callrail.api";
import { GaApi } from "../../apis/ga.api";
import { GravityFormApi } from "../../apis/gravityform.api";
import {
  DateRangePickerValues,
} from "../../components/controls/app-date-range-picker";
import CallWebForm from "../../components/views/dashboard/call-web-form";
import Performance from "../../components/views/dashboard/performance";
import ReviewsAndRatings from "../../components/views/dashboard/reviews-ratings";
import SiteTrafic from "../../components/views/dashboard/site-traffic";
import SupportModal from "../../components/views/dashboard/support-modal";
import { DATE_TIME_FORMAT } from "../../constants/app-constants";
import { DateRangeValues, SyncStatus, ZohoSyncStatus } from "../../constants/app.enums";
import { beReportDefaulValue } from "../../models/be.model";
import { DashBoardRequestModel } from "../../models/ga.model";
import {
  dasboardDateRangeState,
  dashboardBirdeyeState,
  dashboardCallsState,
  dashboardFormsState,
  dashboardGaGeneralState,
  dashboardGaTrafficState,
  dashboardGLState,
  dashboardTotalLeadsState,
} from "../../states/dashboard";

import AppDateRangePicker2 from "../../components/controls/app-date-range-picker-2";
import AppMobileDateRangePicker from "../../components/controls/app-mobile-date-range-picker";
import GoogleListings from "../../components/views/dashboard/google-listings";
import useClient from "../../hooks/useClient";
import { isMobileSelector } from "../../states/common";
import "./index.scss";
import SVGIcons from "../../components/icons/svgs";
import { useRef, useState } from "react";
import EditInsightHubDashboard from "../../components/views/dashboard/edit-dashboard-drawer";
import { ZohoApi } from "../../apis/zoho.api";

function HomePage() {
  const { t } = useTranslation();
  const setDateRange = useSetRecoilState(dasboardDateRangeState);
  const setGaGeneralState = useSetRecoilState(dashboardGaGeneralState);
  const setTrafficChanels = useSetRecoilState(dashboardGaTrafficState);
  const setCalls = useSetRecoilState(dashboardCallsState);
  const setBeReport = useSetRecoilState(dashboardBirdeyeState);
  const setGfForms = useSetRecoilState(dashboardFormsState);
  const setTotalLeads = useSetRecoilState(dashboardTotalLeadsState);
  const setGLState = useSetRecoilState(dashboardGLState);
  const { client, isAdminViewSite, clientId } = useClient();
  const onboarded = isAdminViewSite || (client && client.onBoarding);
  const isMobile = useRecoilValue(isMobileSelector);
  const [isShowEdit, setIsShowEdit] = useState<boolean>(false);
  const [syncStatus, setSyncStatus] = useState<SyncStatus>()

  async function getTotalLeads(request: DashBoardRequestModel) {
    setTotalLeads({ loading: true, data: undefined });
    try {
      const { data } = await GaApi.getTotalLeads(request);
      setTotalLeads({ loading: false, data: data });
    } catch (error) {
      setTotalLeads({ loading: false, data: undefined });
    }
  }

  async function getForms(request: DashBoardRequestModel) {
    setGfForms({ loading: true, data: undefined });
    try {
      const { data } = await GravityFormApi.getReport(request);
      setGfForms({ loading: false, data: data });
    } catch (error) {
      setGfForms({ loading: false, data: undefined });
    }
  }

  async function getReviews(request: DashBoardRequestModel) {
    try {
      setBeReport({ loading: true, data: beReportDefaulValue });
      const { data } = await BirdeyeApi.getReport(request);
      if (data.reviews && data.reviews.length) {
        setBeReport({ loading: false, data: data });
      } else {
        setBeReport({ loading: false, data: beReportDefaulValue });
      }
    } catch (error) {
      setBeReport({ loading: false, data: beReportDefaulValue });
    }
  }

  async function getGAData(request: DashBoardRequestModel) {
    setGaGeneralState({ loading: true, data: undefined });
    try {
      const { data } = await GaApi.getGeneralData(request);
      setGaGeneralState({ loading: false, data: data });
    } catch (error) {
      setGaGeneralState({ loading: false, data: undefined });
    }
  }

  async function getTrafficChanels(request: DashBoardRequestModel) {
    setTrafficChanels({ loading: true, data: undefined });
    try {
      const { data } = await GaApi.getTrafficChanel(request);
      setTrafficChanels({ loading: false, data: data });
    } catch (error) {
      setTrafficChanels({ loading: false, data: undefined });
    }
  }

  async function getCalls(request: DashBoardRequestModel) {
    try {
      setCalls({ loading: true, data: undefined });
      const { data } = await CallRailApi.getCalls(request);
      setCalls({ loading: false, data: data });
    } catch (error) {
      setCalls({ loading: false, data: undefined });
    }
  }

  async function getGLData(request: DashBoardRequestModel) {
    setGLState({ loading: true, data: undefined });
    try {
      const { data } = await GaApi.getGLData(request);
      setGLState({ loading: false, data: data });
    } catch (error) {
      setGLState({ loading: false, data: undefined });
    }
  }

  const dateRangeValue = useRef<DateRangePickerValues>();

  function dateChange(values: DateRangePickerValues) {
    dateRangeValue.current = values;
    const totalDays = values.endDate.diff(values.startDate, "days") + 1;
    const startDate = values.startDate.format(DATE_TIME_FORMAT.isoDateStartOfDay);
    const endDate = values.endDate.format(DATE_TIME_FORMAT.isoDateEndOfDay);

    if (onboarded && startDate && endDate) {
      const request: DashBoardRequestModel = {
        clientId: clientId || 0,
        endDate: endDate,
        startDate: startDate,
      };
      getGAData(request);
      getTrafficChanels(request);
      getCalls(request);
      getReviews(request);
      getForms(request);
      getTotalLeads(request);
      getGLData(request);
    }

    setDateRange({
      totalDays: totalDays,
      endDate: endDate,
      startDate: startDate,
      value: values.value,
    });
  }

  const renderSyncStatus = () => {
    switch (syncStatus) {
      case SyncStatus.Syncing:
        return <><div className="icon-refresh syncing spin-animation" ><SVGIcons.RefreshIcon /></div>{t("salesAnalytics.syncing")}</>;

      case SyncStatus.Synced:
        return <><SVGIcons.SyncDoneIcon />{t("salesAnalytics.synced")}</>;

      default:
        return "";
    }
  }

  const onSyncData = async () => {
    if (!client?.id) return;
    try {
      setSyncStatus(SyncStatus.Syncing);
      await CallRailApi.insightsHubSyncData(client?.id);
      await checkPendingSyncJobs();
    } catch (error) {
      setSyncStatus(SyncStatus.None);
    }
  };

  const checkPendingSyncJobs = () => {
    let attempts = 0;
    let intervalId: NodeJS.Timeout;
    const MAX_ATTEMPTS = 10;
    const DELAY = 15000;
    if (!client?.id) return;

    const request = async () => {
      attempts++;
      try {

        const { data } = await ZohoApi.getPendingSyncJobs(client?.id);

        const allJobsSuccess = data.jobs.every(x => x.status === ZohoSyncStatus.SUCCESS);

        if (allJobsSuccess) {
          clearInterval(intervalId);
          setSyncStatus(SyncStatus.Synced);
          if (dateRangeValue.current) {
            dateChange(dateRangeValue.current);
          }
          return data;
        }

        if (
          data.jobs.filter(x => x.status === ZohoSyncStatus.NEW).length > 0 ||
          data.jobs.filter(x => x.status === ZohoSyncStatus.RUNNING).length > 0
        ) {
          setSyncStatus(SyncStatus.Syncing);
          if (attempts >= MAX_ATTEMPTS) {
            clearInterval(intervalId);
            setSyncStatus(SyncStatus.None);
            return data;
          }
        }
      } catch (error) {
        clearInterval(intervalId);
        setSyncStatus(SyncStatus.None);
        throw error;
      }
    };

    const promise = request();

    intervalId = setInterval(request, DELAY);

    return promise;
  };

  return (
    <div className="home-page page-content">
      <div className="page-header">
        <Row>
          <Col span={12}>
            <div className="page-header-with-icon" >
              <h4 className="page-header__title">{t("dashboard.pageTitle")}</h4>
              {
                <Tooltip overlayClassName="custom-tooltip" title={t("generalSettings.edit")}>
                  <div onClick={() => setIsShowEdit(true)} className="icon-edit">
                    <SVGIcons.EditIcon />
                  </div>
                </Tooltip>
              }
              {syncStatus === SyncStatus.None ? (
                <div onClick={onSyncData} className="icon-refresh">
                  <SVGIcons.RefreshIcon />
                </div>
              ) : (
                <>
                  {
                    syncStatus ? <div className="sync-status" >
                      {renderSyncStatus()}
                    </div> : <Tooltip
                      overlayClassName="custom-tooltip"
                      title={<span>{t("salesAnalytics.refreshLatestData")}</span>}
                    >
                      <div onClick={onSyncData} className="icon-refresh">
                        <SVGIcons.RefreshIcon />
                      </div>
                    </Tooltip>
                  }
                </>
              )}
              {/* <Tooltip
                overlayClassName="custom-tooltip"
                title={<span>{t("salesAnalytics.refreshLatestData")}</span>}
              >
                   <div className="icon-refresh" >
                <SVGIcons.RefreshIcon />
              </div>
              </Tooltip> */}
            </div>
          </Col>
          <Col span={12}>
            {isMobile ? (
              <AppMobileDateRangePicker
                defaulValue={DateRangeValues.lastMonth}
                onChange={dateChange}
              />
            ) : (
              <AppDateRangePicker2
                defaulValue={DateRangeValues.lastMonth}
                onChange={dateChange}
              />
            )}
          </Col>
        </Row>
      </div>

      {onboarded ? (
        <div className="page-body">
          <div className="home-page__session">
            <h4 className="home-page__sessionTitle">
              {t("dashboard.performance")}
            </h4>
            <Performance />
          </div>

          <div className="home-page__session">
            <h4 className="home-page__sessionTitle">
              {t("dashboard.siteTrafic")}
            </h4>
            <SiteTrafic />
          </div>

          <div className="home-page__session">
            <h4 className="home-page__sessionTitle">
              {t("dashboard.googleListings")}
            </h4>
            <GoogleListings />
          </div>

          <div className="home-page__session">
            <h4 className="home-page__sessionTitle">
              {t("dashboard.reivewAndRating")}
            </h4>
            <ReviewsAndRatings />
          </div>

          <div className="home-page__session">
            <h4 className="home-page__sessionTitle">
              {t("dashboard.callsAndWWebForms")}
            </h4>
            <CallWebForm />
          </div>
        </div>
      ) : (
        <div className="page-body">
          <div className="home-page__session">
            <h4 className="home-page__sessionTitle">
              {t("dashboard.performance")}
            </h4>
          </div>

          <SupportModal />
        </div>
      )}

      <EditInsightHubDashboard isShow={isShowEdit} setIsShow={setIsShowEdit} />
    </div>
  );
}

export default HomePage;
