import { ApexOptions } from "apexcharts";
import { memo } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { EMPTY_DATA_COLOR } from "../../../../../constants/app-constants";
import useZoho from "../../../../../hooks/useZoho";
import SpinDiv from "../../../../common/spin-div";
import "./index.scss";
import { NUMBER_UTIL } from "../../../../../utils/number.utils";

function ConnectionRateChart() {
  const { t } = useTranslation();
  const {
    connectionRate,
  } = useZoho();


  const isEmpty = connectionRate?.data?.every((x) => x.value === 0);

  const getValueByLabel = (label: "None" | "Not Connected" | "Connected") => {
    const item = connectionRate.data?.find((x) => x.name === label);
    return item?.value || 0;
  }

  const fakeData = [
    {
      label: t("salesAnalytics.none"),
      color: "#f59f00",
      value: getValueByLabel("None"),
    },
    {
      label: t("salesAnalytics.connected"),
      color: "#0185de",
      value: getValueByLabel("Connected"),
    },

    {
      label: t("salesAnalytics.notConnected"),
      color: "#f35848",
      value: getValueByLabel("Not Connected"),
    },
  ];

  const series = fakeData.map((x) => x.value);

  const options: ApexOptions = {
    chart: {
      id: 'connectionRateChart',
      toolbar: {
        show: false,
      },
    },
    labels: fakeData.map((x) => x.label),
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -30,
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        return NUMBER_UTIL.converNumberToPercentage(val);
      },
      style: {
        fontSize: "14px",
        fontWeight: "300",
      },
    },
    xaxis: {
      categories: fakeData.map((x) => x.label),
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      max: 100,
    },
    fill: {
      colors: fakeData.map((x) => x.color),
    },
    tooltip: {
      fillSeriesColor: false,
      custom: function ({ series, seriesIndex }) {
        return (
          '<div class="custom-tooltip-wrapper">' +
          `<span class="tooltip-color" style="background-color: ${fakeData[seriesIndex].color}"></span>` +
          `<span class="tooltip-name">${fakeData[seriesIndex].label}:</span>` +
          "<span>" +
          series[seriesIndex] +
          "</span>" +
          "</div>"
        );
      },
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
      },
    },
  };

  return (
    <div className="connection-rate-chart">
      <SpinDiv loading={connectionRate.loading} style={{ height: 220 }}>
        <>
          <div className="connection-rate-chart__sum">
            <div>
              <span>{t("salesAnalytics.none")}</span>
              <span>
                {getValueByLabel("None")}
              </span>
            </div>

            <div>
              <span>{t("salesAnalytics.connected")}</span>
              <span>
                {getValueByLabel("Connected")}
              </span>
            </div>

            <div>
              <span>{t("salesAnalytics.notConnected")}</span>
              <span>
                {getValueByLabel("Not Connected")}
              </span>
            </div>
          </div>
          <div className="connection-rate-chart__chartWrapper">
            {isEmpty ? (
              <div
                className="connection-rate-chart__emptyChart"
                style={{
                  background: EMPTY_DATA_COLOR,
                  width: 232,
                  height: 232,
                }}
              ></div>
            ) : (
              <Chart
                options={options}
                series={series}
                type="pie"
                width={232}
                height={232}
              />
            )}
          </div>
          <div className="connection-rate-chart__legend">
            {fakeData &&
              fakeData.map((item, index) => (
                <div key={index} className="connection-rate-chart__legend__item">
                  <div style={{ backgroundColor: item.color }}></div>
                  <span className="connection-rate-chart__legend__item__name">
                    {item.label}
                  </span>
                </div>
              ))}
          </div>
        </>
      </SpinDiv>
    </div>
  );
}

export default memo(ConnectionRateChart);
