import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Tabs,
} from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthApi } from "../../../../apis/auth.api";
import { VALIDATION_MESSAGE_CONFIG } from "../../../../constants/app-constants";
import {
  FunctionEnum,
  PermissionEnum,
} from "../../../../constants/permission.constants";
import useFetchUserGroups from "../../../../hooks/useFetchUserGroups";
import useHasPermission from "../../../../hooks/useHasPermission";
import { UserCompanyModel } from "../../../../models/client.model";
import { UserListModel } from "../../../../models/user";
import "./index.scss";
import SiteTabTable from "./site-tab-table";

enum TabKeys {
  Profile = "Profile",
  Sites = "Sites",
}

interface EditUserFormProps {
  open: boolean;
  close: () => void;
  user?: UserListModel;
  getUsers: () => void;
}

function EditUserForm(props: EditUserFormProps) {
  const { open, close, user, getUsers } = props;
  const { t } = useTranslation();
  const { hasPermission } = useHasPermission();
  const [form] = Form.useForm();
  const groups = useFetchUserGroups();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(TabKeys.Profile);
  const [loadingCompany, setLoadingCompany] = useState(false);
  const [listCompanyUser, setListCompanyUser] = useState<UserCompanyModel[]>(
    []
  );

  const hasPermissionInput = hasPermission({
    permission: PermissionEnum.Edit,
    function: FunctionEnum.Users,
  });

  const getListClientUser = async () => {
    if (!user?.id) return;
    setLoadingCompany(true);
    try {
      const { data } = await AuthApi.getListCompanyUser(user?.id);
      setListCompanyUser(data);
    } catch (error) {}
    setLoadingCompany(false);
  };

  useEffect(() => {
    if (open) {
      form.resetFields();
      getListClientUser();
    }
  }, [open]);

  useEffect(() => {
    if (user) {
      form.setFieldsValue(user);
      if (user.userGroups) {
        form.setFieldValue("userGroup", Number(Object.keys(user.userGroups)));
      }
    }
  }, [user]);

  async function submit(values: any) {
    setLoading(true);
    try {
      const { firstName, lastName, userGroup, email } = values;
      user &&
        (await AuthApi.updateUser({
          firstName,
          lastName,
          userGroupIds: [userGroup],
          id: user?.id,
          email,
        }));
      getUsers();
      cancel();
    } catch (error) {}
    setLoading(false);
  }

  function okClick() {
    form.submit();
  }

  function cancel() {
    close();
  }

  const tabItems = [
    {
      label: t("users.profile"),
      key: TabKeys.Profile,
      children: (
        <Form
          name="editUserForm"
          layout="vertical"
          validateMessages={VALIDATION_MESSAGE_CONFIG}
          onFinish={submit}
          form={form}
        >
          <Form.Item>
            <label>
              {t("users.userId")}: {user?.id}
            </label>
          </Form.Item>

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                label={t("users.firstName")}
                rules={[{ required: true }]}
                name="firstName"
              >
                <Input disabled={!hasPermissionInput} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label={t("users.lastName")}
                rules={[{ required: true }]}
                name="lastName"
              >
                <Input disabled={!hasPermissionInput} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label={t("users.email")}
            name="email"
            rules={[{ required: true }, { type: "email" }]}
          >
            <Input
              placeholder={t("signUp.businessEmailPlahoder")}
              disabled={!hasPermissionInput}
            />
          </Form.Item>

          <Form.Item
            label={t("users.userGroup")}
            name="userGroup"
            rules={[{ required: true }]}
            style={{ marginBottom: 0 }}
          >
            <Select disabled={!hasPermissionInput}>
              {groups.map((x) => (
                <Select.Option value={x.id} key={x.id}>
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      ),
    },
    {
      label: (
        <div className="sites-tab">
          {`${t("users.sites")} (${listCompanyUser.length})`}
        </div>
      ),
      key: TabKeys.Sites,
      children: (
        <>
          Linked client’s sites will be shown here
          <Spin spinning={loadingCompany}>
            <SiteTabTable listCompanyUser={listCompanyUser} />
          </Spin>
        </>
      ),
    },
  ];

  return (
    <Drawer
      className="app-edit-drawer"
      title={t("users.editUserTitle")}
      open={open}
      width={540}
      onClose={() => cancel()}
      maskClosable={false}
      footer={
        <div className="drawer-footer-wrapper">
          <Button type="text" onClick={() => cancel()}>
            {t("clientSites.btnClose")}
          </Button>
          {hasPermission({
            function: FunctionEnum.Users,
            permission: PermissionEnum.Edit,
          }) && (
            <Button type="primary" onClick={okClick} loading={loading}>
              {t("clientSites.btnSave")}
            </Button>
          )}
        </div>
      }
    >
      <Tabs
        type="card"
        activeKey={activeTab}
        onChange={(key) => setActiveTab(key as TabKeys)}
        className="edit-user-site-tab"
        items={tabItems}
      />
    </Drawer>
  );
}

export default EditUserForm;
