import { Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { SocialApi } from "../../../../../apis/social.api";
import useClient from "../../../../../hooks/useClient";
import { SocialContentModel } from "../../../../../models/content.model";
import { socialDashboardDateRangeState } from "../../../../../states/social-dashboard";
import "./index.scss";
import TopPostItem from "./top-post-item";
import { SocialType } from "../../../../../constants/app.enums";

const TopPosts = () => {
  const { clientId } = useClient();

  const dateRange = useRecoilValue(socialDashboardDateRangeState);
  const [loadingTopPosts, setLoadingTopPosts] = useState(false);
  const [listTopPost, setListTopPost] = useState<SocialContentModel[]>([]);

  const initTopPosts = async () => {
    const { startDate, endDate } = dateRange;
    const params = { startDate, endDate, clientId };
    if (startDate && endDate && clientId) {
      setLoadingTopPosts(true);
      try {
        const { data } = await SocialApi.SocialDashboard.getTopPlatformContents(
          params
        );
        if(data && data.length) {

        }
        setListTopPost(data.filter(x=> x.platform !== SocialType.Google));
      } catch (error) { }
      setLoadingTopPosts(false);
    }
  };

  useEffect(() => {
    initTopPosts();
  }, [dateRange]);

  return (
    <div className="social-dashboard-topposts">
      {/* <Row gutter={[16,16]}>
        {[topPostFacebook, topPostInstagram, topPostTiktok, topPostYoutube].map(
          (topPostInfo, index) => {
            return (
              <Col xl={8} sm={24} xs={24} key={index}>
                <Spin spinning={loadingTopPosts}>
                  <div className="rl-card">
                    <div className="rl-card-toppost">
                      <TopPostItem topPostInfo={topPostInfo} />
                    </div>
                  </div>
                </Spin>
              </Col>
            );
          }
        )}
      </Row> */}

      <Row gutter={[16, 16]}>
        <Col xl={8} sm={24} xs={24}>
          <Spin spinning={loadingTopPosts}>
            <div className="rl-card">
              <div className="rl-card-toppost">
                <TopPostItem topPostInfo={listTopPost.length > 0 ? listTopPost[0] : undefined} />
              </div>
            </div>
          </Spin>
          {
            listTopPost.length > 3 &&
            <>
              <br />
              <Spin spinning={loadingTopPosts}>
                <div className="rl-card">
                  <div className="rl-card-toppost">
                    <TopPostItem topPostInfo={listTopPost[3]} />
                  </div>
                </div>
              </Spin>
            </>
          }
        </Col>

        <Col xl={8} sm={24} xs={24}>
          <Spin spinning={loadingTopPosts}>
            <div className="rl-card">
              <div className="rl-card-toppost">
                <TopPostItem topPostInfo={listTopPost.length > 1 ? listTopPost[1] : undefined} />
              </div>
            </div>
          </Spin>
          {
            listTopPost.length > 4 &&
            <>
              <br />
              <Spin spinning={loadingTopPosts}>
                <div className="rl-card">
                  <div className="rl-card-toppost">
                    <TopPostItem topPostInfo={listTopPost[4]} />
                  </div>
                </div>
              </Spin>
            </>
          }
        </Col>

        <Col xl={8} sm={24} xs={24}>
          <Spin spinning={loadingTopPosts}>
            <div className="rl-card">
              <div className="rl-card-toppost">
                <TopPostItem topPostInfo={listTopPost.length > 2 ? listTopPost[2] : undefined} />
              </div>
            </div>
          </Spin>
          {
            listTopPost.length > 5 &&
            <>
              <br />
              <Spin spinning={loadingTopPosts}>
                <div className="rl-card">
                  <div className="rl-card-toppost">
                    <TopPostItem topPostInfo={listTopPost[5]} />
                  </div>
                </div>
              </Spin>
            </>
          }
        </Col>


      </Row>
    </div>
  );
};

export default TopPosts;
