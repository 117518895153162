import { Button, Dropdown, Menu, Modal, Select } from "antd";
import { useTranslation } from "react-i18next";
import "./index.scss";
import { useState } from "react";
import SVGIcons from "../../../icons/svgs";
import useHasPermission from "../../../../hooks/useHasPermission";
import {
  FunctionEnum,
  PermissionEnum,
} from "../../../../constants/permission.constants";
import useFetchUserGroups from "../../../../hooks/useFetchUserGroups";
import showNotification from "../../../common/notification";
import { UserGroupModel } from "../../../../models/user-group.model";
import { BulkActionType } from "../../../../constants/app.enums";
import EditNotifications from "./edit-notifications";
import { NotificationSettings } from "../../../../models/notification.model";
import { NotificationApi } from "../../../../apis/notification.api";

interface Props {
  typeBulkAction: string;
  bulkAction: BulkActionType | null;
  onSetBulkAction: any;
  amount: number;
  onDeleteAction: () => void;
  onMoveAction: (selectedGroup: UserGroupModel) => void;
  onSelectAll?: () => void;
  onDeselectAll?: () => void;
  total?: number;
  clientIds?: number[];
}
function BulkAction(props: Props) {
  const {
    typeBulkAction,
    bulkAction,
    onSetBulkAction,
    amount,
    onDeleteAction,
    onMoveAction,
    onSelectAll,
    onDeselectAll,
    clientIds,
    total
  } = props;
  const { t } = useTranslation();
  const groups = useFetchUserGroups();
  const { hasPermission } = useHasPermission();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenEditNotificationsModal, setIsOpenEditNotificationsModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<number | undefined>();
  const [isEditNotificationLoading, setIsEditNotificationLoading] = useState(false);

  const defaultNotificationSettings: NotificationSettings = {
    dbConnectionLost: false,
    dbDataSyncFailed: false,
    socialContentFailed: false,
    socialContentSuccessfully: false,
    socialRequireApproval: true,
    socialContentApproved: true,
    socialContentDeclined: true,
    socialNewComment: true,
  }

  const [settings, setSettings] = useState<NotificationSettings>(defaultNotificationSettings);

  const handleMenuClick = (menuItem: any) => {
    onSetBulkAction((menuItem.key));
  };

  const cancelAction = () => {
    onSetBulkAction(null);
    onDeselectAll?.();
  };

  const deleteAction = async () => {
    if (amount === 0) return;
    Modal.confirm({
      title: t(`${typeBulkAction}.deleteBulkActionTitle`, { amount: amount }),
      content: (
        <span>
          {t(`${typeBulkAction}.deleteBulkActionDescription`, {
            amount: amount,
          })}
        </span>
      ),
      okText: t("common.delete"),
      cancelText: t("common.cancel"),
      cancelButtonProps: { type: "text" },
      icon: "",
      className: "confirm-modal confirm-delete-modal",
      centered: true,
      onOk: () => onDeleteAction(),
    });
  };

  const moveAction = async () => {
    const group = groups.find((g) => g.id === selectedGroup);
    if (!selectedGroup || !group) {
      showNotification("warning", "Please select group!");
      return;
    }
    onMoveAction(group);
    setIsOpenModal(false);
    setIsOpenEditNotificationsModal(false);
  };

  const onChangeGroup = (value: any) => {
    setSelectedGroup(value);
  };

  const menu = {
    items: [
      {
        label: t(`${typeBulkAction}.labelMoveAction`),
        key: BulkActionType.MOVE,
      },
      {
        label: t(`${typeBulkAction}.editNotifications`),
        key: BulkActionType.EDIT_NOTIFICATIONS,
      },
      hasPermission({
        function: FunctionEnum.Everything,
        permission: PermissionEnum.Delete,
      })
        ? {
            label: t(`${typeBulkAction}.labelDeleteAction`),
            key: BulkActionType.DELETE,
            danger: true,
          }
        : null,
    ],
    onClick: handleMenuClick,
  }

  const renderActionBtn = () => {
    switch (bulkAction) {
      case BulkActionType.MOVE:
        return (
          <Button
            type="primary"
            className="secondary-btn mr-right-16"
            onClick={() => amount > 0 && setIsOpenModal(true)}
          >
            {t(`${typeBulkAction}.moveBulkActionBtn`, { amount: amount })}
          </Button>
        );
      case BulkActionType.EDIT_NOTIFICATIONS:
        return (
          <Button
            type="primary"
            className="secondary-btn mr-right-16"
            onClick={() => amount > 0 && setIsOpenEditNotificationsModal(true)}
          >
          {t(`${typeBulkAction}.editBulkActionBtn`, { amount: amount })}
          </Button>
        );
      case BulkActionType.DELETE:
        return (
          <Button
            type="primary"
            className="mr-right-16"
            danger
            onClick={deleteAction}
          >
            {t(`${typeBulkAction}.deleteBulkActionBtn`, { amount: amount })}
          </Button>
        );

      default:
        break;
    }
  };

  const onEditNotifications = async() => {
    if(!clientIds || clientIds.length === 0)  return
    try {
      setIsEditNotificationLoading(true);

      await NotificationApi.saveClientsSettings(
        {
          clientIds,
          setting: settings,
        }
      )

      setIsOpenEditNotificationsModal(false);
      showNotification("success", t("common.selectedClientSettingsSaveSuccessfully"))

      setSettings(defaultNotificationSettings);
      
    } finally {
      setIsEditNotificationLoading(false);
    }
  }

  const onCancelEditNotifications = async() => {
    setSettings(defaultNotificationSettings);
    setIsOpenEditNotificationsModal(false);
  }

  return (
    <>
      {!bulkAction ? (
        <Dropdown menu={menu}>
          <Button>
            <span className="mr-right-4">{t("clientSites.bulkActions")}</span>
            <SVGIcons.ChevronDown />
          </Button>
        </Dropdown>
      ) : (
        <>
          {renderActionBtn()}
          {
            amount === 0 && onSelectAll && <Button className="mr-right-12" onClick={onSelectAll}>{t("common.selectAll")} ({total}) </Button>
          }
           {
            amount > 0 && onDeselectAll && <Button className="mr-right-12" onClick={onDeselectAll}>{t("common.deselectAll")} ({amount})</Button>
          }
          <Button onClick={cancelAction}>{t("common.cancel")}</Button>
        </>
      )}
      <Modal
        open={isOpenModal}
        title={t(`${typeBulkAction}.moveBulkActionTitle`)}
        okText={t("common.move")}
        onOk={moveAction}
        className="custom-move-action-modal custom-create-modal"
        onCancel={() => setIsOpenModal(false)}
      >
        <div className="modal-content">
          <div className="description">
            {t(`${typeBulkAction}.moveBulkActionDescription`, {
              amount: amount,
            })}
          </div>
          <div className="select-label">
            {t(`${typeBulkAction}.listGroupName`)}
          </div>
          <Select
            onChange={onChangeGroup}
            suffixIcon={<SVGIcons.ChevronDown />}
          >
            {groups.map((x) => (
              <Select.Option value={x.id} key={x.id}>
                {x.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </Modal>

      {/* Edit notifications */}
      <Modal
        open={isOpenEditNotificationsModal}
        title={t(`${typeBulkAction}.editNotifications`)}
        okText={t("common.done")}
        onOk={onEditNotifications}
        className="custom-move-action-modal custom-create-modal"
        onCancel={onCancelEditNotifications}
        confirmLoading={isEditNotificationLoading}
      >
        <div className="modal-content">
          <div dangerouslySetInnerHTML={{
            __html: t("clientSites.editNotificationsClientsNumber", {
              number: amount,
            }),
          }} className="notification-title"/>
          <EditNotifications settings={settings} setSettings={setSettings} />
        </div>
      </Modal>
    </>
  );
}

export default BulkAction;
