import ImportPNG from "../../assets/icons/import-icon.png";
import FacebookPNG from "../../assets/images/app-icon-facebook.png";
import InstagramPNG from "../../assets/images/app-icon-instag.png";
import YoutubePNG from "../../assets/images/app-icon-youtube.png";
import GooglePNG from "../../assets/images/app-icon-google.png";
import TiktokPNG from "../../assets/images/app-icon-tiktok.png";
import LinkedInPNG from "../../assets/images/app-icon-linkedin.png";
import LinkedInGreyIconPNG from "../../assets/images/icon-linkedin-grey.png";
import GGDrivePNG from "../../assets/images/import-google-drive.png";
import DevicePNG from "../../assets/images/upload-device.png";
import LogoGAPNG from "../../assets/icons/logo-GA.png";
import LogoGMBPNG from "../../assets/icons/logo-GMB.png";
import LogoBEPNG from "../../assets/icons/logo-BE.png";
import LogoCRPNG from "../../assets/icons/logo-CR.png";
import LogoGFPNG from "../../assets/icons/logo-GF.png";
import LogoGoogleSVG from "../../assets/icons/Google-Button.svg";
import DragIconPNG from "../../assets/icons/icon-drag.png";
import InstagramLikePNG from "../../assets/icons/instag-like.png";
import InstagramRepPNG from "../../assets/icons/instag-rep.png";
import InstagramDMPNG from "../../assets/icons/instag-dm.png";
import InstagramBookmarkPNG from "../../assets/icons/instag-bookmark.png";
import TiktokLikePNG from "../../assets/icons/tiktok-like.png";
import TiktokCommentPNG from "../../assets/icons/tiktok-cmt.png";
import TiktokSharePNG from "../../assets/icons/tiktok-share.png";
import BrandYoutubePNG from "../../assets/icons/brand-youtube.png";
import ZohoIconPNG from "../../assets/icons/logo-zoho.png";


const ImportIcon = () => <img src={ImportPNG} alt="" />;
const FacebookImage = () => <img src={FacebookPNG} alt="" />;
const InstagramImage = () => <img src={InstagramPNG} alt="" />;
const YoutubeImage = () => <img src={YoutubePNG} alt="" />;
const GoogleImage = () => <img src={GooglePNG} alt="" />;
const TiktokImage = () => <img src={TiktokPNG} alt="" />;
const LinkedInImage = () => <img src={LinkedInPNG} alt="" />;
const LinkedInGreyIcon = () => <img src={LinkedInGreyIconPNG} alt="" />;
const GGDriveImage = () => <img src={GGDrivePNG} alt="" />;
const DeviceUploadImage = () => <img src={DevicePNG} alt="" />;
const LogoGAIcon = () => <img src={LogoGAPNG} alt="" />;
const LogoGMBIcon = () => <img src={LogoGMBPNG} alt="" />;
const LogoBEIcon = () => <img src={LogoBEPNG} alt="" />;
const LogoCRIcon = () => <img src={LogoCRPNG} alt="" />;
const LogoGFIcon = () => <img src={LogoGFPNG} alt="" />;
const LogoGoogleIcon = () => <img src={LogoGoogleSVG} alt="" />;
const DragIcon = () => <img src={DragIconPNG} alt="" />;
const InstagramLikeIcon = () => <img src={InstagramLikePNG} alt="" />;
const InstagramRepIcon = () => <img src={InstagramRepPNG} alt="" />;
const InstagramDMIcon = () => <img src={InstagramDMPNG} alt="" />;
const InstagramBookmarkIcon = () => <img src={InstagramBookmarkPNG} alt="" />;
const TiktokLikeIcon = () => <img src={TiktokLikePNG} alt="" />;
const TiktokCommentIcon = () => <img src={TiktokCommentPNG} alt="" />;
const TiktokShareIcon = () => <img src={TiktokSharePNG} alt="" />;
const ZohoIcon = () => <img src={ZohoIconPNG} alt="" />;
// const BrandYoutubeIcon = () => <img src={BrandYoutubePNG} alt="" />;

const BrandYoutubeNotFillIcon = () => <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  strokeLinecap="round"  strokeLinejoin="round" ><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M2 8a4 4 0 0 1 4 -4h12a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-12a4 4 0 0 1 -4 -4v-8z" /><path d="M10 9l5 3l-5 3z" /></svg>

const BrandYoutubeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" className="icon icon-tabler icons-tabler-filled icon-tabler-brand-youtube"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 3a5 5 0 0 1 5 5v8a5 5 0 0 1 -5 5h-12a5 5 0 0 1 -5 -5v-8a5 5 0 0 1 5 -5zm-9 6v6a1 1 0 0 0 1.514 .857l5 -3a1 1 0 0 0 0 -1.714l-5 -3a1 1 0 0 0 -1.514 .857z" /></svg>
)

const BrandGoogleNotFillIcon = ()=> <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  strokeLinecap="round"  strokeLinejoin="round" ><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M20.945 11a9 9 0 1 1 -3.284 -5.997l-2.655 2.392a5.5 5.5 0 1 0 2.119 6.605h-4.125v-3h7.945z" /></svg>

const BrandGoogleIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" className="icon icon-tabler icons-tabler-filled icon-tabler-brand-google"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 2a9.96 9.96 0 0 1 6.29 2.226a1 1 0 0 1 .04 1.52l-1.51 1.362a1 1 0 0 1 -1.265 .06a6 6 0 1 0 2.103 6.836l.001 -.004h-3.66a1 1 0 0 1 -.992 -.883l-.007 -.117v-2a1 1 0 0 1 1 -1h6.945a1 1 0 0 1 .994 .89c.04 .367 .061 .737 .061 1.11c0 5.523 -4.477 10 -10 10s-10 -4.477 -10 -10s4.477 -10 10 -10z" /></svg>
)

const BrandLinkedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8.797" height="8.357" viewBox="0 0 8.797 8.357" fill="#9b9b9b"  className="icon icon-tabler icons-tabler-filled icon-tabler-brand-linkedin">
    <path d="M4.972 6.979h.011a.953.953 0 0 0 1-.99A.949.949 0 0 0 5 5a.952.952 0 0 0-1 .99.947.947 0 0 0 .972.989zM4 7.639h1.979v5.718H4zm8.8 2.089a2.088 2.088 0 0 0-3.742-1.279v-.81H7.079v5.718h1.979v-3.3a.88.88 0 0 1 1.759 0v3.3H12.8z" transform="translate(-4 -5)" />
</svg>

)
const GoogleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M17.788 5.108a9 9 0 1 0 3.212 6.892h-8"></path>
  </svg>
);
const WarningIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-exclamation-circle"
    width={22}
    height={22}
    viewBox="0 0 24 24"
    strokeWidth={2}
    stroke="#fff"
    fill="#0185de"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
    <path d="M12 9v4" />
    <path d="M12 16v.01" />
  </svg>
);

const AtomFilledIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M12 8a4 4 0 1 1 -3.995 4.2l-.005 -.2l.005 -.2a4 4 0 0 1 3.995 -3.8z" strokeWidth="0" fill="currentColor" />
  <path d="M12 20a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z" strokeWidth="0" fill="currentColor" />
  <path d="M3 8a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z" strokeWidth="0" fill="currentColor" />
  <path d="M21 8a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z" strokeWidth="0" fill="currentColor" />
  <path d="M2.89 12.006a1 1 0 0 1 1.104 .884a8 8 0 0 0 4.444 6.311a1 1 0 1 1 -.876 1.799a10 10 0 0 1 -5.556 -7.89a1 1 0 0 1 .884 -1.103z" strokeWidth="0" fill="currentColor" />
  <path d="M20.993 12l.117 .006a1 1 0 0 1 .884 1.104a10 10 0 0 1 -5.556 7.889a1 1 0 1 1 -.876 -1.798a8 8 0 0 0 4.444 -6.31a1 1 0 0 1 .987 -.891z" strokeWidth="0" fill="currentColor" />
  <path d="M5.567 4.226a10 10 0 0 1 12.666 0a1 1 0 1 1 -1.266 1.548a8 8 0 0 0 -10.134 0a1 1 0 1 1 -1.266 -1.548z" strokeWidth="0" fill="currentColor" />
</svg>

const ChartAreaIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M15.22 9.375a1 1 0 0 1 1.393 -.165l.094 .083l4 4a1 1 0 0 1 .284 .576l.009 .131v5a1 1 0 0 1 -.883 .993l-.117 .007h-16.022l-.11 -.009l-.11 -.02l-.107 -.034l-.105 -.046l-.1 -.059l-.094 -.07l-.06 -.055l-.072 -.082l-.064 -.089l-.054 -.096l-.016 -.035l-.04 -.103l-.027 -.106l-.015 -.108l-.004 -.11l.009 -.11l.019 -.105c.01 -.04 .022 -.077 .035 -.112l.046 -.105l.059 -.1l4 -6a1 1 0 0 1 1.165 -.39l.114 .05l3.277 1.638l3.495 -4.369z" strokeWidth="0" fill="currentColor" />
  <path d="M15.232 3.36a1 1 0 0 1 1.382 -.15l.093 .083l4 4a1 1 0 0 1 -1.32 1.497l-.094 -.083l-3.226 -3.225l-4.299 5.158a1 1 0 0 1 -1.1 .303l-.115 -.049l-3.254 -1.626l-2.499 3.332a1 1 0 0 1 -1.295 .269l-.105 -.069a1 1 0 0 1 -.269 -1.295l.069 -.105l3 -4a1 1 0 0 1 1.137 -.341l.11 .047l3.291 1.645l4.494 -5.391z" strokeWidth="0" fill="currentColor" />
</svg>

const EmailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <rect x="3" y="5" width="18" height="14" rx="2"></rect>
    <polyline points="3 7 12 13 21 7"></polyline>
  </svg>
);

const KeyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx="8" cy="15" r="4"></circle>
    <line x1="10.85" y1="12.15" x2="19" y2="4"></line>
    <line x1="18" y1="5" x2="20" y2="7"></line>
    <line x1="15" y1="8" x2="17" y2="10"></line>
  </svg>
);

const CheckedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M44 0a12 12 0 1 0 12 12A12 12 0 0 0 44 0zm5.707 8.707-8 8a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L41 14.586l7.293-7.293a1 1 0 0 1 1.414 1.414z"
      transform="translate(-32)"
      style={{ fill: "#9bc13c" }}
      data-name="050---Good-Conversion-Rate"
    />
  </svg>
);

const CheckedNotRoundedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M5 12l5 5l10 -10"></path>
  </svg>
);
const ErrorIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g data-name="icon-error">
      <g data-name="Layer 3">
        <path
          data-name="Path 405"
          d="M26.22 9.25h-9.94l-7.03 7.03v9.94l7.03 7.03h9.94l7.03-7.03v-9.94zm-.471 15.637-.862.862-3.637-3.636-3.637 3.637-.862-.863 3.637-3.637-3.637-3.637.862-.862 3.637 3.637 3.637-3.637.862.862-3.636 3.637z"
          transform="translate(-9.25 -9.25)"
          style={{ fill: "#f35848" }}
        />
      </g>
    </g>
  </svg>
);

const MessageIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M12 20l-3 -3h-2a3 3 0 0 1 -3 -3v-6a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-2l-3 3"></path>
    <line x1="8" y1="9" x2="16" y2="9"></line>
    <line x1="8" y1="13" x2="14" y2="13"></line>
  </svg>
);

const BellIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"></path>
    <path d="M9 17v1a3 3 0 0 0 6 0v-1"></path>
  </svg>
);

const BallIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx="12" cy="12" r="9"></circle>
    <path d="M12 7l4.76 3.45l-1.76 5.55h-6l-1.76 -5.55z"></path>
    <path d="M12 7v-4m3 13l2.5 3m-.74 -8.55l3.74 -1.45m-11.44 7.05l-2.56 2.95m.74 -8.55l-3.74 -1.45"></path>
  </svg>
);

const Layout2Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <rect x="4" y="4" width="6" height="5" rx="2"></rect>
    <rect x="4" y="13" width="6" height="7" rx="2"></rect>
    <rect x="14" y="4" width="6" height="7" rx="2"></rect>
    <rect x="14" y="15" width="6" height="5" rx="2"></rect>
  </svg>
);

const UsersIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx="9" cy="7" r="4"></circle>
    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
  </svg>
);

const Map2Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <line x1="18" y1="6" x2="18" y2="6.01"></line>
    <path d="M18 13l-3.5 -5a4 4 0 1 1 7 0l-3.5 5"></path>
    <polyline points="10.5 4.75 9 4 3 7 3 20 9 17 15 20 21 17 21 15"></polyline>
    <line x1="9" y1="4" x2="9" y2="17"></line>
    <line x1="15" y1="15" x2="15" y2="20"></line>
  </svg>
);

const WorldIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx="12" cy="12" r="9"></circle>
    <line x1="3.6" y1="9" x2="20.4" y2="9"></line>
    <line x1="3.6" y1="15" x2="20.4" y2="15"></line>
    <path d="M11.5 3a17 17 0 0 0 0 18"></path>
    <path d="M12.5 3a17 17 0 0 1 0 18"></path>
  </svg>
);

const TrendUpIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <polyline points="3 17 9 11 13 15 21 7"></polyline>
    <polyline points="14 7 21 7 21 14"></polyline>
  </svg>
);

const TrenDownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <polyline points="3 7 9 13 13 9 21 17"></polyline>
    <polyline points="21 10 21 17 14 17"></polyline>
  </svg>
);

const MinusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <line x1="5" y1="12" x2="19" y2="12"></line>
  </svg>
);

const ThumbUpIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M7 11v8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3"></path>
  </svg>
);

const ThumbDownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M7 13v-8a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v7a1 1 0 0 0 1 1h3a4 4 0 0 1 4 4v1a2 2 0 0 0 4 0v-5h3a2 2 0 0 0 2 -2l-1 -5a2 3 0 0 0 -2 -2h-7a3 3 0 0 0 -3 3"></path>
  </svg>
);

const CalendarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <rect x="4" y="5" width="16" height="16" rx="2"></rect>
    <line x1="16" y1="3" x2="16" y2="7"></line>
    <line x1="8" y1="3" x2="8" y2="7"></line>
    <line x1="4" y1="11" x2="20" y2="11"></line>
    <line x1="11" y1="15" x2="12" y2="15"></line>
    <line x1="12" y1="15" x2="12" y2="18"></line>
  </svg>
);

const ChevronDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <polyline points="6 9 12 15 18 9"></polyline>
  </svg>
);

const UserCheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx="9" cy="7" r="4"></circle>
    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
    <path d="M16 11l2 2l4 -4"></path>
  </svg>
);

const BrowserIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <rect x="4" y="4" width="16" height="16" rx="1"></rect>
    <line x1="4" y1="8" x2="20" y2="8"></line>
    <line x1="8" y1="4" x2="8" y2="8"></line>
  </svg>
);

const SearchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="app-icon"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx="10" cy="10" r="7"></circle>
    <line x1="21" y1="21" x2="15" y2="15"></line>
  </svg>
);

const StartIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"></path>
  </svg>
);

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </svg>
);

const PlusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <line x1="12" y1="5" x2="12" y2="19"></line>
    <line x1="5" y1="12" x2="19" y2="12"></line>
  </svg>
);

const ClipBoardTextIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path>
    <rect x="9" y="3" width="6" height="4" rx="2"></rect>
    <path d="M9 12h6"></path>
    <path d="M9 16h6"></path>
  </svg>
);

const AdjustmentsHorizontalIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx="14" cy="6" r="2"></circle>
    <line x1="4" y1="6" x2="12" y2="6"></line>
    <line x1="16" y1="6" x2="20" y2="6"></line>
    <circle cx="8" cy="12" r="2"></circle>
    <line x1="4" y1="12" x2="6" y2="12"></line>
    <line x1="10" y1="12" x2="20" y2="12"></line>
    <circle cx="17" cy="18" r="2"></circle>
    <line x1="4" y1="18" x2="15" y2="18"></line>
    <line x1="19" y1="18" x2="20" y2="18"></line>
  </svg>
);

const BriefcaseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <rect x="3" y="7" width="18" height="13" rx="2"></rect>
    <path d="M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2"></path>
    <line x1="12" y1="12" x2="12" y2="12.01"></line>
    <path d="M3 13a20 20 0 0 0 18 0"></path>
  </svg>
);

const DollarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M16.7 8a3 3 0 0 0 -2.7 -2h-4a3 3 0 0 0 0 6h4a3 3 0 0 1 0 6h-4a3 3 0 0 1 -2.7 -2"></path>
    <path d="M12 3v3m0 12v3"></path>
  </svg>
);

const QuestionIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M8 8a3.5 3 0 0 1 3.5 -3h1a3.5 3 0 0 1 3.5 3a3 3 0 0 1 -2 3a3 4 0 0 0 -2 4"></path>
    <line x1="12" y1="19" x2="12" y2="19.01"></line>
  </svg>
);

const HomeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <polyline points="5 12 3 12 12 3 21 12 19 12"></polyline>
    <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path>
    <rect x="10" y="12" width="4" height="4"></rect>
  </svg>
);

const EditIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="17"
    viewBox="0 0 24 24"
    strokeWidth="1"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
    <line x1="13.5" y1="6.5" x2="17.5" y2="10.5"></line>
  </svg>
);

const WordIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx="12" cy="12" r="9"></circle>
    <line x1="3.6" y1="9" x2="20.4" y2="9"></line>
    <line x1="3.6" y1="15" x2="20.4" y2="15"></line>
    <path d="M11.5 3a17 17 0 0 0 0 18"></path>
    <path d="M12.5 3a17 17 0 0 1 0 18"></path>
  </svg>
);

const DeleteNotification = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M12 0a12 12 0 1 0 12 12A12 12 0 0 0 12 0zm0 6a1.5 1.5 0 1 1-1.5 1.5A1.5 1.5 0 0 1 12 6zm2.25 12h-4.5a.75.75 0 0 1 0-1.5h.75V12h-.75a.75.75 0 0 1 0-1.5h3a.75.75 0 0 1 .75.75v5.25h.75a.75.75 0 0 1 0 1.5z"
      style={{ fill: "#a1aac7" }}
    />
  </svg>
);

const ChevronLeft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <polyline points="15 6 9 12 15 18"></polyline>
  </svg>
);

const ChevronRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <polyline points="9 6 15 12 9 18"></polyline>
  </svg>
);

const ChevronUp = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M6 15l6 -6l6 6"></path>
  </svg>
);

const LockOpenIcon = () => (
  <svg
    className="icon icon-tabler icon-tabler-lock-open"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M5 11m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z"></path>
    <path d="M12 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
    <path d="M8 11v-5a4 4 0 0 1 8 0"></path>
  </svg>
);

const LockIcon = () => (
  <svg
    className="icon icon-tabler icon-tabler-lock"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M5 13a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-6z"></path>
    <path d="M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0"></path>
    <path d="M8 11v-4a4 4 0 1 1 8 0v4"></path>
  </svg>
);

const PolygonIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="1"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx="12" cy="5" r="2"></circle>
    <circle cx="19" cy="8" r="2"></circle>
    <circle cx="5" cy="11" r="2"></circle>
    <circle cx="15" cy="19" r="2"></circle>
    <path d="M6.5 9.5l3.5 -3"></path>
    <path d="M14 5.5l3 1.5"></path>
    <path d="M18.5 10l-2.5 7"></path>
    <path d="M13.5 17.5l-7 -5"></path>
  </svg>
);

const RefreshIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="1"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4"></path>
    <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4"></path>
  </svg>
);

const SyncDoneIcon = () => (
 <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-check"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l5 5l10 -10" /></svg>
);

const TrashIcon = () => (
  <svg
    className="trash-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <line x1="4" y1="7" x2="20" y2="7"></line>
    <line x1="10" y1="11" x2="10" y2="17"></line>
    <line x1="14" y1="11" x2="14" y2="17"></line>
    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
  </svg>
);

const MenuIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <line x1="4" y1="6" x2="20" y2="6"></line>
    <line x1="4" y1="12" x2="20" y2="12"></line>
    <line x1="4" y1="18" x2="20" y2="18"></line>
  </svg>
);

const PhotoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <line x1="15" y1="8" x2="15.01" y2="8"></line>
    <rect x="4" y="4" width="16" height="16" rx="3"></rect>
    <path d="M4 15l4 -4a3 5 0 0 1 3 0l5 5"></path>
    <path d="M14 14l1 -1a3 5 0 0 1 3 0l2 2"></path>
  </svg>
);

const MoodSmileIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx="12" cy="12" r="9"></circle>
    <line x1="9" y1="10" x2="9.01" y2="10"></line>
    <line x1="15" y1="10" x2="15.01" y2="10"></line>
    <path d="M9.5 15a3.5 3.5 0 0 0 5 0"></path>
  </svg>
);

const HashIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <line x1="5" y1="9" x2="19" y2="9"></line>
    <line x1="5" y1="15" x2="19" y2="15"></line>
    <line x1="11" y1="4" x2="7" y2="20"></line>
    <line x1="17" y1="4" x2="13" y2="20"></line>
  </svg>
);

const BrandFacebookNotFillIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"></path>
  </svg>
);

const BrandFacebookIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="3.9"
    height="8.358"
    viewBox="0 0 3.9 8.358"
    fill="#9b9b9b"
    fillRule="evenodd"
  >
    <path
      data-name="Path 757"
      d="M30.176 211.671h-1.307v-.721c0-.375.036-.577.575-.577h.721v-1.44h-1.151a1.631 1.631 0 0 0-1.873 1.874v.865h-.864v1.44h.864v4.179h1.729v-4.179h1.154z"
      transform="translate(-26.276 -208.933)"
    />
  </svg>
);

const BrandInstagramNotFillIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z"></path>
    <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
    <path d="M16.5 7.5l0 .01"></path>
  </svg>
);

const BrandInstagramIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7.71"
    height="7.71"
    viewBox="0 0 7.71 7.71"
    fill="#9b9b9b"
  >
    <path d="M2.3 7.71A2.3 2.3 0 0 1 0 5.413V2.3A2.3 2.3 0 0 1 2.3 0h3.113a2.3 2.3 0 0 1 2.3 2.3v3.113a2.3 2.3 0 0 1-2.3 2.3zM.775 2.3v3.113A1.522 1.522 0 0 0 2.3 6.934h3.113a1.521 1.521 0 0 0 1.521-1.521V2.3A1.521 1.521 0 0 0 5.413.776H2.3A1.521 1.521 0 0 0 .775 2.3zm1.086 1.555a1.994 1.994 0 1 1 1.994 1.994 2 2 0 0 1-1.994-1.994zm.775 0a1.218 1.218 0 1 0 1.219-1.219 1.218 1.218 0 0 0-1.218 1.219zm2.739-1.979a.478.478 0 1 1 .478.478.478.478 0 0 1-.478-.478z" />
  </svg>
);

const BrandTiktokNotFillIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M21 7.917v4.034a9.948 9.948 0 0 1 -5 -1.951v4.5a6.5 6.5 0 1 1 -8 -6.326v4.326a2.5 2.5 0 1 0 4 2v-11.5h4.083a6.005 6.005 0 0 0 4.917 4.917z"></path>
  </svg>
);

const BrandTiktokIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6.723"
    height="7.71"
    viewBox="0 0 6.723 7.71"
    fill="#9b9b9b"
  >
    <path
      d="M20.975 15.77v5.248a1.1 1.1 0 0 1-1.075 1.093 1.116 1.116 0 0 1-1.11-1.1 1.062 1.062 0 0 1 1.119-1.1.744.744 0 0 1 .323.049v-1.4a2.852 2.852 0 0 0-.373-.026 2.441 2.441 0 0 0-2.445 2.479 2.472 2.472 0 0 0 2.47 2.47 2.447 2.447 0 0 0 2.479-2.462v-2.6a3.39 3.39 0 0 0 1.775.531v-1.334A1.845 1.845 0 0 1 22.3 15.77z"
      transform="translate(-17.41 -15.77)"
    />
  </svg>
);

const AlertCircle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon-alert-circle"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx="12" cy="12" r="9"></circle>
    <line x1="12" y1="8" x2="12" y2="12"></line>
    <line x1="12" y1="16" x2="12.01" y2="16"></line>
  </svg>
);

const ClockHour4 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx="12" cy="12" r="9"></circle>
    <path d="M12 12l3 2"></path>
    <path d="M12 7v5"></path>
  </svg>
);

const DotCircleHorizontalIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx="12" cy="12" r="9"></circle>
    <line x1="8" y1="12" x2="8" y2="12.01"></line>
    <line x1="12" y1="12" x2="12" y2="12.01"></line>
    <line x1="16" y1="12" x2="16" y2="12.01"></line>
  </svg>
);

const MessageCircleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1"></path>
    <line x1="12" y1="12" x2="12" y2="12.01"></line>
    <line x1="8" y1="12" x2="8" y2="12.01"></line>
    <line x1="16" y1="12" x2="16" y2="12.01"></line>
  </svg>
);

const ArrowBackUpIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-arrow-back-up"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M9 13l-4 -4l4 -4m-4 4h11a4 4 0 0 1 0 8h-1"></path>
  </svg>
);

const LinkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5"></path>
    <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5"></path>
  </svg>
);

const HandRockIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M11 11.5v-1a1.5 1.5 0 0 1 3 0v1.5"></path>
    <path d="M17 12v-6.5a1.5 1.5 0 0 1 3 0v10.5a6 6 0 0 1 -6 6h-2h.208a6 6 0 0 1 -5.012 -2.7a69.74 69.74 0 0 1 -.196 -.3c-.312 -.479 -1.407 -2.388 -3.286 -5.728a1.5 1.5 0 0 1 .536 -2.022a1.867 1.867 0 0 1 2.28 .28l1.47 1.47"></path>
    <path d="M14 10.5a1.5 1.5 0 0 1 3 0v1.5"></path>
    <path d="M8 13v-8.5a1.5 1.5 0 0 1 3 0v7.5"></path>
  </svg>
);

const PencilMinusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M8 20l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4h4z"></path>
    <path d="M13.5 6.5l4 4"></path>
    <path d="M16 18h4"></path>
  </svg>
);

const SendIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <line x1="10" y1="14" x2="21" y2="3"></line>
    <path d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5"></path>
  </svg>
);

const ClockEditIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M21 12a9.001 9.001 0 1 0 -9.972 8.948c.32 .034 .644 .052 .972 .052"></path>
    <path d="M12 7v5l2 2"></path>
    <path d="M18.42 15.61a2.1 2.1 0 0 1 2.97 2.97l-3.39 3.42h-3v-3l3.42 -3.39z"></path>
  </svg>
);

const UserXIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx="9" cy="7" r="4"></circle>
    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
    <path d="M17 9l4 4m0 -4l-4 4"></path>
  </svg>
);

const UserExclamationIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx="9" cy="7" r="4"></circle>
    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
    <line x1="19" y1="7" x2="19" y2="10"></line>
    <line x1="19" y1="14" x2="19" y2="14.01"></line>
  </svg>
);

const UserMinusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <circle cx="9" cy="7" r="4"></circle>
    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
    <line x1="16" y1="11" x2="22" y2="11"></line>
  </svg>
);

const PlugConnectXIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M20 16l-4 4"></path>
    <path d="M7 12l5 5l-1.5 1.5a3.536 3.536 0 1 1 -5 -5l1.5 -1.5z"></path>
    <path d="M17 12l-5 -5l1.5 -1.5a3.536 3.536 0 1 1 5 5l-1.5 1.5z"></path>
    <path d="M3 21l2.5 -2.5"></path>
    <path d="M18.5 5.5l2.5 -2.5"></path>
    <path d="M10 11l-2 2"></path>
    <path d="M13 14l-2 2"></path>
    <path d="M16 16l4 4"></path>
  </svg>
);

const AlertTriangleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M12 9v2m0 4v.01"></path>
    <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path>
  </svg>
);

const AlertTriangleFillIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path
      d="M11.94 2a2.99 2.99 0 0 1 2.45 1.279l.108 .164l8.431 14.074a2.989 2.989 0 0 1 -2.366 4.474l-.2 .009h-16.856a2.99 2.99 0 0 1 -2.648 -4.308l.101 -.189l8.425 -14.065a2.989 2.989 0 0 1 2.555 -1.438zm.07 14l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm-.01 -8a1 1 0 0 0 -.993 .883l-.007 .117v4l.007 .117a1 1 0 0 0 1.986 0l.007 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z"
      strokeWidth="0"
      fill="currentColor"
    ></path>
  </svg>
);

const PaperClipIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M15 7l-6.5 6.5a1.5 1.5 0 0 0 3 3l6.5 -6.5a3 3 0 0 0 -6 -6l-6.5 6.5a4.5 4.5 0 0 0 9 9l6.5 -6.5"></path>
  </svg>
);

const UploadIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path>
    <path d="M7 9l5 -5l5 5"></path>
    <path d="M12 4l0 12"></path>
  </svg>
);

const DownloadIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
    <path d="M7 11l5 5l5 -5" />
    <path d="M12 4l0 12" />
  </svg>
)

const ClickIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M3 12l3 0"></path>
    <path d="M12 3l0 3"></path>
    <path d="M7.8 7.8l-2.2 -2.2"></path>
    <path d="M16.2 7.8l2.2 -2.2"></path>
    <path d="M7.8 16.2l-2.2 2.2"></path>
    <path d="M12 12l9 3l-4 2l-2 4l-3 -9"></path>
  </svg>
);

const EyeCheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
    <path d="M12 19c-4 0 -7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7c-.42 .736 -.858 1.414 -1.311 2.033"></path>
    <path d="M15 19l2 2l4 -4"></path>
  </svg>
);

const DraftIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M5 3m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z"></path>
    <path d="M9 7l6 0"></path>
    <path d="M9 11l6 0"></path>
    <path d="M9 15l4 0"></path>
  </svg>
);

const PowerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M7 6a7.75 7.75 0 1 0 10 0"></path>
    <path d="M12 4l0 8"></path>
  </svg>
);

const CopyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z"></path>
    <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"></path>
  </svg>
);

const InfoCircleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
    <path d="M12 9h.01"></path>
    <path d="M11 12h1v4h1"></path>
  </svg>
);

const UserCircleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-user-circle"
    width={16}
    height={16}
    viewBox="0 0 24 24"
    strokeWidth={2}
    stroke="#4b546e"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
    <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
    <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855" />
  </svg>
);

const FilterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-adjustments-horizontal"
    width={16}
    height={16}
    viewBox="0 0 24 24"
    strokeWidth={2}
    stroke="#4b546e"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M14 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
    <path d="M4 6l8 0" />
    <path d="M16 6l4 0" />
    <path d="M8 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
    <path d="M4 12l2 0" />
    <path d="M10 12l10 0" />
    <path d="M17 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
    <path d="M4 18l11 0" />
    <path d="M19 18l1 0" />
  </svg>
);

const SettingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-settings"
    width={16}
    height={16}
    viewBox="0 0 24 24"
    strokeWidth={2}
    stroke="#4b546e"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" />
    <path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
  </svg>
);

const LogoutIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-logout"
    width={16}
    height={16}
    viewBox="0 0 24 24"
    strokeWidth={2}
    stroke="#4b546e"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
    <path d="M9 12h12l-3 -3" />
    <path d="M18 15l3 -3" />
  </svg>
);

const CalendarOffIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M9 5h9a2 2 0 0 1 2 2v9m-.184 3.839a2 2 0 0 1 -1.816 1.161h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 1.158 -1.815"></path>
    <path d="M16 3v4"></path>
    <path d="M8 3v1"></path>
    <path d="M4 11h7m4 0h5"></path>
    <path d="M3 3l18 18"></path>
  </svg>
);

const RefreshUserIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 24 24"
    strokeWidth="1"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4"></path>
    <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4"></path>
  </svg>
);

const CommentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M8 9h8"></path>
    <path d="M8 13h6"></path>
    <path d="M9 18h-3a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-3l-3 3l-3 -3z"></path>
  </svg>
);

const RubberStampIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M21 17.85h-18c0 -4.05 1.421 -4.05 3.79 -4.05c5.21 0 1.21 -4.59 1.21 -6.8a4 4 0 1 1 8 0c0 2.21 -4 6.8 1.21 6.8c2.369 0 3.79 0 3.79 4.05z"></path>
    <path d="M5 21h14"></path>
  </svg>
);

const BackToTopIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-arrow-bar-to-up"
    width={18}
    height={18}
    viewBox="0 0 24 24"
    strokeWidth={2}
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M12 10l0 10" />
    <path d="M12 10l4 4" />
    <path d="M12 10l-4 4" />
    <path d="M4 4l16 0" />
  </svg>
);

const ListDetailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M13 5h8" />
    <path d="M13 9h5" />
    <path d="M13 15h8" />
    <path d="M13 19h5" />
    <path d="M3 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
    <path d="M3 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
  </svg>
);

const CirclePlusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
    <path d="M9 12h6" />
    <path d="M12 9v6" />
  </svg>
);

const BuildingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M3 21l18 0" />
    <path d="M9 8l1 0" />
    <path d="M9 12l1 0" />
    <path d="M9 16l1 0" />
    <path d="M14 8l1 0" />
    <path d="M14 12l1 0" />
    <path d="M14 16l1 0" />
    <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16" />
  </svg>
);

const AppsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-apps" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M4 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
    <path d="M4 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
    <path d="M14 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
    <path d="M14 7l6 0" /><path d="M17 4l0 6" />
  </svg>
);

const BookIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-book-2" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M19 4v16h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12z" />
    <path d="M19 16h-12a2 2 0 0 0 -2 2" /><path d="M9 8h6" />
  </svg>
);

const LeftMessageIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-message" width={24}
    height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor"
    fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none"
      d="M0 0h24v24H0z" fill="none" /><path d="M8 9h8" /><path d="M8 13h6" />
    <path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z" />
  </svg>
);

const UpgradeModalCloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="#4b546e"
    fill="#4b546e"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </svg>
);
const IconHelp = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} viewBox="0 0 12 12">
    <g transform="translate(0 .437)">
      <circle data-name="Ellipse 17" cx={6} cy={6} r={6} transform="translate(0 -.437)" style={{ fill: '#e3e4e6' }} />
      <text data-name="?" transform="translate(4 7.563)" style={{ fill: '#4b546e', fontSize: 6, fontFamily: 'Rubik-Regular,Rubik', letterSpacing: '.002em' }}><tspan x={0} y={0}>?</tspan></text>
    </g>
  </svg>
);
const DiscountCheckIcon = () => (
  <svg fill="#9bc13c" width="18px" height="18px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve">
    <g>
      <path d="M102.12,132.85c0.03-16.96,13.77-30.69,30.72-30.72l25.6,0l0.01,0c21.63-0.01,42.38-8.58,57.72-23.83l0.05-0.05
   l17.92-17.92l0.05-0.05c6.07-6.09,13.81-9.03,21.79-9.06c7.92,0.03,15.6,2.93,21.66,8.94l-0.05-0.05l0.22,0.22l17.92,17.92
   l0.04,0.04c15.36,15.28,36.12,23.84,57.75,23.84l25.6,0c16.96,0.03,30.69,13.77,30.72,30.72v25.6c0,21.63,8.56,42.39,23.84,57.75
   l0.04,0.04l17.92,17.92l0.05,0.05c6.09,6.07,9.03,13.81,9.06,21.79c-0.03,7.92-2.93,15.6-8.94,21.66l0.05-0.05l-0.22,0.22
   l-17.92,17.92l-0.05,0.05c-15.25,15.34-23.82,36.09-23.83,57.72v0.01v25.6c-0.03,16.96-13.77,30.69-30.72,30.72h0h-25.6h-0.01
   c-21.63,0.01-42.38,8.58-57.72,23.83l-0.05,0.05l-17.92,17.92l-0.05,0.05c-6.07,6.09-13.81,9.03-21.79,9.06
   c-7.92-0.03-15.6-2.93-21.66-8.94l0.05,0.05l-0.22-0.22l0,0l-17.92-17.92l-0.05-0.05c-15.34-15.25-36.09-23.82-57.72-23.83h-0.01
   h-25.6c-16.96-0.03-30.69-13.77-30.72-30.72l0-25.6l0-0.01c-0.01-21.63-8.58-42.38-23.83-57.72l-0.05-0.05l-17.92-17.92l-0.05-0.05
   c-6.09-6.07-9.03-13.81-9.06-21.78c0.03-7.92,2.93-15.6,8.94-21.67l-0.05,0.05l0.22-0.22l17.92-17.92l0.05-0.05
   c15.25-15.34,23.82-36.09,23.83-57.72l0-0.01L102.12,132.85c0-14.14-11.46-25.6-25.6-25.6c-14.14,0-25.6,11.46-25.6,25.6v25.6
   v-0.01c0,8.11-3.22,15.89-8.94,21.65l0.05-0.05l-17.92,17.92l-0.22,0.22l-0.05,0.05C7.99,214.15-0.03,235.2,0,256
   c-0.03,20.96,8.11,42.15,24.16,58.09l-0.05-0.05l17.92,17.92l-0.05-0.05c5.72,5.75,8.93,13.53,8.94,21.65v-0.01v25.6
   c0.02,45.26,36.67,81.91,81.93,81.93h25.6h-0.01c8.11,0,15.89,3.22,21.65,8.94l-0.05-0.05l17.92,17.92l0,0l0.22,0.22l0.05,0.05
   c15.92,15.85,36.97,23.86,57.77,23.83c20.96,0.03,42.15-8.11,58.1-24.16l-0.05,0.05l17.92-17.92l-0.05,0.05
   c5.75-5.72,13.53-8.93,21.65-8.94h-0.01h25.6h0c45.26-0.02,81.91-36.67,81.93-81.93l0-25.6l0,0.01c0-8.11,3.22-15.89,8.94-21.65
   l-0.05,0.05l17.92-17.92l0,0l0.22-0.22l0.05-0.05c15.85-15.92,23.86-36.96,23.83-57.77c0.03-20.96-8.11-42.15-24.16-58.1l0.05,0.05
   l-17.92-17.92l0.04,0.04c-5.72-5.74-8.93-13.55-8.93-21.62l0-25.6c-0.02-45.26-36.67-81.91-81.93-81.93h-25.6
   c-8.07,0-15.89-3.21-21.62-8.93l0.04,0.04l-17.92-17.92l-0.22-0.22l-0.05-0.05C297.85,7.99,276.81-0.03,256,0
   c-20.96-0.03-42.15,8.11-58.1,24.16l0.05-0.05l-17.92,17.92l0.05-0.05c-5.75,5.72-13.53,8.93-21.65,8.94h0.01h-25.6
   c-45.26,0.02-81.91,36.67-81.93,81.93c0,14.14,11.46,25.6,25.6,25.6C90.66,158.45,102.12,146.99,102.12,132.85L102.12,132.85z" />
      <path d="M160.83,273.85l51.21,51.21c4.77,4.77,11.36,7.5,18.1,7.5c6.74,0,13.34-2.73,18.1-7.5l102.41-102.41
   c10-10,10-26.21,0-36.21c-10-10-26.21-10-36.21,0l-84.31,84.31l-33.1-33.1c-10-10-26.21-10-36.21,0
   C150.83,247.64,150.83,263.85,160.83,273.85L160.83,273.85z" />
    </g>
  </svg>


);

const ChartIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-align-box-bottom-center" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" /><path d="M9 15v2" /><path d="M12 11v6" /><path d="M15 13v4" /></svg>
);

const NotesIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-notes" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 3m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z" /><path d="M9 7l6 0" /><path d="M9 11l6 0" /><path d="M9 15l4 0" /></svg>
);

const TeamMembersIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-users" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" /><path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /><path d="M16 3.13a4 4 0 0 1 0 7.75" /><path d="M21 21v-2a4 4 0 0 0 -3 -3.85" /></svg>
);

const ReportAnalyticsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-report-analytics" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" /><path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" /><path d="M9 17v-5" /><path d="M12 17v-1" /><path d="M15 17v-3" /></svg>
);

const ClockHour4Filled = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-clock-filled" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-5 2.66a1 1 0 0 0 -.993 .883l-.007 .117v5l.009 .131a1 1 0 0 0 .197 .477l.087 .1l3 3l.094 .082a1 1 0 0 0 1.226 0l.094 -.083l.083 -.094a1 1 0 0 0 0 -1.226l-.083 -.094l-2.707 -2.708v-4.585l-.007 -.117a1 1 0 0 0 -.993 -.883z" strokeWidth={0} fill="currentColor" /></svg>
);

const CreditIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-credit-card" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 5m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z" /><path d="M3 10l18 0" /><path d="M7 15l.01 0" /><path d="M11 15l2 0" /></svg>
);

const ArrowBigUpLine = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-big-up-line" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M9 12h-3.586a1 1 0 0 1 -.707 -1.707l6.586 -6.586a1 1 0 0 1 1.414 0l6.586 6.586a1 1 0 0 1 -.707 1.707h-3.586v6h-6v-6z" /><path d="M9 21h6" /></svg>
);
const StarFilledIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-star-filled" width={24} height={24} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M8.243 7.34l-6.38 .925l-.113 .023a1 1 0 0 0 -.44 1.684l4.622 4.499l-1.09 6.355l-.013 .11a1 1 0 0 0 1.464 .944l5.706 -3l5.693 3l.1 .046a1 1 0 0 0 1.352 -1.1l-1.091 -6.355l4.624 -4.5l.078 -.085a1 1 0 0 0 -.633 -1.62l-6.38 -.926l-2.852 -5.78a1 1 0 0 0 -1.794 0l-2.853 5.78z" strokeWidth={0} fill="currentColor" /></svg>
);

const FreeTrialIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} viewBox="0 0 50 50">
    <rect data-name="Rectangle 224" width={50} height={50} rx={5} style={{ fill: '#f9e9f3' }} />
    <g transform="translate(-22.771 8.399)">
      <path data-name="Path 1263" d="M247.8 41.764h1.07v3.745h-1.07z" transform="translate(-200.559 -39.056)" style={{ fill: '#dad8db' }} />
      <path data-name="Path 1264" d="m359.092 82.969 1.98-3.021-.9-.586-1.972 3.021z" transform="translate(-303.798 -74.216)" style={{ fill: '#dad8db' }} />
      <path data-name="Path 1265" d="M344.738 1.61a5.67 5.67 0 0 0-1.625-.719c-2.125-.562-3.461.674-4.883 2.439l7.782 5.083.962-.042a5.674 5.674 0 0 0-2.236-6.761z" transform="translate(-285.125 -.706)" style={{ fill: '#c13584' }} />
      <path data-name="Path 1266" d="m331.083 7.665-6.062-3.91a1.6 1.6 0 0 1-.05-2.668 5.726 5.726 0 0 1 2.249-.9 5.686 5.686 0 0 0-5.864 1.923.541.541 0 0 0 .123.8l8.581 5.536a.528.528 0 0 0 .764-.193 5.567 5.567 0 0 0 .259-.588z" transform="translate(-269.234)" style={{ fill: '#c13584' }} />
      <path data-name="Path 1267" d="m111.561 82.969-1.98-3.021.9-.586 1.979 3.021z" transform="translate(-71.305 -74.216)" style={{ fill: '#dad8db' }} />
      <path data-name="Path 1268" d="m362.227 452.373 1.1 3.027a.625.625 0 0 1-1 .681l-2.387-2.118z" transform="translate(-305.426 -423.036)" style={{ fill: '#dad8db' }} />
      <path data-name="Path 1269" d="m96.376 449.648-1.165 3.2a.625.625 0 0 0 1 .681l2.493-2.212z" transform="translate(-57.831 -420.488)" style={{ fill: '#dad8db' }} />
      <path data-name="Path 1270" d="M113.726 423.077a13.944 13.944 0 0 0 2.33 1.673l1.551-1.376-3.21-2.14z" transform="translate(-75.181 -393.917)" style={{ fill: '#c8c5c9' }} />
      <path data-name="Path 1271" d="m337.615 421.235-3.21 2.14 1.656 1.469a13.934 13.934 0 0 0 2.288-1.59z" transform="translate(-281.548 -393.918)" style={{ fill: '#c8c5c9' }} />
      <path data-name="Path 1272" d="M89.278 104.1a12.793 12.793 0 0 1-3.367 8.668 12.551 12.551 0 0 1-9.473 3.864A12.257 12.257 0 0 1 63.982 104.1a12.493 12.493 0 0 1 3.787-9.472 12.84 12.84 0 0 1 21.509 9.472z" transform="translate(-28.663 -85.342)" style={{ fill: '#c13584' }} />
      <circle data-name="Ellipse 93" cx="10.165" cy="10.165" r="10.165" transform="translate(37.61 8.593)" style={{ fill: '#f9e8f3' }} />
      <circle data-name="Ellipse 94" cx="9.095" cy="9.095" r="9.095" transform="translate(38.68 9.663)" style={{ fill: '#fff' }} />
      <circle data-name="Ellipse 95" cx="2.14" cy="2.14" r="2.14" transform="translate(45.635 16.618)" style={{ fill: '#f9e8f3' }} />
      <path data-name="Path 1273" d="M171.191 198.5a.535.535 0 0 0-.535.535v4.815h-4.815a.535.535 0 1 0 0 1.07h5.35a.535.535 0 0 0 .535-.535v-5.35a.535.535 0 0 0-.535-.535z" transform="translate(-123.416 -185.629)" style={{ fill: '#c13584' }} />
      <g data-name="Group 1261">
        <path data-name="Path 1274" d="M248.334 158.861a.535.535 0 0 1-.535-.535v-.535a.535.535 0 1 1 1.07 0v.535a.535.535 0 0 1-.535.535z" transform="translate(-200.559 -147.058)" style={{ fill: '#d899cb' }} />
        <path data-name="Path 1275" d="M248.334 398.093a.535.535 0 0 1-.535-.535v-.535a.535.535 0 1 1 1.07 0v.535a.535.535 0 0 1-.535.535z" transform="translate(-200.559 -370.775)" style={{ fill: '#d899cb' }} />
        <path data-name="Path 1276" d="M364.36 282.066h-.535a.535.535 0 1 1 0-1.07h.535a.535.535 0 1 1 0 1.07z" transform="translate(-308.56 -262.773)" style={{ fill: '#d899cb' }} />
        <path data-name="Path 1277" d="M125.129 282.066h-.535a.535.535 0 1 1 0-1.07h.535a.535.535 0 1 1 0 1.07z" transform="translate(-84.844 -262.773)" style={{ fill: '#d899cb' }} />
      </g>
      <circle data-name="Ellipse 96" cx="1.07" cy="1.07" r="1.07" transform="translate(46.705 17.688)" style={{ fill: '#d899cb' }} />
      <path data-name="Path 1278" d="M52.466 2.146a5.679 5.679 0 0 0-8.405.495c-1.375 1.714.094 5.8.094 5.8l8.409-5.556z" transform="translate(-9.484 -.485)" style={{ fill: '#c13584' }} />
      <path data-name="Path 1279" d="M71.705 164.707a12.84 12.84 0 0 1-9.471-21.507 12.84 12.84 0 1 0 18.142 18.14 12.8 12.8 0 0 1-8.67 3.369z" transform="translate(-23.128 -133.912)" style={{ fill: '#c13584' }} />
      <path data-name="Path 1280" d="m42.982 25.627-6.034 3.953a1.6 1.6 0 0 1-2.458-1.037 5.72 5.72 0 0 1 .086-2.421 5.684 5.684 0 0 0-.621 6.138.541.541 0 0 0 .777.21l8.542-5.6a.528.528 0 0 0 .133-.777 5.574 5.574 0 0 0-.425-.466z" transform="translate(0 -23.966)" style={{ fill: '#c13584' }} />
      <path data-name="Path 1281" d="M236.961 17.016h-1.07a1.065 1.065 0 0 0-.521.135l-.3.935 1.9.452 1.07-.452a1.07 1.07 0 0 0-1.079-1.07z" transform="translate(-188.651 -15.913)" style={{ fill: '#dad8db' }} />
      <path data-name="Path 1282" d="M231.849 19.1a1.07 1.07 0 0 0 .521 2h1.07a1.07 1.07 0 0 0 1.07-1.07h-1.6a1.071 1.071 0 0 1-1.061-.93z" transform="translate(-185.13 -17.861)" style={{ fill: '#c8c5c9' }} />
    </g>
  </svg>
);

const FreePlanIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} viewBox="0 0 50 50">
    <defs>
      <clipPath id="ftt5zrc4sa">
        <path data-name="Rectangle 236" style={{ fill: 'none' }} d="M0 0h31.228v27.666H0z" />
      </clipPath>
    </defs>
    <rect data-name="Rectangle 224" width={50} height={50} rx={5} style={{ fill: '#f3fdd8' }} />
    <g data-name="Group 1260">
      <g data-name="Group 1259" style={{ clipPath: 'url(#ftt5zrc4sa)' }} transform="translate(9.653 10.613)">
        <path data-name="Path 1256" d="m54.231 5.733 1.084-1.149-1.084-1.038S50.347-1.425 48.246.4c-1.271 1.107-1.561 3.523-1.584 5.328a18.908 18.908 0 0 0 .1 2.237l3.6 1.084 3.869-1.079 1.084-1.119-1.084-1.118zm-7.569 0z" transform="translate(-41.042)" style={{ fill: '#717fa7' }} />
        <path data-name="Path 1257" d="M149.472 5.733c-.023-1.806-.313-4.222-1.584-5.328-2.1-1.829-5.985 3.141-5.985 3.141l-1.084 1.038 1.081 1.149-1.084 1.119L141.9 7.97l3.874 1.084 3.6-1.084a18.908 18.908 0 0 0 .1-2.237m0 0z" transform="translate(-123.864)" style={{ fill: '#717fa7' }} />
        <path data-name="Path 1258" d="m44.151 104.431-13.564-1.154-13.564 1.154V116.644a1.864 1.864 0 0 0 1.864 1.864h9.275l2.425-1.154 2.425 1.154h9.275a1.864 1.864 0 0 0 1.864-1.864V114.407z" transform="translate(-14.972 -90.842)" style={{ fill: '#88a73a' }} />
        <path data-name="Path 1259" d="M109.537 29.447v4.424l2.425 1.084 2.425-1.084v-4.424z" transform="translate(-96.348 -25.901)" style={{ fill: '#4b546e' }} />
        <path data-name="Path 1260" d="M29.985 66.191H18.039l-2.425 1.084-2.425-1.084H1.243A1.243 1.243 0 0 0 0 67.434v3.133a1.243 1.243 0 0 0 1.243 1.243h28.742a1.243 1.243 0 0 0 1.243-1.243v-3.133a1.243 1.243 0 0 0-1.243-1.243" transform="translate(0 -58.221)" style={{ fill: '#9bc13c' }} />
        <path data-name="Path 1261" d="M109.537 66.191v5.619l2.425 1.084 2.425-1.084v-5.619z" transform="translate(-96.348 -58.221)" style={{ fill: '#717fa7' }} />
        <path data-name="Path 1262" d="M114.387 112.858h-4.85v14.077h4.85V124.7z" transform="translate(-96.348 -99.269)" style={{ fill: '#4b546e' }} />
      </g>
    </g>
  </svg>
);

const PremiumPlanIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={50} height={50} viewBox="0 0 50 50">
    <defs>
      <linearGradient id="2u17v07mdb" x1=".463" y1=".914" x2=".463" y2="-.169" gradientUnits="objectBoundingBox">
        <stop offset={0} stopColor="#ffcf95" />
        <stop offset=".427" stopColor="#ffc954" />
        <stop offset={1} stopColor="#ffc200" />
      </linearGradient>
      <linearGradient id="ff26vhwoca" x1="1.217" y1="1.457" x2="-.633" y2="1.457" gradientUnits="objectBoundingBox">
        <stop offset={0} stopColor="#ffc200" stopOpacity={0} />
        <stop offset=".203" stopColor="#fb0" stopOpacity=".204" />
        <stop offset=".499" stopColor="#ffa700" stopOpacity=".498" />
        <stop offset=".852" stopColor="#f80" stopOpacity=".851" />
        <stop offset={1} stopColor="#ff7800" />
      </linearGradient>
      <linearGradient id="nqiwb1rmjc" x1=".518" y1="-.407" x2=".353" y2="1.223" xlinkHref="#ff26vhwoca" />
      <linearGradient id="1rohmcyspd" x1="-.462" y1="-1.03" x2=".591" y2="-.306" xlinkHref="#ff26vhwoca" />
      <linearGradient id="oepa14y1je" x1="-.151" y1="-.116" x2=".585" y2="-.571" xlinkHref="#ff26vhwoca" />
      <linearGradient id="joqdlwdq1f" x1=".61" y1="2.287" x2=".415" y2="1.017" xlinkHref="#ff26vhwoca" />
      <linearGradient id="qw948qwi3g" x1="1.34" y1="1.58" x2="-.083" y2=".368" xlinkHref="#ff26vhwoca" />
    </defs>
    <rect data-name="Rectangle 224" width={50} height={50} rx={5} style={{ fill: '#fff7d6' }} />
    <path data-name="Path 866" d="m19.611 12.336 4.832 9.791 10.8 1.57a1.5 1.5 0 0 1 .831 2.557l-7.814 7.621 1.846 10.761a1.5 1.5 0 0 1-2.175 1.58l-9.664-5.081L8.6 46.217a1.5 1.5 0 0 1-2.175-1.58l1.848-10.762-7.819-7.621a1.5 1.5 0 0 1 .831-2.554l10.8-1.57 4.832-9.791a1.5 1.5 0 0 1 2.694-.003z" transform="translate(7 -4.501)" style={{ fill: 'url(#2u17v07mdb)' }} />
    <path data-name="Path 867" d="m262.177 22.128-4.832-9.791A1.481 1.481 0 0 0 256 11.5v18.538z" transform="translate(-230.734 -4.501)" style={{ fill: 'url(#ff26vhwoca)' }} />
    <path data-name="Path 868" d="m265.994 206.157 7.819-7.621a1.48 1.48 0 0 0 .385-1.516l-18.2 5.3z" transform="translate(-230.734 -176.783)" style={{ fill: 'url(#nqiwb1rmjc)' }} />
    <path data-name="Path 869" d="M267.247 287.351 256 271.289v11.1l9.664 5.081a1.481 1.481 0 0 0 1.583-.119z" transform="translate(-230.734 -245.752)" style={{ fill: 'url(#1rohmcyspd)' }} />
    <path data-name="Path 870" d="m101.619 271.289-9.994 3.838-1.845 10.761a1.481 1.481 0 0 0 .592 1.463z" transform="translate(-76.353 -245.752)" style={{ fill: 'url(#oepa14y1je)' }} />
    <path data-name="Path 871" d="m.968 163.04 18.2 5.3-6.176-7.91L2.184 162a1.482 1.482 0 0 0-1.216 1.04z" transform="translate(6.101 -142.802)" style={{ fill: 'url(#joqdlwdq1f)' }} />
    <path data-name="Path 872" d="M8.278 206.158.46 198.537a1.48 1.48 0 0 1-.385-1.516l18.2 5.3z" transform="translate(6.995 -176.784)" style={{ fill: 'url(#qw948qwi3g)' }} />
  </svg>
);

const LimitedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={100} height="71.583" viewBox="0 0 100 71.583">
    <defs>
      <clipPath id="wrchqoarza">
        <path data-name="Rectangle 231" style={{ fill: 'none' }} d="M0 0h100v71.583H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 1236" style={{ clipPath: 'url(#wrchqoarza)' }}>
      <path data-name="Path 1230" d="M165.909 59.981a1.933 1.933 0 0 0 .038.267 1.651 1.651 0 0 0 .449.848c4.778-5.265 6.267-12.814.946-17.178-2.7-2.215-6.558-2.815-9.929-3.039-.269-.018-.538-.028-.807-.039q.551.541 1.072 1.107a17.926 17.926 0 0 1 8.2 2.343c5.785 3.546 3.892 11.429.037 15.691" transform="translate(-70.71 -18.44)" style={{ fill: '#0185de' }} />
      <path data-name="Path 1231" d="M160.225 53.084a.331.331 0 0 0 .006.033 1.451 1.451 0 0 0 .64.987.627.627 0 0 0 .141-.135c3.648-4.943 4.889-13.544-.446-17.838-3.656-2.942-9-3.354-13.888-3.046q.866.522 1.681 1.093c3.772-.072 7.6.432 10.726 2.323 5.485 3.323 4.43 11.909 1.14 16.582" transform="translate(-66.228 -14.9)" style={{ fill: '#0185de' }} />
      <path data-name="Path 1232" d="M5.166 63.007a1.9 1.9 0 0 1-.027.268 1.656 1.656 0 0 1-.414.866c-4.988-5.067-6.781-12.55-1.641-17.126 2.61-2.322 6.438-3.078 9.8-3.438.268-.029.536-.05.8-.072q-.529.563-1.025 1.15a17.919 17.919 0 0 0-8.1 2.673c-5.637 3.777-3.426 11.577.6 15.68" transform="translate(0 -19.643)" style={{ fill: '#0185de' }} />
      <path data-name="Path 1233" d="M7.822 55.947a.242.242 0 0 1 0 .034 1.451 1.451 0 0 1-.6 1.011.6.6 0 0 1-.146-.129C3.227 52.072 1.639 43.528 6.8 39.022c3.534-3.088 8.859-3.715 13.751-3.606q-.842.557-1.635 1.16c-3.772.08-7.579.739-10.623 2.756-5.345 3.542-3.944 12.079-.467 16.615" transform="translate(-1.591 -15.986)" style={{ fill: '#0185de' }} />
      <path data-name="Path 1234" d="M8.218 63.8a40.161 40.161 0 0 0 .682 8.674 4.619 4.619 0 0 0 2.747 3.465l.677-.06-.683-11.272.671-8.087S8.32 59.669 8.218 63.8" transform="translate(-3.701 -25.52)" style={{ fill: '#fff' }} />
      <path data-name="Path 1235" d="M8.218 63.8a40.161 40.161 0 0 0 .682 8.674 4.619 4.619 0 0 0 2.747 3.465l.677-.06-.683-11.272.671-8.087S8.32 59.669 8.218 63.8z" transform="translate(-3.701 -25.52)" style={{ stroke: '#5795d0', strokeWidth: '.83px', fill: 'none' }} />
      <path data-name="Path 1236" d="M8.218 63.8a40.161 40.161 0 0 0 .682 8.674 4.619 4.619 0 0 0 2.747 3.465l.677-.06-.683-11.272.671-8.087S8.32 59.669 8.218 63.8" transform="translate(-3.701 -25.52)" style={{ fill: '#fff' }} />
      <path data-name="Path 1237" d="M8.218 63.8a40.161 40.161 0 0 0 .682 8.674 4.619 4.619 0 0 0 2.747 3.465l.677-.06-.683-11.272.671-8.087S8.32 59.669 8.218 63.8z" transform="translate(-3.701 -25.52)" style={{ stroke: '#5795d0', strokeWidth: '.83px', fill: 'none' }} />
      <path data-name="Path 1238" d="M8.218 63.8a40.161 40.161 0 0 0 .682 8.674 4.619 4.619 0 0 0 2.747 3.465l.677-.06-.683-11.272.671-8.087S8.32 59.669 8.218 63.8" transform="translate(-3.701 -25.52)" style={{ fill: '#33b6ff' }} />
      <path data-name="Path 1239" d="M8.218 63.8a40.161 40.161 0 0 0 .682 8.674 4.619 4.619 0 0 0 2.747 3.465l.677-.06-.683-11.272.671-8.087S8.32 59.669 8.218 63.8z" transform="translate(-3.701 -25.52)" style={{ strokeWidth: '1.66px', stroke: '#0185de', fill: 'none' }} />
      <path data-name="Path 1240" d="M171.46 60.559a40.161 40.161 0 0 1-.327 8.7 4.62 4.62 0 0 1-2.6 3.573l-.68-.032.225-11.29-1-8.053s4.114 2.984 4.382 7.1" transform="translate(-75.439 -24.136)" style={{ fill: '#fff' }} />
      <path data-name="Path 1241" d="M171.46 60.559a40.161 40.161 0 0 1-.327 8.7 4.62 4.62 0 0 1-2.6 3.573l-.68-.032.225-11.29-1-8.053s4.114 2.982 4.382 7.102z" transform="translate(-75.439 -24.136)" style={{ stroke: '#5795d0', strokeWidth: '.83px', fill: 'none' }} />
      <path data-name="Path 1242" d="M171.46 60.559a40.161 40.161 0 0 1-.327 8.7 4.62 4.62 0 0 1-2.6 3.573l-.68-.032.225-11.29-1-8.053s4.114 2.984 4.382 7.1" transform="translate(-75.439 -24.136)" style={{ fill: '#fff' }} />
      <path data-name="Path 1243" d="M171.46 60.559a40.161 40.161 0 0 1-.327 8.7 4.62 4.62 0 0 1-2.6 3.573l-.68-.032.225-11.29-1-8.053s4.114 2.982 4.382 7.102z" transform="translate(-75.439 -24.136)" style={{ stroke: '#5795d0', strokeWidth: '.83px', fill: 'none' }} />
      <path data-name="Path 1244" d="M171.46 60.559a40.161 40.161 0 0 1-.327 8.7 4.62 4.62 0 0 1-2.6 3.573l-.68-.032.225-11.29-1-8.053s4.114 2.984 4.382 7.1" transform="translate(-75.439 -24.136)" style={{ fill: '#33b6ff' }} />
      <path data-name="Path 1245" d="M171.46 60.559a40.161 40.161 0 0 1-.327 8.7 4.62 4.62 0 0 1-2.6 3.573l-.68-.032.225-11.29-1-8.053s4.114 2.982 4.382 7.102z" transform="translate(-75.439 -24.136)" style={{ strokeWidth: '1.66px', stroke: '#0185de', fill: 'none' }} />
      <path data-name="Path 1246" d="M99.284 41.252c.381 18.817-18.6 29.714-42.274 30.194s-43.083-9.641-43.464-28.458S22.947 1.9 54.368 1.259C87.25.593 98.9 22.435 99.284 41.252" transform="translate(-6.112 -.562)" style={{ fill: '#33b6ff' }} />
      <path data-name="Path 1247" d="M99.284 41.252c.381 18.817-18.6 29.714-42.274 30.194s-43.083-9.641-43.464-28.458S22.947 1.9 54.368 1.259C87.25.593 98.9 22.435 99.284 41.252z" transform="translate(-6.112 -.562)" style={{ strokeWidth: '2.489px', stroke: '#0185de', fill: 'none' }} />
      <path data-name="Path 1248" d="M98.419 46.84C98.76 63.682 82.048 73.429 61.2 73.851S23.253 65.21 22.911 48.368s8.268-36.776 35.94-37.336C87.809 10.446 98.077 30 98.419 46.84" transform="translate(-10.34 -4.976)" style={{ fill: '#fae28d' }} />
      <path data-name="Path 1249" d="M125.7 30.286c-.768 1.451-4.03 1.232-7.285-.488s-5.274-4.291-4.506-5.742 4.03-1.232 7.286.488 5.273 4.291 4.505 5.742" transform="translate(-51.357 -10.428)" style={{ fill: '#fff' }} />
      <path data-name="Path 1250" d="M141.3 41.824a1.389 1.389 0 1 1-.647-1.726 1.31 1.31 0 0 1 .647 1.726" transform="translate(-62.639 -18.025)" style={{ fill: '#fff' }} />
      <path data-name="Path 1251" d="M22.381 28.079s3.154-14.946 24.27-21.528c0 0-18.237 6.993-24.27 21.528" transform="translate(-10.105 -2.958)" style={{ fill: '#52caff' }} />
      <path data-name="Path 1252" d="m73.573 52.78 1.753-1.753a1.155 1.155 0 0 0-1.593-1.594l-1.753 1.753-1.753-1.753a1.155 1.155 0 0 0-1.593 1.594l1.753 1.753-1.753 1.753a1.155 1.155 0 0 0 1.593 1.594l1.753-1.753 1.753 1.753a1.155 1.155 0 0 0 1.593-1.594z" transform="translate(-30.888 -22.219)" style={{ fill: '#304f60' }} />
      <path data-name="Path 1253" d="m106.447 52.78 1.753-1.753a1.155 1.155 0 0 0-1.593-1.594l-1.753 1.753-1.754-1.753a1.155 1.155 0 0 0-1.593 1.594l1.753 1.753-1.753 1.753a1.155 1.155 0 0 0 1.593 1.594l1.753-1.753 1.753 1.753a1.155 1.155 0 0 0 1.593-1.594z" transform="translate(-45.731 -22.219)" style={{ fill: '#304f60' }} />
      <path data-name="Path 1254" d="M92.351 81.215c0 3.968-2.522 7.186-5.635 7.186s-5.635-3.217-5.635-7.186 2.522-7.186 5.635-7.186 5.635 3.217 5.635 7.186" transform="translate(-36.61 -33.426)" style={{ fill: '#304f60' }} />
      <path data-name="Path 1255" d="M87.83 91.03a5.227 5.227 0 0 0-4.283 2.524 4.9 4.9 0 0 0 8.565 0 5.227 5.227 0 0 0-4.282-2.524" transform="translate(-37.723 -41.102)" style={{ fill: '#ff5fa0' }} />
    </g>
  </svg>
);

const DiamondIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} viewBox="0 0 40 40">
    <rect data-name="Rectangle 224" width={40} height={40} rx={5} style={{ fill: '#e0f8ff' }} />
    <path data-name="Path 873" d="M86.029 6.724 83.9 1.827a.293.293 0 0 1 .269-.41h1.262a.293.293 0 0 1 .143.037l4.011 2.239a.06.06 0 0 0 .075-.013L92.843.1a.289.289 0 0 1 .432 0l3.179 3.583a.061.061 0 0 0 .075.013l4.011-2.239a.293.293 0 0 1 .143-.037h1.262a.293.293 0 0 1 .269.41l-2.125 4.9v.724H86.121z" transform="translate(-73.059 6.013)" style={{ fill: '#fee97d' }} />
    <g data-name="Group 1137">
      <path data-name="Path 874" d="m88.033 27.341-.7-1.608-1.756-.98a.293.293 0 0 0-.143-.037h-1.26a.293.293 0 0 0-.269.41l2.125 4.9.091.724h13.968v-.724l.426-.981-10.835-.561a1.9 1.9 0 0 1-1.647-1.143z" transform="translate(-73.06 -17.285)" style={{ fill: '#fedf30' }} />
    </g>
    <path data-name="Path 875" d="M13.819 175.453.575 161.951a.72.72 0 0 1 .047-1.051l4.758-4.05a.72.72 0 0 1 .467-.172H22.82a.72.72 0 0 1 .467.172l4.758 4.05a.72.72 0 0 1 .047 1.053l-13.245 13.5a.72.72 0 0 1-1.028 0z" transform="translate(5.666 -141.683)" style={{ fill: '#99e6fc' }} />
    <path data-name="Path 876" d="M4.463 161.951a.72.72 0 0 1 .047-1.051l4.758-4.05a.72.72 0 0 1 .467-.172H5.846a.72.72 0 0 0-.467.172L.621 160.9a.72.72 0 0 0-.047 1.053l13.245 13.5a.72.72 0 0 0 1.028 0l1.43-1.453z" transform="translate(5.667 -141.683)" style={{ fill: '#62dbfb' }} />
    <g data-name="Group 1138">
      <path data-name="Path 877" d="m94.874 156.673-3.533 4.727-3.53-4.551a.724.724 0 0 1 .467-.172h6.6z" transform="translate(-76.765 -141.68)" style={{ fill: '#01d0fb' }} />
      <path data-name="Path 878" d="m92.084 156.679.084-.006h-3.889a.724.724 0 0 0-.467.172l1.547 1.994 2.343-1.994a.72.72 0 0 1 .382-.166z" transform="translate(-76.766 -141.68)" style={{ fill: '#01c0fa' }} />
      <path data-name="Path 879" d="m284.1 156.845-3.53 4.551-3.533-4.723h6.6a.724.724 0 0 1 .463.172z" transform="translate(-255.151 -141.68)" style={{ fill: '#01d0fb' }} />
      <path data-name="Path 880" d="m347.23 164.216-.114.009h-8.425l3.53-4.551 4.758 4.05a.719.719 0 0 1 .251.492z" transform="translate(-313.268 -144.508)" style={{ fill: '#01c0fa' }} />
      <path data-name="Path 881" d="m160.258 239.093-5.424 14.155-5.424-14.155z" transform="translate(-134.835 -219.377)" style={{ fill: '#01c0fa' }} />
      <path data-name="Path 882" d="m208.4 387.95-3.2-3.265 2.227 5.812z" transform="translate(-187.42 -356.625)" style={{ fill: '#08a9f1' }} />
      <path data-name="Path 883" d="m5.41 159.673 3.53 4.551H.4a.719.719 0 0 1 .252-.5z" transform="translate(5.636 -144.508)" style={{ fill: '#01c0fa' }} />
      <path data-name="Path 884" d="M257.985 238.943a.719.719 0 0 1-.2.56l-13.245 13.5a.717.717 0 0 1-.516.216v-.114l5.424-14.156h8.425z" transform="translate(-224.023 -219.235)" style={{ fill: '#01d0fb' }} />
      <path data-name="Path 885" d="m245.967 442.646-.969-.987-.976 2.547v.114a.717.717 0 0 0 .516-.215z" transform="translate(-224.023 -410.334)" style={{ fill: '#01c0fa' }} />
      <path data-name="Path 886" d="m156.725 156.673 3.533 4.723h-10.847l3.533-4.723z" transform="translate(-134.835 -141.68)" style={{ fill: '#08a9f1' }} />
    </g>
    <g data-name="Group 1143">
      <path data-name="Path 887" d="M123.408 119.58h-14.059a.732.732 0 0 1-.732-.732v-.791a.732.732 0 0 1 .732-.732h14.059a.732.732 0 0 1 .732.732v.791a.732.732 0 0 1-.732.732z" transform="translate(-96.379 -104.588)" style={{ fill: '#fee45a' }} />
      <path data-name="Path 888" d="M111.781 118.847v-.791a.732.732 0 0 1 .732-.732h-3.164a.732.732 0 0 0-.732.732v.791a.732.732 0 0 0 .732.732h3.164a.732.732 0 0 1-.732-.732z" transform="translate(-96.379 -104.587)" style={{ fill: '#fed402' }} />
      <path data-name="Path 889" d="M227.421 60.537h-.571a.743.743 0 0 1-.743-.743v-.17a.743.743 0 0 1 .743-.743h.571a.743.743 0 0 1 .743.743v.17a.743.743 0 0 1-.743.743z" transform="translate(-207.136 -49.493)" style={{ fill: '#f28cc4' }} />
      <g data-name="Group 1139">
        <path data-name="Path 890" d="M287.832 84h-.214a.43.43 0 1 1 0-.86h.214a.43.43 0 0 1 0 .86z" transform="translate(-264.717 -72.366)" style={{ fill: '#f28cc4' }} />
      </g>
      <g data-name="Group 1140">
        <path data-name="Path 891" d="M334.042 84h-.214a.43.43 0 0 1 0-.86h.214a.43.43 0 0 1 0 .86z" transform="translate(-308.278 -72.366)" style={{ fill: '#f28cc4' }} />
      </g>
      <g data-name="Group 1141">
        <path data-name="Path 892" d="M182.824 84h-.214a.43.43 0 1 1 0-.86h.214a.43.43 0 0 1 0 .86z" transform="translate(-165.726 -72.366)" style={{ fill: '#f28cc4' }} />
      </g>
      <g data-name="Group 1142">
        <path data-name="Path 893" d="M136.614 84h-.214a.43.43 0 1 1 0-.86h.214a.43.43 0 0 1 0 .86z" transform="translate(-122.164 -72.366)" style={{ fill: '#f28cc4' }} />
      </g>
    </g>
    <path data-name="Path 894" d="m4.541 163.726 2.415-2.056-1.546-1.994-4.757 4.05a.719.719 0 0 0-.252.5H4.29a.717.717 0 0 1 .251-.5z" transform="translate(5.636 -144.511)" style={{ fill: '#08a9f1' }} />
  </svg>
);

const BlueCheckIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
    <g data-name="Rectangle 83" style={{ fill: '#0185de', stroke: '#0185de' }}>
      <rect width={24} height={24} rx={12} style={{ stroke: 'none' }} />
      <rect x=".5" y=".5" width={23} height={23} rx="11.5" style={{ fill: 'none' }} />
    </g>
    <text data-name="" transform="translate(3 19.2)" style={{ fill: '#fff', fontSize: 16, fontFamily: 'tabler-icons', letterSpacing: '.002em' }}><tspan x={0} y={0}></tspan></text>
  </svg>
);


const RedWarningIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="#f35848" className="icon icon-tabler icons-tabler-filled icon-tabler-alert-triangle"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 1.67c.955 0 1.845 .467 2.39 1.247l.105 .16l8.114 13.548a2.914 2.914 0 0 1 -2.307 4.363l-.195 .008h-16.225a2.914 2.914 0 0 1 -2.582 -4.2l.099 -.185l8.11 -13.538a2.914 2.914 0 0 1 2.491 -1.403zm.01 13.33l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm-.01 -7a1 1 0 0 0 -.993 .883l-.007 .117v4l.007 .117a1 1 0 0 0 1.986 0l.007 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z" /></svg>
);

const SparklesIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-sparkles"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M16 18a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm0 -12a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm-7 12a6 6 0 0 1 6 -6a6 6 0 0 1 -6 -6a6 6 0 0 1 -6 6a6 6 0 0 1 6 6z" /></svg>
)

const BulbIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-bulb"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 12h1m8 -9v1m8 8h1m-15.4 -6.4l.7 .7m12.1 -.7l-.7 .7" /><path d="M9 16a5 5 0 1 1 6 0a3.5 3.5 0 0 0 -1 3a2 2 0 0 1 -4 0a3.5 3.5 0 0 0 -1 -3" /><path d="M9.7 17l4.6 0" /></svg>
)

const MoodHappyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-mood-happy"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /><path d="M9 9l.01 0" /><path d="M15 9l.01 0" /><path d="M8 13a4 4 0 1 0 8 0h-8" /></svg>
)

const MoodCrazyHappyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-mood-crazy-happy"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /><path d="M7 8.5l3 3" /><path d="M7 11.5l3 -3" /><path d="M14 8.5l3 3" /><path d="M14 11.5l3 -3" /><path d="M9.5 15a3.5 3.5 0 0 0 5 0" /></svg>
)

const MoodSmileBeamIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-mood-smile-beam"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 21a9 9 0 1 1 0 -18a9 9 0 0 1 0 18z" /><path d="M10 10c-.5 -1 -2.5 -1 -3 0" /><path d="M17 10c-.5 -1 -2.5 -1 -3 0" /><path d="M14.5 15a3.5 3.5 0 0 1 -5 0" /></svg>
)

const MoodNerdIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-mood-nerd"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /><path d="M8 10m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M16 10m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M9.5 15a3.5 3.5 0 0 0 5 0" /><path d="M3.5 9h2.5" /><path d="M18 9h2.5" /><path d="M10 9.5c1.333 -1.333 2.667 -1.333 4 0" /></svg>
)

const MoodTongueWink = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-mood-tongue-wink-2"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /><path d="M12 21a9 9 0 1 1 0 -18a9 9 0 0 1 0 18z" /><path d="M15 10h-.01" /><path d="M10 14v2a2 2 0 1 0 4 0v-2m1.5 0h-7" /><path d="M7 10c.5 -1 2.5 -1 3 0" /></svg>
)

const BookmarkIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-bookmark"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 7v14l-6 -4l-6 4v-14a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4z" /></svg>
)

const BookmarksIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-bookmarks"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M15 10v11l-5 -3l-5 3v-11a3 3 0 0 1 3 -3h4a3 3 0 0 1 3 3z" /><path d="M11 3h5a3 3 0 0 1 3 3v11" /></svg>
)

const ArrowNarrowRight = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-narrow-right"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 12l14 0" /><path d="M15 16l4 -4" /><path d="M15 8l4 4" /></svg>
)

const AiIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24.174" height="24.174" viewBox="0 0 24.174 24.174">
    <g data-name="Group 1261">
      <path data-name="Path 1266" d="M15.286 23.151a2.615 2.615 0 0 1-1.084-.234l-1.941-.883a1.657 1.657 0 0 0-1.369 0l-1.941.882a2.62 2.62 0 0 1-3.537-1.464l-.749-2a1.652 1.652 0 0 0-.968-.968l-2-.749A2.62 2.62 0 0 1 .236 14.2l.883-1.941a1.661 1.661 0 0 0 0-1.369L.235 8.95A2.62 2.62 0 0 1 1.7 5.413l2-.749a1.652 1.652 0 0 0 .964-.964l.749-2A2.62 2.62 0 0 1 8.95.235l1.941.883a1.657 1.657 0 0 0 1.369 0L14.2.236A2.62 2.62 0 0 1 17.739 1.7l.749 2a1.652 1.652 0 0 0 .968.968l2 .749a2.62 2.62 0 0 1 1.464 3.537l-.883 1.941a1.661 1.661 0 0 0 0 1.369l.882 1.941a2.62 2.62 0 0 1-1.464 3.537l-2 .749a1.652 1.652 0 0 0-.968.968l-.749 2a2.618 2.618 0 0 1-2.451 1.7zm-3.71-2.229a2.621 2.621 0 0 1 1.083.234l1.942.883a1.656 1.656 0 0 0 2.234-.925l.749-2a2.616 2.616 0 0 1 1.532-1.532l2-.749a1.656 1.656 0 0 0 .925-2.234l-.883-1.941a2.623 2.623 0 0 1 0-2.168l.883-1.942a1.656 1.656 0 0 0-.925-2.234l-2-.749a2.616 2.616 0 0 1-1.532-1.532l-.749-2a1.656 1.656 0 0 0-2.235-.918L12.661 2a2.628 2.628 0 0 1-2.167 0l-1.943-.887a1.656 1.656 0 0 0-2.234.925l-.749 2a2.62 2.62 0 0 1-1.532 1.53l-2 .749a1.657 1.657 0 0 0-.926 2.234L2 10.492a2.623 2.623 0 0 1 0 2.168l-.887 1.94a1.656 1.656 0 0 0 .925 2.234l2 .749a2.616 2.616 0 0 1 1.532 1.532l.749 2a1.656 1.656 0 0 0 2.234.925l1.941-.883a2.65 2.65 0 0 1 1.085-.233zm.885-19.365h.01z" transform="translate(.511 .511)" style={{ fill: '#fff', stroke: '#fff' }} />
    </g>
    <g data-name="Group 1262">
      <path data-name="Path 1267" d="M12.582 16a.583.583 0 0 1-.582-.585v-4.082a1.166 1.166 0 0 0-2.333 0v4.082a.583.583 0 0 1-1.166 0v-4.082a2.333 2.333 0 0 1 4.665 0v4.082a.583.583 0 0 1-.584.585z" transform="translate(-.495 -.411)" style={{ fill: '#fff', stroke: '#fff' }} />
    </g>
    <g data-name="Group 1263">
      <path data-name="Path 1268" d="M12.582 13.166h-3.5a.583.583 0 0 1 0-1.166h3.5a.583.583 0 0 1 0 1.166z" transform="translate(-.495 .087)" style={{ fill: '#fff', stroke: '#fff' }} />
    </g>
    <g data-name="Group 1264">
      <path data-name="Path 1269" d="M15.083 16a.583.583 0 0 1-.583-.583V9.583a.583.583 0 0 1 1.166 0v5.831a.583.583 0 0 1-.583.586z" transform="translate(.503 -.411)" style={{ fill: '#fff', stroke: '#fff' }} />
    </g>
  </svg>
)
const XCloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-xbox-x"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z" /><path d="M9 8l6 8" /><path d="M15 8l-6 8" /></svg>
);

const PencilIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-pencil"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4" /><path d="M13.5 6.5l4 4" /></svg>
)

const ShapeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-shape"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M19 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M5 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M19 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M5 7l0 10" /><path d="M7 5l10 0" /><path d="M7 19l10 0" /><path d="M19 7l0 10" /></svg>
)

const ExternalLink = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6" />
    <path d="M11 13l9 -9" />
    <path d="M15 4h5v5" />
  </svg>)

const FolderOpenIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-folder-open"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 19l2.757 -7.351a1 1 0 0 1 .936 -.649h12.307a1 1 0 0 1 .986 1.164l-.996 5.211a2 2 0 0 1 -1.964 1.625h-14.026a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2h4l3 3h7a2 2 0 0 1 2 2v2" /></svg>
)

const CloudIconUpload = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-cloud-upload"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1" /><path d="M9 15l3 -3l3 3" /><path d="M12 12l0 9" /></svg>
)

const TriangleSquareCircleIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-triangle-square-circle"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 3l-4 7h8z" /><path d="M17 17m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" /><path d="M4 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" /></svg>
)

const PackageIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-package"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 3l8 4.5l0 9l-8 4.5l-8 -4.5l0 -9l8 -4.5" /><path d="M12 12l8 -4.5" /><path d="M12 12l0 9" /><path d="M12 12l-8 -4.5" /><path d="M16 5.25l-8 4.5" /></svg>
);
const SettingAutomationIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-settings-automation"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" /><path d="M10 9v6l5 -3z" /></svg>
)
const ConfettiIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-confetti"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 5h2" /><path d="M5 4v2" /><path d="M11.5 4l-.5 2" /><path d="M18 5h2" /><path d="M19 4v2" /><path d="M15 9l-1 1" /><path d="M18 13l2 -.5" /><path d="M18 19h2" /><path d="M19 18v2" /><path d="M14 16.518l-6.518 -6.518l-4.39 9.58a1 1 0 0 0 1.329 1.329l9.579 -4.39z" /></svg>
)
const InfoTriangleFillIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 1.67c.955 0 1.845 .467 2.39 1.247l.105 .16l8.114 13.548a2.914 2.914 0 0 1 -2.307 4.363l-.195 .008h-16.225a2.914 2.914 0 0 1 -2.582 -4.2l.099 -.185l8.11 -13.538a2.914 2.914 0 0 1 2.491 -1.403zm0 9.33h-1l-.117 .007a1 1 0 0 0 0 1.986l.117 .007v3l.007 .117a1 1 0 0 0 .876 .876l.117 .007h1l.117 -.007a1 1 0 0 0 .876 -.876l.007 -.117l-.007 -.117a1 1 0 0 0 -.764 -.857l-.112 -.02l-.117 -.006v-3l-.007 -.117a1 1 0 0 0 -.876 -.876l-.117 -.007zm.01 -3l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007z" /></svg>
)

const PhotoVideoIcon = () => (
  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-photo-video"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 15h-3a3 3 0 0 1 -3 -3v-6a3 3 0 0 1 3 -3h6a3 3 0 0 1 3 3v3" /><path d="M9 9m0 3a3 3 0 0 1 3 -3h6a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-6a3 3 0 0 1 -3 -3z" /><path d="M3 12l2.296 -2.296a2.41 2.41 0 0 1 3.408 0l.296 .296" /><path d="M14 13.5v3l2.5 -1.5z" /><path d="M7 6v.01" /></svg>
)
const CalendarPlusIcon = () => (
  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-calendar-plus"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12.5 21h-6.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v5" /><path d="M16 3v4" /><path d="M8 3v4" /><path d="M4 11h16" /><path d="M16 19h6" /><path d="M19 16v6" /></svg>
)
const CirclePercentageIcon = () => (
  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-circle-percentage"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" /><path d="M9 15.075l6 -6" /><path d="M9 9.105v.015" /><path d="M15 15.12v.015" /></svg>
)
const XIcon = () => (
  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-x"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
)

const NightRefreshIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" color="#4b546e" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-refresh"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" /><path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" /></svg>
)

const NightEditIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" color="#4b546e" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-pencil"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4" /><path d="M13.5 6.5l4 4" /></svg>
)

const DotsIcon = () => (
  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-dots"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /><path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /><path d="M19 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" /></svg>
)
const CaretUpFilledIcon = () => (
  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="currentColor"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M11.293 7.293a1 1 0 0 1 1.32 -.083l.094 .083l6 6l.083 .094l.054 .077l.054 .096l.017 .036l.027 .067l.032 .108l.01 .053l.01 .06l.004 .057l.002 .059l-.002 .059l-.005 .058l-.009 .06l-.01 .052l-.032 .108l-.027 .067l-.07 .132l-.065 .09l-.073 .081l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002h-12c-.852 0 -1.297 -.986 -.783 -1.623l.076 -.084l6 -6z" /></svg>
)
const CaretDownFilledIcon = () => (
  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="currentColor"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 9c.852 0 1.297 .986 .783 1.623l-.076 .084l-6 6a1 1 0 0 1 -1.32 .083l-.094 -.083l-6 -6l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057v-.118l.005 -.058l.009 -.06l.01 -.052l.032 -.108l.027 -.067l.07 -.132l.065 -.09l.073 -.081l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01l.057 -.004l12.059 -.002z" /></svg>
)

const PptIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={100} height={120} viewBox="0 0 100 120">
  <defs>
    <clipPath id="ont7ul89za">
      <path data-name="Rectangle 327" style={{fill: '#ee9a9a'}} d="M0 0h21.573v21.354H0z" />
    </clipPath>
    <clipPath id="ybwbtbguub">
      <path data-name="Rectangle 330" style={{fill: '#d32f2e'}} d="M0 0h49.371v3H0z" />
    </clipPath>
  </defs>
  <path data-name="Rectangle 325" style={{fill: '#fff'}} d="M0 0h100v120H0z" />
  <g data-name="Group 1422">
    <g data-name="Group 1441">
      <path data-name="Path 1292" d="M115.524 21.877V91.1a3.137 3.137 0 0 1-3.1 3.151H47.538a3.137 3.137 0 0 1-3.1-3.151V3.26a3.137 3.137 0 0 1 3.1-3.151h46.323z" transform="translate(-29.981 11.823)" style={{fill: '#fdcdd2'}} />
    </g>
  </g>
  <g data-name="Group 1427">
    <g data-name="Group 1424">
      <g data-name="Group 1423" style={{clipPath: 'url(#ont7ul89za)'}} transform="translate(63.971 11.932)">
        <path data-name="Path 1293" d="M488.989 21.463h-18.482a3.1 3.1 0 0 1-3.091-3.091V.109" transform="translate(-467.416 -.109)" style={{fill: '#ee9a9a'}} />
      </g>
    </g>
  </g>
  <g data-name="Group 1429">
    <path data-name="Path 1294" d="M78.616 549.387H4.173A4.439 4.439 0 0 1 0 544.745V513.6a4.439 4.439 0 0 1 4.173-4.642h74.443a4.439 4.439 0 0 1 4.173 4.642v31.145a4.425 4.425 0 0 1-4.173 4.642" transform="translate(8.605 -441.483)" style={{fill: '#d32f2e'}} />
    <text data-name="ppt" transform="translate(50 96)" style={{fontSize: 26, fontFamily: 'Kanit-Medium,Kanit', fontWeight: 500, letterSpacing: '.034em', fill: '#fff'}}><tspan x="-23.764" y={0}>PPT</tspan></text>
  </g>
  <g data-name="Group 1440">
    <g data-name="Group 1432" style={{opacity: '.77'}}>
      <g data-name="Group 1431">
        <g data-name="Group 1430" style={{clipPath: 'url(#ybwbtbguub)'}} transform="translate(25.386 56)">
          <path data-name="Path 1295" d="M174.785 396.167h-45.854a1.519 1.519 0 1 1 0-3h45.854a1.519 1.519 0 1 1 0 3" transform="translate(-127.172 -393.167)" style={{fill: '#d32f2e'}} />
        </g>
      </g>
    </g>
    <g data-name="Group 1435" style={{opacity: '.77'}}>
      <g data-name="Group 1434">
        <g data-name="Group 1433" style={{clipPath: 'url(#ybwbtbguub)'}} transform="translate(25.386 48)">
          <path data-name="Path 1296" d="M174.785 318.151h-45.854a1.519 1.519 0 1 1 0-3h45.854a1.519 1.519 0 1 1 0 3" transform="translate(-127.172 -315.151)" style={{fill: '#d32f2e'}} />
        </g>
      </g>
    </g>
    <g data-name="Group 1438" style={{opacity: '.77'}}>
      <g data-name="Group 1437">
        <g data-name="Group 1436" style={{clipPath: 'url(#ybwbtbguub)'}} transform="translate(25.386 40)">
          <path data-name="Path 1297" d="M174.785 240.313h-45.854a1.519 1.519 0 1 1 0-3h45.854a1.519 1.519 0 1 1 0 3" transform="translate(-127.172 -237.313)" style={{fill: '#d32f2e'}} />
        </g>
      </g>
    </g>
  </g>
  </svg>
)

const PptxIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={100} height={120} viewBox="0 0 100 120">
    <defs>
      <clipPath id="q4xiq31ypa">
        <path data-name="Rectangle 327" style={{fill: '#ee9a9a'}} d="M0 0h21.573v21.354H0z" />
      </clipPath>
      <clipPath id="25cuvd17ib">
        <path data-name="Rectangle 330" style={{fill: '#d32f2e'}} d="M0 0h49.371v3H0z" />
      </clipPath>
    </defs>
    <path data-name="Rectangle 325" style={{fill: '#fff'}} d="M0 0h100v120H0z" />
    <g data-name="Group 1422">
      <g data-name="Group 1441">
        <path data-name="Path 1292" d="M115.524 21.877V91.1a3.137 3.137 0 0 1-3.1 3.151H47.538a3.137 3.137 0 0 1-3.1-3.151V3.26a3.137 3.137 0 0 1 3.1-3.151h46.323z" transform="translate(-29.981 11.823)" style={{fill: '#fdcdd2'}} />
      </g>
    </g>
    <g data-name="Group 1427">
      <g data-name="Group 1424">
        <g data-name="Group 1423" style={{clipPath: 'url(#q4xiq31ypa)'}} transform="translate(63.971 11.932)">
          <path data-name="Path 1293" d="M488.989 21.463h-18.482a3.1 3.1 0 0 1-3.091-3.091V.109" transform="translate(-467.416 -.109)" style={{fill: '#ee9a9a'}} />
        </g>
      </g>
    </g>
    <g data-name="Group 1429">
      <path data-name="Path 1294" d="M78.616 549.387H4.173A4.439 4.439 0 0 1 0 544.745V513.6a4.439 4.439 0 0 1 4.173-4.642h74.443a4.439 4.439 0 0 1 4.173 4.642v31.145a4.425 4.425 0 0 1-4.173 4.642" transform="translate(8.605 -441.483)" style={{fill: '#d32f2e'}} />
      <text data-name="pptx" transform="translate(50 96)" style={{fontSize: 26, fontFamily: 'Kanit-Medium,Kanit', fontWeight: 500, letterSpacing: '.034em', fill: '#fff'}}><tspan x="-33.007" y={0}>PPTX</tspan></text>
    </g>
    <g data-name="Group 1440">
      <g data-name="Group 1432" style={{opacity: '.77'}}>
        <g data-name="Group 1431">
          <g data-name="Group 1430" style={{clipPath: 'url(#25cuvd17ib)'}} transform="translate(25.386 56)">
            <path data-name="Path 1295" d="M174.785 396.167h-45.854a1.519 1.519 0 1 1 0-3h45.854a1.519 1.519 0 1 1 0 3" transform="translate(-127.172 -393.167)" style={{fill: '#d32f2e'}} />
          </g>
        </g>
      </g>
      <g data-name="Group 1435" style={{opacity: '.77'}}>
        <g data-name="Group 1434">
          <g data-name="Group 1433" style={{clipPath: 'url(#25cuvd17ib)'}} transform="translate(25.386 48)">
            <path data-name="Path 1296" d="M174.785 318.151h-45.854a1.519 1.519 0 1 1 0-3h45.854a1.519 1.519 0 1 1 0 3" transform="translate(-127.172 -315.151)" style={{fill: '#d32f2e'}} />
          </g>
        </g>
      </g>
      <g data-name="Group 1438" style={{opacity: '.77'}}>
        <g data-name="Group 1437">
          <g data-name="Group 1436" style={{clipPath: 'url(#25cuvd17ib)'}} transform="translate(25.386 40)">
            <path data-name="Path 1297" d="M174.785 240.313h-45.854a1.519 1.519 0 1 1 0-3h45.854a1.519 1.519 0 1 1 0 3" transform="translate(-127.172 -237.313)" style={{fill: '#d32f2e'}} />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

const DocIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={100} height={120} viewBox="0 0 100 120">
  <defs>
    <clipPath id="vrrom5pb8a">
      <path data-name="Rectangle 330" style={{fill: '#229be6'}} d="M0 0h49.371v3H0z" />
    </clipPath>
  </defs>
  <path data-name="Rectangle 325" style={{fill: '#fff'}} d="M0 0h100v120H0z" />
  <g data-name="Group 1422">
    <g data-name="Group 1441">
      <path data-name="Path 1292" d="M115.524 21.877V91.1a3.137 3.137 0 0 1-3.1 3.151H47.538a3.137 3.137 0 0 1-3.1-3.151V3.26a3.137 3.137 0 0 1 3.1-3.151h46.323z" transform="translate(-29.981 11.823)" style={{fill: '#b3e5fc'}} />
    </g>
  </g>
  <g data-name="Group 1427">
    <g data-name="Group 1424">
      <g data-name="Group 1442">
        <path data-name="Path 1293" d="M488.989 21.463h-18.482a3.1 3.1 0 0 1-3.091-3.091V.109" transform="translate(-403.445 11.823)" style={{fill: '#81d5fa'}} />
      </g>
    </g>
  </g>
  <g data-name="Group 1429">
    <path data-name="Path 1294" d="M78.616 549.387H4.173A4.439 4.439 0 0 1 0 544.745V513.6a4.439 4.439 0 0 1 4.173-4.642h74.443a4.439 4.439 0 0 1 4.173 4.642v31.145a4.425 4.425 0 0 1-4.173 4.642" transform="translate(8.605 -441.483)" style={{fill: '#229be6'}} />
    <text data-name="doc" transform="translate(50 96)" style={{fontSize: 26, fontFamily: 'Kanit-Medium,Kanit', fontWeight: 500, letterSpacing: '.034em', fill: '#fff'}}><tspan x="-26.312" y={0}>DOC</tspan></text>
  </g>
  <g data-name="Group 1440">
    <g data-name="Group 1432" style={{opacity: '.77'}}>
      <g data-name="Group 1431">
        <g data-name="Group 1430" style={{clipPath: 'url(#vrrom5pb8a)'}} transform="translate(25.386 56)">
          <path data-name="Path 1295" d="M174.785 396.167h-45.854a1.519 1.519 0 1 1 0-3h45.854a1.519 1.519 0 1 1 0 3" transform="translate(-127.172 -393.167)" style={{fill: '#229be6'}} />
        </g>
      </g>
    </g>
    <g data-name="Group 1435" style={{opacity: '.77'}}>
      <g data-name="Group 1434">
        <g data-name="Group 1433" style={{clipPath: 'url(#vrrom5pb8a)'}} transform="translate(25.386 48)">
          <path data-name="Path 1296" d="M174.785 318.151h-45.854a1.519 1.519 0 1 1 0-3h45.854a1.519 1.519 0 1 1 0 3" transform="translate(-127.172 -315.151)" style={{fill: '#229be6'}} />
        </g>
      </g>
    </g>
    <g data-name="Group 1438" style={{opacity: '.77'}}>
      <g data-name="Group 1437">
        <g data-name="Group 1436" style={{clipPath: 'url(#vrrom5pb8a)'}} transform="translate(25.386 40)">
          <path data-name="Path 1297" d="M174.785 240.313h-45.854a1.519 1.519 0 1 1 0-3h45.854a1.519 1.519 0 1 1 0 3" transform="translate(-127.172 -237.313)" style={{fill: '#229be6'}} />
        </g>
      </g>
    </g>
  </g>
  </svg>
)

const DocxIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={100} height={120} viewBox="0 0 100 120">
  <defs>
    <clipPath id="275lyoxrka">
      <path data-name="Rectangle 330" style={{fill: '#229be6'}} d="M0 0h49.371v3H0z" />
    </clipPath>
  </defs>
  <path data-name="Rectangle 325" style={{fill: '#fff'}} d="M0 0h100v120H0z" />
  <g data-name="Group 1422">
    <g data-name="Group 1441">
      <path data-name="Path 1292" d="M115.524 21.877V91.1a3.137 3.137 0 0 1-3.1 3.151H47.538a3.137 3.137 0 0 1-3.1-3.151V3.26a3.137 3.137 0 0 1 3.1-3.151h46.323z" transform="translate(-29.981 11.823)" style={{fill: '#b3e5fc'}} />
    </g>
  </g>
  <g data-name="Group 1427">
    <g data-name="Group 1424">
      <g data-name="Group 1442">
        <path data-name="Path 1293" d="M488.989 21.463h-18.482a3.1 3.1 0 0 1-3.091-3.091V.109" transform="translate(-403.445 11.823)" style={{fill: '#81d5fa'}} />
      </g>
    </g>
  </g>
  <g data-name="Group 1429">
    <path data-name="Path 1294" d="M78.616 549.387H4.173A4.439 4.439 0 0 1 0 544.745V513.6a4.439 4.439 0 0 1 4.173-4.642h74.443a4.439 4.439 0 0 1 4.173 4.642v31.145a4.425 4.425 0 0 1-4.173 4.642" transform="translate(8.605 -441.483)" style={{fill: '#229be6'}} />
    <text data-name="docx" transform="translate(50 96)" style={{fontSize: 26, fontFamily: 'Kanit-Medium,Kanit', fontWeight: 500, letterSpacing: '.034em', fill: '#fff'}}><tspan x="-35.685" y={0}>DOCX</tspan></text>
  </g>
  <g data-name="Group 1440">
    <g data-name="Group 1432" style={{opacity: '.77'}}>
      <g data-name="Group 1431">
        <g data-name="Group 1430" style={{clipPath: 'url(#275lyoxrka)'}} transform="translate(25.386 56)">
          <path data-name="Path 1295" d="M174.785 396.167h-45.854a1.519 1.519 0 1 1 0-3h45.854a1.519 1.519 0 1 1 0 3" transform="translate(-127.172 -393.167)" style={{fill: '#229be6'}} />
        </g>
      </g>
    </g>
    <g data-name="Group 1435" style={{opacity: '.77'}}>
      <g data-name="Group 1434">
        <g data-name="Group 1433" style={{clipPath: 'url(#275lyoxrka)'}} transform="translate(25.386 48)">
          <path data-name="Path 1296" d="M174.785 318.151h-45.854a1.519 1.519 0 1 1 0-3h45.854a1.519 1.519 0 1 1 0 3" transform="translate(-127.172 -315.151)" style={{fill: '#229be6'}} />
        </g>
      </g>
    </g>
    <g data-name="Group 1438" style={{opacity: '.77'}}>
      <g data-name="Group 1437">
        <g data-name="Group 1436" style={{clipPath: 'url(#275lyoxrka)'}} transform="translate(25.386 40)">
          <path data-name="Path 1297" d="M174.785 240.313h-45.854a1.519 1.519 0 1 1 0-3h45.854a1.519 1.519 0 1 1 0 3" transform="translate(-127.172 -237.313)" style={{fill: '#229be6'}} />
        </g>
      </g>
    </g>
  </g>
  </svg>
)

const PdfIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={100} height={120} viewBox="0 0 100 120">
  <defs>
    <clipPath id="69lnbih77a">
      <path data-name="Rectangle 330" style={{fill: '#cb0606'}} d="M0 0h49.371v3H0z" />
    </clipPath>
  </defs>
  <path data-name="Rectangle 325" style={{fill: '#fff'}} d="M0 0h100v120H0z" />
  <g data-name="Group 1422">
    <g data-name="Group 1441">
      <path data-name="Path 1292" d="M115.524 21.877V91.1a3.137 3.137 0 0 1-3.1 3.151H47.538a3.137 3.137 0 0 1-3.1-3.151V3.26a3.137 3.137 0 0 1 3.1-3.151h46.323z" transform="translate(-29.981 11.823)" style={{fill: '#fb8d8d'}} />
    </g>
  </g>
  <g data-name="Group 1427">
    <g data-name="Group 1424">
      <g data-name="Group 1442">
        <path data-name="Path 1293" d="M488.989 21.463h-18.482a3.1 3.1 0 0 1-3.091-3.091V.109" transform="translate(-403.445 11.823)" style={{fill: '#ffbfbf'}} />
      </g>
    </g>
  </g>
  <g data-name="Group 1429">
    <path data-name="Path 1294" d="M78.616 549.387H4.173A4.439 4.439 0 0 1 0 544.745V513.6a4.439 4.439 0 0 1 4.173-4.642h74.443a4.439 4.439 0 0 1 4.173 4.642v31.145a4.425 4.425 0 0 1-4.173 4.642" transform="translate(8.605 -441.483)" style={{fill: '#cb0606'}} />
    <text data-name="pdf" transform="translate(50 96)" style={{fontSize: 26, fontFamily: 'Kanit-Medium,Kanit', fontWeight: 500, letterSpacing: '.034em', fill: '#fff'}}><tspan x="-24.037" y={0}>PDF</tspan></text>
  </g>
  <g data-name="Group 1440">
    <g data-name="Group 1432" style={{opacity: '.77'}}>
      <g data-name="Group 1431">
        <g data-name="Group 1430" style={{clipPath: 'url(#69lnbih77a)'}} transform="translate(25.386 56)">
          <path data-name="Path 1295" d="M174.785 396.167h-45.854a1.519 1.519 0 1 1 0-3h45.854a1.519 1.519 0 1 1 0 3" transform="translate(-127.172 -393.167)" style={{fill: '#cb0606'}} />
        </g>
      </g>
    </g>
    <g data-name="Group 1435" style={{opacity: '.77'}}>
      <g data-name="Group 1434">
        <g data-name="Group 1433" style={{clipPath: 'url(#69lnbih77a)'}} transform="translate(25.386 48)">
          <path data-name="Path 1296" d="M174.785 318.151h-45.854a1.519 1.519 0 1 1 0-3h45.854a1.519 1.519 0 1 1 0 3" transform="translate(-127.172 -315.151)" style={{fill: '#cb0606'}} />
        </g>
      </g>
    </g>
    <g data-name="Group 1438" style={{opacity: '.77'}}>
      <g data-name="Group 1437">
        <g data-name="Group 1436" style={{clipPath: 'url(#69lnbih77a)'}} transform="translate(25.386 40)">
          <path data-name="Path 1297" d="M174.785 240.313h-45.854a1.519 1.519 0 1 1 0-3h45.854a1.519 1.519 0 1 1 0 3" transform="translate(-127.172 -237.313)" style={{fill: '#cb0606'}} />
        </g>
      </g>
    </g>
  </g>
  </svg>
)

const ReactionIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-mood-happy">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
    <path d="M8 13a4 4 0 1 0 8 0h-8" />
  </svg>
)
const GraphicReportIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="71.402" height="85.012" viewBox="0 0 71.402 85.012">
  <path data-name="Path 906" d="M76.754 4.807v77.63a2.7 2.7 0 0 1-2.7 2.7H20.189v-8.4a4.447 4.447 0 0 0-4.447-4.448h-8.4V4.807a2.7 2.7 0 0 1 2.7-2.7h64.016a2.7 2.7 0 0 1 2.7 2.7" transform="translate(-6.339 -1.123)" style={{fill: '#fff'}} />
  <path data-name="Path 907" d="M74.17 85.736H20.3a.488.488 0 0 1-.488-.488v-8.4a3.964 3.964 0 0 0-3.959-3.96h-8.4a.488.488 0 0 1-.488-.488V4.921a3.188 3.188 0 0 1 3.185-3.185h64.021a3.187 3.187 0 0 1 3.183 3.185v77.63a3.189 3.189 0 0 1-3.184 3.185zm-53.38-.976h53.38a2.211 2.211 0 0 0 2.208-2.209V4.921a2.21 2.21 0 0 0-2.207-2.208H10.148A2.21 2.21 0 0 0 7.94 4.921v66.987h7.915a4.941 4.941 0 0 1 4.935 4.936z" transform="translate(-6.453 -1.237)" style={{stroke: '#4b546e', fill: '#4b546e'}} />
  <g data-name="Path 908" style={{strokeLinecap: 'round', strokeLinejoin: 'round', fill: '#4b546e'}}>
    <path d="m20.69 70.074-.854-.853L6.985 56.37l-.854-.854h9.612a4.952 4.952 0 0 1 4.946 4.947v9.611z" style={{stroke: 'none'}} transform="translate(-6.339 15.144)" />
    <path d="M20.19 68.867v-8.404a4.446 4.446 0 0 0-4.447-4.447H7.338L20.19 68.867m0 1a1 1 0 0 1-.707-.293l-12.85-12.85a1 1 0 0 1 .706-1.708h8.405a5.453 5.453 0 0 1 5.446 5.447v8.404a1 1 0 0 1-1 1z" style={{fill: '#4b546e', stroke: 'none'}} transform="translate(-6.339 15.144)" />
  </g>
  <path data-name="Path 909" d="M20.3 69.468a.492.492 0 0 1-.346-.142L7.106 56.475a.489.489 0 0 1 .346-.834h8.4a4.94 4.94 0 0 1 4.935 4.935v8.4a.491.491 0 0 1-.3.451.5.5 0 0 1-.187.041zM8.63 56.617 19.815 67.8v-7.224a3.963 3.963 0 0 0-3.959-3.959z" transform="translate(-6.452 15.029)" style={{fill: '#4b546e'}} />
  <path data-name="Path 910" d="m17.2 37.343-1.881-1.8L31.68 18.435l15.015 4.776L61.338 8.785l1.828 1.855L47.4 26.169l-14.964-4.76z" transform="translate(-3.932 .89)" style={{fill: '#304f60'}} />
  <path data-name="Path 911" d="M45.067 33.009v15.579a1.7 1.7 0 0 1-1.695 1.7h-4.955a1.7 1.7 0 0 1-1.695-1.7V33.009a1.7 1.7 0 0 1 1.695-1.7h4.956a1.7 1.7 0 0 1 1.695 1.7" transform="translate(2.528 7.689)" style={{fill: '#8cad38'}} />
  <path data-name="Path 912" d="M43.486 50.885H38.53a2.187 2.187 0 0 1-2.184-2.185V33.123a2.187 2.187 0 0 1 2.184-2.185h4.955a2.186 2.186 0 0 1 2.183 2.185V48.7a2.186 2.186 0 0 1-2.182 2.185zM38.53 31.914a1.209 1.209 0 0 0-1.208 1.208V48.7a1.209 1.209 0 0 0 1.208 1.208h4.955a1.209 1.209 0 0 0 1.207-1.208V33.123a1.209 1.209 0 0 0-1.206-1.208z" transform="translate(2.415 7.576)" style={{fill: '#9bc13c'}} />
  <path data-name="Path 913" d="M33.227 29.243v20.481a1.7 1.7 0 0 1-1.7 1.7h-4.8a1.7 1.7 0 0 1-1.695-1.7V29.243a1.7 1.7 0 0 1 1.695-1.7h4.8a1.7 1.7 0 0 1 1.7 1.7" transform="translate(-.998 6.552)" style={{fill: '#e63e82'}} />
  <path data-name="Path 914" d="M31.644 52.021h-4.8a2.186 2.186 0 0 1-2.183-2.184v-20.48a2.186 2.186 0 0 1 2.183-2.185h4.8a2.187 2.187 0 0 1 2.184 2.185v20.48a2.187 2.187 0 0 1-2.184 2.184zm-4.8-23.873a1.209 1.209 0 0 0-1.206 1.208v20.481a1.209 1.209 0 0 0 1.206 1.208h4.8a1.209 1.209 0 0 0 1.208-1.208v-20.48a1.209 1.209 0 0 0-1.208-1.208z" transform="translate(-1.112 6.439)" style={{fill: '#ff5fa0'}} />
  <path data-name="Path 915" d="M56.532 21.567V52.04a1.7 1.7 0 0 1-1.695 1.695h-4.8a1.7 1.7 0 0 1-1.695-1.695V21.567a1.7 1.7 0 0 1 1.695-1.695h4.8a1.7 1.7 0 0 1 1.695 1.695" transform="translate(6.034 4.236)" style={{fill: '#eace6d'}} />
  <path data-name="Path 916" d="M54.951 54.336h-4.8a2.186 2.186 0 0 1-2.184-2.183V21.68a2.186 2.186 0 0 1 2.183-2.18h4.8a2.185 2.185 0 0 1 2.183 2.183v30.47a2.185 2.185 0 0 1-2.182 2.183zm-4.8-33.863a1.209 1.209 0 0 0-1.208 1.207v30.473a1.209 1.209 0 0 0 1.207 1.207h4.8a1.208 1.208 0 0 0 1.206-1.206V21.68a1.208 1.208 0 0 0-1.206-1.207z" transform="translate(5.922 4.123)" style={{fill: '#fae28d'}} />
  <path data-name="Path 917" d="M21.566 38.452v8.491a1.7 1.7 0 0 1-1.695 1.7h-4.8a1.7 1.7 0 0 1-1.7-1.7v-8.491a1.7 1.7 0 0 1 1.7-1.695h4.8a1.7 1.7 0 0 1 1.695 1.695" transform="translate(-4.517 9.33)" style={{fill: '#0185de'}} />
  <path data-name="Path 918" d="M19.984 49.242h-4.8A2.188 2.188 0 0 1 13 47.056v-8.49a2.186 2.186 0 0 1 2.185-2.183h4.8a2.185 2.185 0 0 1 2.183 2.183v8.491a2.186 2.186 0 0 1-2.184 2.185zm-4.8-11.882a1.21 1.21 0 0 0-1.209 1.206v8.491a1.211 1.211 0 0 0 1.209 1.209h4.8a1.21 1.21 0 0 0 1.206-1.209v-8.491a1.208 1.208 0 0 0-1.206-1.206z" transform="translate(-4.631 9.218)" style={{fill: '#8cd3ff'}} />
  <path data-name="Path 919" d="M21.9 30.465a4.407 4.407 0 1 1-4.408-4.408 4.427 4.427 0 0 1 4.408 4.408" transform="translate(-4.604 6.101)" style={{fill: '#33b6ff'}} />
  <path data-name="Path 920" d="M17.607 35.472a4.9 4.9 0 1 1 4.9-4.894 4.9 4.9 0 0 1-4.9 4.894zm0-8.814a3.919 3.919 0 1 0 3.919 3.92 3.924 3.924 0 0 0-3.919-3.92z" transform="translate(-4.718 5.99)" style={{fill: '#304f60'}} />
  <path data-name="Path 921" d="M33.668 18.362a4.407 4.407 0 1 1-4.408-4.408 4.426 4.426 0 0 1 4.408 4.408" transform="translate(-1.052 2.451)" style={{fill: '#33b6ff'}} />
  <path data-name="Path 922" d="M29.373 23.369a4.9 4.9 0 1 1 4.9-4.894 4.9 4.9 0 0 1-4.9 4.894zm0-8.814a3.919 3.919 0 1 0 3.919 3.92 3.924 3.924 0 0 0-3.919-3.92z" transform="translate(-1.167 2.338)" style={{fill: '#304f60'}} />
  <path data-name="Path 923" d="M45.169 21.984a4.407 4.407 0 1 1-4.408-4.408 4.427 4.427 0 0 1 4.408 4.408" transform="translate(2.417 3.543)" style={{fill: '#33b6ff'}} />
  <path data-name="Path 924" d="M40.875 26.993a4.9 4.9 0 1 1 4.9-4.9 4.9 4.9 0 0 1-4.9 4.9zm0-8.816a3.92 3.92 0 1 0 3.919 3.92 3.924 3.924 0 0 0-3.919-3.919z" transform="translate(2.304 3.429)" style={{fill: '#304f60'}} />
  <path data-name="Path 925" d="M56.957 10.6a4.407 4.407 0 1 1-4.408-4.408 4.427 4.427 0 0 1 4.408 4.408" transform="translate(5.975 .107)" style={{fill: '#33b6ff'}} />
  <path data-name="Path 926" d="M52.661 15.607a4.9 4.9 0 1 1 4.9-4.894 4.9 4.9 0 0 1-4.9 4.894zm0-8.814a3.919 3.919 0 1 0 3.921 3.92 3.924 3.924 0 0 0-3.921-3.921z" transform="translate(5.863 -.006)" style={{fill: '#304f60'}} />
  <path data-name="Path 927" d="M64.111 51.976H23.716a.488.488 0 1 1 0-.976h40.395a.488.488 0 0 1 0 .976z" transform="translate(-1.544 13.98)" style={{stroke: '#4b546e', fill: '#4b546e'}} />
  <path data-name="Path 928" d="M64.111 56.764H23.716a.488.488 0 0 1 0-.976h40.395a.488.488 0 0 1 0 .976z" transform="translate(-1.544 13.474)" style={{stroke: '#4b546e', fill: '#4b546e'}} />
  <path data-name="Path 929" d="M64.111 61.553H23.716a.488.488 0 0 1 0-.976h40.395a.488.488 0 0 1 0 .976z" transform="translate(-1.544 12.965)" style={{stroke: '#4b546e', fill: '#4b546e'}} />
</svg>
)

export const SVGIcons = {
  GGDriveImage,
  DeviceUploadImage,
  GoogleIcon,
  EmailIcon,
  KeyIcon,
  CheckedIcon,
  CheckedNotRoundedIcon,
  ErrorIcon,
  MessageIcon,
  BellIcon,
  BallIcon,
  Layout2Icon,
  UsersIcon,
  Map2Icon,
  WorldIcon,
  TrendUpIcon,
  TrenDownIcon,
  MinusIcon,
  ThumbDownIcon,
  ThumbUpIcon,
  CalendarIcon,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  UserCheckIcon,
  BrowserIcon,
  SearchIcon,
  StartIcon,
  CloseIcon,
  PlusIcon,
  ClipBoardTextIcon,
  AdjustmentsHorizontalIcon,
  BriefcaseIcon,
  DollarIcon,
  QuestionIcon,
  HomeIcon,
  EditIcon,
  WordIcon,
  DeleteNotification,
  PolygonIcon,
  RefreshIcon,
  TrashIcon,
  MenuIcon,
  ImportIcon,
  PhotoIcon,
  MoodSmileIcon,
  HashIcon,
  BrandFacebookNotFillIcon,
  BrandFacebookIcon,
  BrandInstagramNotFillIcon,
  BrandInstagramIcon,
  BrandTiktokNotFillIcon,
  BrandTiktokIcon,
  AlertCircle,
  FacebookImage,
  InstagramImage,
  YoutubeImage,
  GoogleImage,
  TiktokImage,
  LinkedInImage,
  LinkedInGreyIcon,
  BrandLinkedIcon,
  ClockHour4,
  DotCircleHorizontalIcon,
  MessageCircleIcon,
  ArrowBackUpIcon,
  WarningIcon,
  LinkIcon,
  HandRockIcon,
  PencilMinusIcon,
  SendIcon,
  ClockEditIcon,
  UserXIcon,
  UserExclamationIcon,
  UserMinusIcon,
  PlugConnectXIcon,
  AlertTriangleIcon,
  PaperClipIcon,
  UploadIcon,
  DownloadIcon,
  ClickIcon,
  EyeCheckIcon,
  DraftIcon,
  LogoGAIcon,
  LogoGMBIcon,
  LogoBEIcon,
  LogoCRIcon,
  LogoGFIcon,
  LogoGoogleIcon,
  PowerIcon,
  CopyIcon,
  AlertTriangleFillIcon,
  InfoCircleIcon,
  UserCircleIcon,
  FilterIcon,
  SettingIcon,
  LogoutIcon,
  CommentIcon,
  CalendarOffIcon,
  RefreshUserIcon,
  RubberStampIcon,
  LockOpenIcon,
  LockIcon,
  BackToTopIcon,
  DragIcon,
  InstagramLikeIcon,
  InstagramBookmarkIcon,
  InstagramDMIcon,
  InstagramRepIcon,
  TiktokCommentIcon,
  TiktokLikeIcon,
  TiktokShareIcon,
  ListDetailIcon,
  CirclePlusIcon,
  BuildingIcon,
  AppsIcon,
  BookIcon,
  LeftMessageIcon,
  UpgradeModalCloseIcon,
  DiscountCheckIcon,
  IconHelp,
  ChartIcon,
  NotesIcon,
  TeamMembersIcon,
  ReportAnalyticsIcon,
  ClockHour4Filled,
  CreditIcon,
  ArrowBigUpLine,
  StarFilledIcon,
  FreeTrialIcon,
  FreePlanIcon,
  PremiumPlanIcon,
  LimitedIcon,
  DiamondIcon,
  BlueCheckIcon,
  RedWarningIcon,
  SparklesIcon,
  BulbIcon,
  MoodHappyIcon,
  MoodCrazyHappyIcon,
  MoodNerdIcon,
  MoodSmileBeamIcon,
  MoodTongueWink,
  BookmarkIcon,
  BookmarksIcon,
  ArrowNarrowRight,
  AiIcon,
  XCloseIcon,
  PencilIcon,
  ShapeIcon,
  ExternalLink,
  FolderOpenIcon,
  CloudIconUpload,
  PackageIcon,
  TriangleSquareCircleIcon,
  BrandYoutubeNotFillIcon,
  BrandYoutubeIcon,
  BrandGoogleNotFillIcon,
  BrandGoogleIcon,
  SettingAutomationIcon,
  ConfettiIcon,
  InfoTriangleFillIcon,
  PhotoVideoIcon,
  CalendarPlusIcon,
  CirclePercentageIcon,
  XIcon,
  AtomFilledIcon,
  ChartAreaIcon,
  NightRefreshIcon,
  NightEditIcon,
  DotsIcon,
  CaretUpFilledIcon,
  CaretDownFilledIcon,
  ZohoIcon,
  SyncDoneIcon,
  PptIcon,
  PptxIcon,
  DocIcon,
  DocxIcon,
  PdfIcon,
  ReactionIcon,
  GraphicReportIcon,
};

export default SVGIcons;
