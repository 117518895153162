import { Modal } from "antd";
import moment from "moment";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { SocialApi } from "../apis/social.api";
import showNotification from "../components/common/notification";
import useUpdateContent from "../components/views/social/social-content/hook/useUpdateContent";
import { DATE_TIME_FORMAT } from "../constants/app-constants";
import { MenuActionContent, PostStatusType } from "../constants/app.enums";
import {
  ContentModel,
  GetListContentRequestModel,
} from "../models/content.model";
import useClient from "./useClient";
import useService from "./useService";
import momentTimezone from "moment-timezone";
import { authUserSelector } from "../states/auth";
import { contentValueState } from "../states/social";

export interface UseContentModel {
  onCreateContent: () => Promise<void>;
  onCreateContentCalendar: () => Promise<boolean>;
  onEditContent: (contentId: number) => Promise<void>;
  onEditContentCalendar: (contentId: number) => Promise<void>;
  onActionDropdown: (
    action: MenuActionContent,
    contentItem: ContentModel
  ) => void;
  publicNow: (contentItem: ContentModel) => Promise<void>;
  scheduleNow: (contentItem: ContentModel) => Promise<void>;
  initContentDetail: (contentId: number) => Promise<void>;
  initListContent: () => Promise<void>;
  contentSelected: ContentModel | undefined;
  openCreateModal: boolean;
  openDeleteModal: boolean;
  openEditModal: boolean;
  openScheduleModal: boolean;
  scheduleTime: moment.Moment | undefined;
  contentShareLink: ContentModel | undefined;
  setScheduleTime: Dispatch<SetStateAction<moment.Moment | undefined>>;
  setOpenCreateModal: Dispatch<SetStateAction<boolean>>;
  setOpenScheduleModal: Dispatch<SetStateAction<boolean>>;
  setOpenEditModal: Dispatch<SetStateAction<boolean>>;
  setOpenDeleteModal: Dispatch<SetStateAction<boolean>>;
  setContentShareLink: Dispatch<SetStateAction<ContentModel | undefined>>;
  setContentSelected: Dispatch<SetStateAction<ContentModel | undefined>>;
  loadingSchedule: boolean;
}

const useContent = () => {
  const { t } = useTranslation();
  const { SocialService } = useService();
  const { clientId, client } = useClient();
  const [user, setUser] = useRecoilState(authUserSelector);
  const { createContent, editContent, isValidContent } = useUpdateContent();
  const [contentValue, setContentValue] = useRecoilState(contentValueState);
  const [searchParams, setSearchParams] = useSearchParams();

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loadingSchedule, setLoadingSchedule] = useState(false);
  const [contentSelected, setContentSelected] = useState<ContentModel>();
  const [scheduleTime, setScheduleTime] = useState<moment.Moment>();
  const [contentShareLink, setContentShareLink] = useState<ContentModel>();

  const onActionDropdown = (
    action: MenuActionContent,
    contentItem: ContentModel
  ) => {
    setContentSelected({ ...contentItem });
    if (action !== MenuActionContent.SCHEDULE) setOpenScheduleModal(false);
    switch (action) {
      case MenuActionContent.EDIT:
        setOpenEditModal(true);
        break;
      case MenuActionContent.PUBLISH:
        publicNow(contentItem);
        break;
      case MenuActionContent.SCHEDULE:
        setScheduleTime(moment.utc(contentItem.date).local());
        setOpenScheduleModal(true);
        break;
      case MenuActionContent.DELETE:
        setOpenDeleteModal(true);
        break;
      case MenuActionContent.SHARE:
        setContentShareLink(contentItem);
        break;
      default:
        break;
    }
  };

  const initListContent = async (params?: GetListContentRequestModel) => {
    const defaultPageIndex = params?.pageIndex ?? 1;
    await SocialService.getListContent({
      clientId,
      ...params,
      pageIndex: defaultPageIndex,
    });
  };

  const getListContentCalendar = async () => {
    await SocialService.getListContentCalendar({ clientId });
  };

  const publicNow = async (contentItem: ContentModel) => {
    Modal.confirm({
      title: t("social.content.publicNowTitle"),
      content: <span>{t("social.content.publicNowDescription")}</span>,
      okText: t("social.content.publicNow"),
      cancelText: t("common.cancel"),
      cancelButtonProps: { type: "text" },
      icon: "",
      className: "confirm-modal",
      centered: true,
      onOk: async () => {
        try {
          const { id } = contentItem;
          const { data } = await SocialApi.publishContent(id);
          if (data.status === PostStatusType.Error) {
            showNotification("error", t("social.content.failToPublish"));
          }
          else {
            showNotification("success", t("social.content.publicNowSuccess"));
          }
        } catch (error) {
          // Do not show error here because it was show with common code in httpClient
        }

        if (searchParams.has("page")) {
          const pageIndex = Number(searchParams.get("page"));
          await initListContent({pageIndex});
        } else {
          await initListContent();
        }

      },
    });
  };

  const scheduleNow = async (contentItem: ContentModel) => {
    const scheduledDate = client?.timeZoneInfo?.id
      ? momentTimezone.utc(scheduleTime).tz(client?.timeZoneInfo?.id)
      : moment(scheduleTime);
    setLoadingSchedule(true);
    try {
      if (moment(scheduleTime).isBefore()) {
        showNotification("error", "Schedule time cannot be in the past!");
        setLoadingSchedule(false);
        return;
      }
      const { id } = contentItem;
      await SocialApi.scheduleContent(id, moment.utc(scheduleTime).format());
      setOpenScheduleModal(false);
      showNotification(
        "success",
        t("social.content.scheduleNowSuccess", {
          time: scheduledDate.format(DATE_TIME_FORMAT.dateTimeViewFormatPM),
        })
      );
      if (searchParams.has("page")) {
        const pageIndex = Number(searchParams.get("page"));
        await initListContent({ pageIndex });
      } else {
        await initListContent();
      }
    } catch (error) {
      // showNotification("error", t("social.content.scheduleNowError"));
    }
    setLoadingSchedule(false);
  };

  const onCreateContent = async () => {
    if (!(await isValidContent())) return;
    const isSuccess = await createContent();
    if (isSuccess) {
      setOpenCreateModal(false);
      await initListContent();
    }
  };

  const onCreateContentCalendar = async () => {
    if (!(await isValidContent())) return Promise.reject();
    const isSuccess = await createContent();
    if(isSuccess) {
      setOpenCreateModal(false);
      await getListContentCalendar();
    }
    return isSuccess;
  };

  const onEditContent = async (contentId: number) => {
    if (!(await isValidContent())) return;
    const isSuccess = await editContent(contentId);
    if(isSuccess) {
      setOpenEditModal(false);
      await initListContent();
    }
  };

  const onEditContentCalendar = async (contentId: number) => {
    if (!(await isValidContent())) return;
    const isSuccess = await editContent(contentId);
    if(isSuccess) {
      setOpenEditModal(false);
      await getListContentCalendar();
    }
  };

  const initContentDetail = async (contentId: number) => {
    try {
      const { data } = await SocialApi.getContentDetail(contentId);
      setContentSelected(data);
      setOpenEditModal(true);
      // switch client if
      if (clientId !== data.clientId) {
        const clientSelected = user?.clients.find(
          (x) => x.id === data.clientId
        );
        clientSelected && user && setUser({ ...user, clientSelected });
      }
    } catch (error) {
      if (searchParams.has("contentId")) {
        searchParams.delete("contentId");
        setSearchParams(searchParams);
      }
    }
  };

  const closeCreateModal = () => {
    setOpenCreateModal(false);
    setContentValue({ ...contentValue, showWriteWithAI: false });
  };

  const onOpenCreateWithAIModal = () => {
    setOpenCreateModal(true);
    setContentValue({ ...contentValue, showWriteWithAI: true });
  };
  return {
    onCreateContent,
    onCreateContentCalendar,
    onEditContent,
    onEditContentCalendar,
    onActionDropdown,
    publicNow,
    scheduleNow,
    initContentDetail,
    initListContent,
    contentSelected,
    openCreateModal,
    openDeleteModal,
    openEditModal,
    openScheduleModal,
    scheduleTime,
    contentShareLink,
    setScheduleTime,
    setOpenCreateModal,
    setOpenScheduleModal,
    setOpenEditModal,
    setOpenDeleteModal,
    setContentShareLink,
    setContentSelected,
    loadingSchedule,
    closeCreateModal,
    onOpenCreateWithAIModal,
  };
};

export default useContent;
