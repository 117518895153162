import { SocialType } from "./app.enums";

export const SocialTypes = [
  SocialType.Facebook,
  SocialType.Instagram,
  SocialType.Tiktok,
  SocialType.Youtube,
  SocialType.Google,
  SocialType.LinkedIn,
];
