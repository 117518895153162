import { Button, Upload, UploadProps } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import useDrivePicker from "react-google-drive-picker";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { MediaLibraryApi } from "../../../../../apis/media-library.api";
import { MAXIMUM_VIDEO_SIZE } from "../../../../../constants/app-constants";
import useClient from "../../../../../hooks/useClient";
import { useTriggerReloadUsageStorage } from "../../../../../hooks/useTriggerReloadUsageStorage";
import { MediaLibraryState } from "../../../../../states/media-library";
import { CommonUtils } from "../../../../../utils/common";
import showNotification from "../../../../common/notification";
import SVGIcons from "../../../../icons/svgs";
import "./index.scss";
import { MediaExtensions } from "../../../../../constants/app.enums";

type Props = {
  onClose?: () => void;
};

type PreviewImage = {
  name: string;
  src: string;
  size: string;
  type: string;
  duration?: number;
  extension?: MediaExtensions;
  status?: "pending" | "success" | "error";
};

export const getExtensionIcon = (extension?: MediaExtensions) => {
  switch (extension) {
    case MediaExtensions.Ppt:
      return <SVGIcons.PptIcon />;
    case MediaExtensions.Pptx:
      return <SVGIcons.PptxIcon />;
    case MediaExtensions.Doc:
      return <SVGIcons.DocIcon />;
    case MediaExtensions.Docx:
      return <SVGIcons.DocxIcon />;
    case MediaExtensions.Pdf:
      return <SVGIcons.PdfIcon />;
    default:
      return "";
  }
}

const UploadMedia = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const [openPicker, authResponse] = useDrivePicker();
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const { Dragger } = Upload;
  const [fileList, setFileList] = useState<any[]>([]);
  const [mediaLibraryState, setMediaLibraryState] =
    useRecoilState(MediaLibraryState);
  const [previewImages, setPreviewImages] = useState<PreviewImage[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const { triggerReloadStorage } = useTriggerReloadUsageStorage()
  const [listMediaGGDrivePicker, setListGGDrivePicker] = useState<
    { fileName: string; fileId: string }[]
  >([]);
  const [uploading, setUploading] = useState(false);
  const isUploadInModal = onClose ? true : false;

  const { clientId } = useClient();

  const onSelectFiles = async (e: any) => {
    const fileList = Array.from(e.target.files);
    if (invalidFileUpload(fileList)) {
      return;
    }
    setSelectedFiles((prev) => [...prev, ...fileList]);
    onSetPreviewImages(fileList);
    e.target.value = null;
  };

  const onSetPreviewImages = (files: any) => {
    files.forEach((file: any) => {
      if (file.type.startsWith("video/")) {
        const video = document.createElement("video");
        const videoSrc = URL.createObjectURL(file);

        video.preload = "metadata";
        video.src = URL.createObjectURL(file);
        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src);
          const duration = video.duration;
          setPreviewImages((prevImages) => [
            ...prevImages,
            {
              name: file.name,
              src: videoSrc,
              size: CommonUtils.formatFileSize(file.size),
              type: "video",
              duration: duration,
              status: file.status,
            },
          ]);
        };
      } else if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImages((prevImages) => [
            ...prevImages,
            {
              name: file.name,
              src: reader.result as string,
              size: CommonUtils.formatFileSize(file.size),
              type: "image",
              duration: 0,
              status: file.status,
            },
          ]);
        };
        reader.readAsDataURL(file);
      }
      else {
        const extension = file.name.split('.').pop()?.toLowerCase();

        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImages((prevImages) => [
            ...prevImages,
            {
              name: file.name,
              src: reader.result as string,
              size: CommonUtils.formatFileSize(file.size),
              type: "document",
              duration: 0,
              status: file.status,
              extension,
            },
          ]);
        };
        reader.readAsDataURL(file);
      }
    });
  };

  const invalidFileUpload = (fileList: any[]): boolean => {
    if (fileList.length === 0) return true;
    return fileList.some((file) => {
      if (file.size && file.size > MAXIMUM_VIDEO_SIZE) {
        showNotification("error", t("social.content.errorMaximumFileSize"));
        return true;
      }
      return false;
    });
  };

  const controller = useRef(new AbortController());

  const onUpload = async (files: any) => {
    setUploading(true);
    const updatedFiles = [...files];
    const totalFiles = files.length;
    let overallProgress = 0;
    const progressPerFile = 100 / totalFiles;

    setUploadProgress(0);
    const createOnUploadProgress = () => (percentCompleted: number) => {
      const fileProgress = (percentCompleted * progressPerFile) / 100;
      setUploadProgress(prev => overallProgress + fileProgress);
    };
    for (let i = 0; i < totalFiles; i++) {
      try {
        if (files[i].status !== "success") {
          await new Promise(resolve => setTimeout(resolve, 1000));

          await MediaLibraryApi.uploadMedia(
            {
              file: files[i],
              clientId,
            },
            controller.current.signal,
            createOnUploadProgress()
          );

          updatedFiles[i].status = "success";
          overallProgress += progressPerFile; 
        }
      } catch (error) {
        updatedFiles[i].status = "error";
      }
    }
    setPreviewImages([]);
    onSetPreviewImages(updatedFiles);
    setSelectedFiles(updatedFiles);
    setUploading(false);
    if (updatedFiles.every((file) => file.status === "success")) {
      setMediaLibraryState({
        ...mediaLibraryState,
        triggerReloadMediaList: mediaLibraryState.triggerReloadMediaList + 1,
      });

      setUploading(false);
      setSelectedFiles([]);
      setFileList([]);
      setPreviewImages([]);
      onClose && onClose();
      showNotification("success", t("mediaLibrary.uploadSuccess"));
      triggerReloadStorage();

    } else {
      // showNotification("error", t("social.content.errorUnknownUploadFile"));
    }
  };


  const onUploadGoogleDrive = async () => {
    if (!authResponse?.access_token) return;
    const { access_token } = authResponse;
    if (listMediaGGDrivePicker && listMediaGGDrivePicker.length > 0) {
      setUploading(true);
      try {
        await Promise.all(
          listMediaGGDrivePicker.map(async (file) => {
            const { fileName, fileId } = file;
            await MediaLibraryApi.uploadGGDriveMedia({
              fileName,
              fileId,
              accessToken: access_token,
              clientId,
            });
          })
        );

        onClose && onClose();
        setMediaLibraryState({
          ...mediaLibraryState,
          triggerReloadMediaList: mediaLibraryState.triggerReloadMediaList + 1,
        });
        showNotification("success", t("mediaLibrary.uploadSuccess"));
        triggerReloadStorage();
      } catch (error) { }
      setUploading(false);
    }
  };

  useEffect(() => {
    listMediaGGDrivePicker.length && onUploadGoogleDrive();
  }, [listMediaGGDrivePicker]);

  const debounce = (func: any, timeout = 300) => {
    let timer: any;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  };
  const handleDragFiles = async (e: any) => {
    const fileList = e.fileList
      .map((file: any) => file.originFileObj)
      .filter((x: any) => x);

    if (invalidFileUpload(fileList)) {
      return;
    }
    setSelectedFiles((prev) => [...prev, ...fileList]);
    onSetPreviewImages(fileList);
  };

  const debouncedHandleDragFiles = useCallback(
    debounce(handleDragFiles, 300),
    []
  );
  const props: UploadProps = {
    name: "file",
    multiple: true,
    openFileDialogOnClick: false,
    onChange: debouncedHandleDragFiles,
    beforeUpload: (file) => {
      setFileList((prevList) => [...prevList, file]);
      return false;
    },
    accept: ".jpg, .jpeg, .bmp, .gif, .tiff, .png, .mp4, .mov, .avi, .webm, .doc, .docx, .ppt, .pptx",
    fileList: fileList,
    showUploadList: false,
  };
  const handleOpenPicker = () => {
    openPicker({
      customScopes: ['https://www.googleapis.com/auth/drive.readonly',
        'https://www.googleapis.com/auth/drive.readonly.metadata'],
      clientId: process.env.REACT_APP_GG_CLIENT_ID || "",
      developerKey: process.env.REACT_APP_GG_API_KEY || "",
      viewId: "DOCS_IMAGES_AND_VIDEOS",
      showUploadView: false,
      showUploadFolders: false,
      supportDrives: true,
      multiselect: true,
      callbackFunction: (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        }
        if (data?.docs && data?.docs[0]?.url) {
          const files = data.docs
            .filter((f) => f.url)
            .map((f) => {
              return {
                fileName: f.name,
                fileId: f.id,
              };
            });
          setListGGDrivePicker(files);
        }
      },
    });
  };

  const onDeleteRow = (index: number) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setPreviewImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const onDeleteRows = () => {
    setSelectedFiles([]);
    setPreviewImages([]);
  };

  const getFileNameAndClassByStatus = (status?: string) => {
    switch (status) {
      case "success":
        return { name: t("mediaLibrary.success"), className: "status success" };
      case "error":
        return { name: t("mediaLibrary.error"), className: "status error" };
      default:
        return { name: t("mediaLibrary.pending"), className: "status pending" };
    }
  };

 

  return (
    <div className={`upload-media-file ${isUploadInModal ? "isModal" : ""}`}>
      <div className={`header ${isUploadInModal ? "isModal" : ""}`}>
        <div className="left">
          <Button type="default" className="gg-drive-upload">
            <input
              type="file"
              id={`browse-upload ${isUploadInModal ? "isModal" : ""}`}
              onChange={onSelectFiles}
              multiple
              accept=".jpg, .jpeg, .bmp, .gif, .tiff, .png, .mp4, .mov, .avi, .webm, .doc, .docx, .ppt, .pptx, .pdf"
              hidden
            />
            <label htmlFor={`browse-upload ${isUploadInModal ? "isModal" : ""}`}>
              <SVGIcons.FolderOpenIcon />
              {t("mediaLibrary.browse")}
            </label>
          </Button>
          {/* <Button
            onClick={handleOpenPicker}
            type="default"
            disabled={selectedFiles.length > 0}
            className="gg-drive-upload"
          >
            <SVGIcons.GGDriveImage />
            {t("mediaLibrary.googleDrive")}
          </Button> */}
          <Button
            onClick={()=> onUpload(selectedFiles)}
            disabled={selectedFiles.length === 0 || uploading}
            type="primary"
            className="secondary-btn"
          >
            <SVGIcons.CloudIconUpload />
            {t("mediaLibrary.upload")}
          </Button>
        </div>
        <div className="right">
          <Button
            onClick={onDeleteRows}
            disabled={selectedFiles.length === 0}
            type="default"
          >
            <SVGIcons.CloseIcon />
            {t("mediaLibrary.cancelAll")}
          </Button>
        </div>
      </div>
      <div
        onDragOver={(e) => {
          e.preventDefault();
          setIsDragging(true);
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          setIsDragging(false);
        }}
      >
        <Dragger
          className={`dragdrop-area ${previewImages.length === 0 ? "centered" : ""
            }`}
          {...props}
        >
          {
            uploading &&
            <div className="scroll-upload progress-bar">
              <div style={{
                width: `${uploadProgress}%`,
              }} className="scroll-bar">
              </div>
            </div>
          }

          {previewImages.length === 0 ? (
            <p className={`empty-text ${isDragging ? "dragging" : ""}`}>
              {t("mediaLibrary.dragDropFiles")}
            </p>
          ) : (
            <div className="uploaded-list">
              {previewImages.map((item, index) => (
                <div key={index} className="uploaded-item-wrapper">
                  <div className="uploaded-item">
                    <div className="thumbnail">
                      {
                        item.type === "video" ?
                          <video controls={false} key={item.src} preload="metadata">
                            <source src={`${item.src}#t=0.1`} type="video/mp4" />
                          </video>
                          :
                          item.type === "document" ? getExtensionIcon(item.extension) :
                          <img src={item.src} alt={item.name} />
                      }

                      {item.type === "video" && (
                        <div className="duration">
                          {item.duration &&
                            CommonUtils.formatDuration(item.duration)}
                        </div>
                      )}
                    </div>
                    <div className="details">
                      <div className="file-title">{item.name}</div>
                      <div className="quantity">
                        {item.size}{" "}
                        <span
                          className={
                            getFileNameAndClassByStatus(item.status).className
                          }
                        >
                          {getFileNameAndClassByStatus(item.status).name}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => onDeleteRow(index)}
                    className="close-red-icon"
                  >
                    <SVGIcons.CloseIcon />
                  </div>
                </div>
              ))}
            </div>
          )}
        </Dragger>
      </div>
    </div>
  );
};

export default UploadMedia;
