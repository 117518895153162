import {
    Button,
    Collapse,
    Drawer,
    InputNumber,
    Switch,
    Tooltip
} from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthApi } from "../../../../apis/auth.api";
import { BoardType } from "../../../../constants/app.enums";
import { ROUTE_PATHS } from "../../../../constants/router.constants";
import useClient from "../../../../hooks/useClient";
import InsightHubIntegrationCollapse from "../../../common/integration-collapse/insight-hub-integration";
import showNotification from "../../../common/notification";
import AddConnection from "../../client-sites/intergration-form/add-connection";
import "./index.scss";
import { PercentageOutlined } from '@ant-design/icons';
import SVGIcons from "../../../icons/svgs";
import useFetchConnection from "../../../../hooks/useFetchConnection";

type Props = {
    isShow: boolean;
    setIsShow: (show: boolean) => void;
}

const { Panel } = Collapse;

const enum EditSalesAnalyticsDashboardKey {
    DashboardSettings = "0"
}

function EditInsightHubDashboard(props: Props) {
    const { isShow, setIsShow } = props;
    const { t } = useTranslation();
    const [activeKey, setActiveKey] = useState([]);
    const [showInsigntTrendIndicator, setShowInsigntTrendIndicator] = useState(false);
    const [averageValue, setAverageValue] = useState(0);
    const [conversionRate, setConversionRate] = useState(30);
    const { client, setClient, clientId, isOwner } = useClient();
    const [loading, setLoading] = useState(false);
    const { fetchConnection } = useFetchConnection();

    const onChange = (value: any) => {
        setActiveKey(value);
    };

    useEffect(() => {
        if (client) {
            updateValueFromClient();
        }
    }, [client]);

    useEffect(() => {
        if (client && isShow) {
            fetchConnection();
        }
    }, [isShow, client])


    function updateValueFromClient() {
        setAverageValue(client?.averageValueOfNewCustomer || 0);
        setConversionRate(client?.conversionRate || 0);
        setShowInsigntTrendIndicator(client?.showInsigntTrendIndicator || false);
    }

    const onChangeValue = (value: number) => {
        setAverageValue(value);
    };

    const onChangeConversionRate = (value: number) => {
        setConversionRate(value);
    };

    const onClickCancel = () => {
        setAverageValue(client?.averageValueOfNewCustomer || 0);
    };

    const onResetConversionRate = () => {
        setConversionRate(client?.conversionRate || 0);
    }

    const onSave = async () => {
        if (!client) return;
        try {
            setLoading(true);
            await AuthApi.saveDashboardSettings(client?.id, BoardType.INSIGHTS_HUB, showInsigntTrendIndicator, averageValue, conversionRate);
            if (client) {
                setClient({
                    ...client,
                    showInsigntTrendIndicator: showInsigntTrendIndicator,
                    averageValueOfNewCustomer: averageValue,
                    conversionRate: conversionRate
                });
                showNotification("success", t(`common.yourChangesSaveSuccess`));
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Drawer
            className="app-edit-drawer"
            title={"Edit Dashboard - Insights Hub"}
            open={isShow}
            width={540}
            onClose={() => setIsShow(false)}
            maskClosable={false}
            footer={
                <div className="drawer-footer-wrapper">
                    <Button type="text" onClick={() => setIsShow(false)}>
                        {t("clientSites.btnClose")}
                    </Button>
                </div>
            }
        >
            <div className="edit-drawer-content">
                <p className="description">{t("salesAnalytics.manageDashboardSettings")}
                    {" "}
                    <a className="learnMore" target="_blank" rel="noreferrer" href={ROUTE_PATHS.Docs.SetUpDashboard}>
                        {t("salesAnalytics.learnMore")}
                    </a>
                </p>

                <Collapse
                    className="site-collapse-custom-collapse"
                    bordered={false}
                    expandIconPosition={"end"}
                    activeKey={activeKey}
                    onChange={onChange}
                >
                    <Panel
                        header={
                            <>
                                <div className="title">
                                    {t("salesAnalytics.dashboardSettings")}
                                </div>
                                <div className="description">
                                    {t("salesAnalytics.adjustTheKey")}
                                </div>

                            </>
                        }
                        key={EditSalesAnalyticsDashboardKey.DashboardSettings}
                    >
                        <div className="trend-indicators average-value" >

                            <div className="fields-wrapper">
                                <div className="left" >
                                    <div>{t("generalSettings.averageValue")}</div>
                                    <div className="average-value__input">
                                        <Tooltip
                                            overlayClassName="custom-tooltip"
                                            placement="top"
                                            title={t("generalSettings.averageValueTooltip")}
                                        >
                                            <div className="icon">
                                                <span>?</span>
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div>
                                    <div className="average-value__input">
                                        <InputNumber
                                            addonBefore={<SVGIcons.DollarIcon />}
                                            value={averageValue}
                                            onChange={(value) => onChangeValue(value || 0)}
                                            disabled={!client || !isOwner()}
                                            min={0}
                                        />

                                        <Button
                                            disabled={!isOwner()}
                                            className="mid-night-border reset-btn"
                                            onClick={onClickCancel}
                                        >
                                            {t("common.reset")}
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div className="fields-wrapper">
                                <div className="left" >
                                    <div>{t("generalSettings.conversionRate")}</div>
                                </div>
                                <div>
                                    <div className="average-value__input">
                                        <InputNumber
                                            className="input-right-addon"
                                            addonAfter={<PercentageOutlined />}
                                            value={conversionRate}
                                            onChange={(value) => onChangeConversionRate(value || 0)}
                                            disabled={!client || !isOwner()}
                                            min={0}
                                            precision={2}
                                        />


                                        <Button
                                            disabled={!isOwner()}
                                            className="mid-night-border reset-btn"
                                            onClick={onResetConversionRate}
                                        >
                                            {t("common.reset")}
                                        </Button>
                                    </div>
                                </div>
                            </div>

                            <div className="wrapper">
                                <div className="left" >
                                    <div>{t("generalSettings.trendIndicators")}</div>
                                    <Tooltip
                                        overlayClassName="custom-tooltip"
                                        placement="top"
                                        title={t("generalSettings.trendIndicatorTooltip")}
                                    >
                                        <div className="icon">
                                            <span>?</span>
                                        </div>
                                    </Tooltip>
                                </div>
                                <div>
                                    <Switch
                                        checked={showInsigntTrendIndicator}
                                        onChange={(value) => setShowInsigntTrendIndicator(value)}
                                    />
                                </div>
                            </div>
                            <div className="saveBtn" >
                                <Button
                                    loading={loading}
                                    onClick={onSave}
                                    className="save-btn primary-btn"
                                    disabled={!client ||
                                        (client.averageValueOfNewCustomer === averageValue
                                            && client.conversionRate === conversionRate
                                            && showInsigntTrendIndicator === client?.showInsigntTrendIndicator)
                                    }
                                    type="primary">
                                    {t("common.save")}
                                </Button>
                            </div>
                        </div>
                    </Panel>
                </Collapse>
                {
                    clientId && <InsightHubIntegrationCollapse clientId={clientId} />
                }
                {isOwner() && <AddConnection />}
            </div>
        </Drawer>
    );
}

export default EditInsightHubDashboard;
