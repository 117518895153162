import { Button, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  CommentModel,
  ContentMediaModel,
  CreateCommentRequest,
} from "../../../../../../../models/content.model";
import { authState } from "../../../../../../../states/auth";
import AppAvatar from "../../../../../../common/app-avatar/app-avatar";
import CommentDraft from "../comment-draft";
import "./index.scss";
import { useTranslation } from "react-i18next";
import SVGIcons from "../../../../../../icons/svgs";
import AppLightbox from "../../../../../../common/light-box";
import MediaUtils from "../../../../../../../utils/media.utils";
import PlayIcon from "../../../../../../../assets/icons/play-button.svg";
import LocalUtils from "../../../../../../../utils/local.utils";
import {
  requiredGuestUserState,
  showCommentMobileState,
} from "../../../../../../../states/social";

interface CommentPostedProps {
  commentInfo: CommentModel;
  hasLine?: boolean;
  createComment: (
    request: Omit<CreateCommentRequest, "contentId">
  ) => Promise<void>;
  parentId?: number;
  remove: (id: number) => void;
  showDraft?: boolean;
  setShowDraft?: React.Dispatch<React.SetStateAction<boolean>>;
  lastChild?: boolean;
  isReviewing?: boolean;
}

const CommentPosted = (props: CommentPostedProps) => {
  const {
    commentInfo,
    hasLine,
    createComment,
    parentId,
    remove,
    showDraft: showDraftParent,
    setShowDraft: setShowDraftParent,
    lastChild,
    isReviewing,
  } = props;
  const { t } = useTranslation();
  const { user } = useRecoilValue(authState);
  const [showDraftInner, setShowDraftInner] = useState(false);
  const {
    description,
    user: userComment,
    guestUser: guestUserComment,
    createdDate,
    mediaUrl,
    childs,
    id,
    isInternalComment,
  } = commentInfo;

  const guestInfo = LocalUtils.getGuestUserInfo();

  const [showDraft, setShowDraft] = parentId
    ? [showDraftParent, setShowDraftParent]
    : [showDraftInner, setShowDraftInner];

  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [lightBoxStartIndex, setLightBoxStartIndex] = useState(0);
  const [replyHighlight, setReplyHighlight] = useState<boolean>(false);
  const [showCommentMobile, setShowCommentMobile] = useRecoilState(
    showCommentMobileState
  );
  const setRequiredGuestUser = useSetRecoilState(requiredGuestUserState);

  const commentDraftRef = useRef<any>();
  const replyRef = useRef<any>();
  const commentPostedRef = useRef<any>();

  const handleFocus = () => {
    setShowDraft && setShowDraft(true);
    commentDraftRef.current && commentDraftRef.current.focus();
  };

  const openLightBox = (index: number) => {
    setLightBoxOpen(true);
    setLightBoxStartIndex(index);
  };

  const onReplyMobile = () => {
    if (!guestInfo?.uuid) {
      setRequiredGuestUser(true);
      return;
    }
    setShowCommentMobile(parentId || id);
    setReplyHighlight(true);
    setShowDraft && setShowDraft(true);
  };

  const canDeleteComment = () => {
    if (guestUserComment) return guestInfo?.uuid === guestUserComment?.uuid;
    return user?.userId === userComment?.id;
  };

  const renderChilds = () => {
    return childs.map((child, index) => {
      const hasLine = index < childs.length - 1 ? true : false;
      return (
        <CommentPosted
          commentInfo={child}
          key={child.id}
          hasLine={showDraft || hasLine}
          createComment={createComment}
          parentId={id}
          remove={() => remove(child.id)}
          showDraft={showDraft}
          setShowDraft={setShowDraft}
          lastChild={index === childs.length - 1}
          isReviewing={isReviewing}
        />
      );
    });
  };

  const renderGuestName = () => {
    if (guestInfo?.uuid === guestUserComment?.uuid) return "You";
    return guestUserComment?.fullName;
  };

  const renderName = () => {
    if (guestUserComment) return renderGuestName();
    if (user?.userId === userComment?.id) return "You";
    return <div>{`${userComment?.firstName} ${userComment?.lastName}`}</div>;
  };

  const renderDescriptionComment = () => {
    return (
      description &&
      description?.split(/\n/).map((line: any, index) => {
        return (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        );
      })
    );
  };

  const handleClickOutside = (event: any) => {
    if (
      commentPostedRef.current &&
      !commentPostedRef.current.contains(event.target)
    ) {
      const commentDraftDetailElm = document.getElementsByClassName(
        "comment-draft-detail-mobile"
      )[0];
      if (commentDraftDetailElm && commentDraftDetailElm.contains(event.target))
        return;
      setReplyHighlight(false);
    }
  };

  const createCommentReply = async (
    request: Omit<CreateCommentRequest, "contentId">
  ) => {
    await createComment({ ...request });
    setShowDraft && setShowDraft(false);
  };

  useEffect(() => {
    if (!showCommentMobile) setReplyHighlight(false);
  }, [showCommentMobile]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="comment-posted-wrap">
      <div
        className={`comment-wrap ${isInternalComment ? "lock" : ""}`}
        ref={commentPostedRef}
      >
        <div className="comment-posted">
          <div className="comment-posted-avatar">
            <AppAvatar src={userComment?.profilePictureUrl} size={36} />
          </div>
          <div className="comment-posted-content">
            <div
              className={`comment-posted-detail ${
                showCommentMobile && replyHighlight ? "highlight" : ""
              }`}
            >
              <div className="comment-posted-detail__name">
                {renderName()}
                {isInternalComment && (
                  <div className="comment-posted-detail__name-lock">
                    <SVGIcons.LockIcon />
                  </div>
                )}
              </div>
              <div className="comment-posted-detail__comment">
                {renderDescriptionComment()}
              </div>
              {mediaUrl && mediaUrl.length > 0 && (
                <div className="list-media">
                  {mediaUrl.map((media: any, index) => {
                    return (
                      <div
                        className="media"
                        key={media}
                        onClick={() => openLightBox(index)}
                      >
                        {MediaUtils.isVideo(media) ? (
                          <>
                            <video
                              key={media}
                              height={60}
                              width="auto"
                              controls={false}
                              preload="metadata"
                            >
                              <source src={media} type="video/mp4" />
                            </video>
                            <img
                              className="play-icon"
                              src={PlayIcon}
                              alt="play"
                            />
                          </>
                        ) : (
                          <img src={media} alt="" />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
              <div
                className="reply"
                ref={replyRef}
                onClick={() => {
                  handleFocus();
                }}
              >
                Reply
              </div>
              <div className="reply-mobile" onClick={onReplyMobile}>
                Reply
              </div>
            </div>
            <Tooltip
              title={moment
                .utc(createdDate)
                .local()
                .format("MMM DD, YYYY, hh:mma")}
            >
              <span className="comment-created-date">
                {moment.utc(createdDate).fromNow()}
              </span>
            </Tooltip>

            {
              // only delete my comment
              canDeleteComment() && (
                <Tooltip title={t("comment.deleteTooltip")}>
                  <Button
                    className="comment-delete"
                    type="text"
                    onClick={() => remove(id)}
                  >
                    <SVGIcons.TrashIcon />
                  </Button>
                </Tooltip>
              )
            }
          </div>
          {(childs?.length || hasLine || showDraft) && (
            <div
              className={`comment-posted-line ${
                (lastChild === undefined && !childs?.length) || lastChild // parent have not child or last child
                  ? "last-child"
                  : ""
              }`}
            ></div>
          )}
        </div>
      </div>
      {childs && renderChilds()}
      {!parentId && showDraft && (
        <CommentDraft
          setShowDraft={setShowDraft}
          handleFocus={handleFocus}
          ref={commentDraftRef}
          replyRef={replyRef}
          isInternalComment={isInternalComment}
          parentId={parentId || id}
          createComment={(request) => createCommentReply(request)}
          isReviewing={isReviewing}
        />
      )}

      {mediaUrl && (
        <AppLightbox
          listMedia={mediaUrl.map(x => { return { url: x } as ContentMediaModel})}
          index={lightBoxStartIndex}
          open={lightBoxOpen}
          close={() => setLightBoxOpen(false)}
        />
      )}
    </div>
  );
};

export default CommentPosted;
