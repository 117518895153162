import { useEffect, useMemo, useRef } from "react";
import MediaUtils from "../../../utils/media.utils";
import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import "./index.scss";
import { ContentMediaModel } from "../../../models/content.model";

interface Props {
  listMedia: ContentMediaModel[];
  open: boolean;
  index: number;
  close?: Function;
}

const AppLightbox = ({ listMedia, open, index, close }: Props) => {

  const titleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!open) {
      return;
    }

    updateIcon();
  }, [open]);

  const updateIcon = () => {
    setTimeout(() => {
      const next = document.getElementsByClassName("yarl__navigation_next");
      if (next.length) {
        next[0].innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-narrow-right" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M5 12l14 0"></path>
            <path d="M15 16l4 -4"></path>
            <path d="M15 8l4 4"></path>
         </svg>`;
      }

      const prev = document.getElementsByClassName("yarl__navigation_prev");
      if (prev.length) {
        prev[0].innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-narrow-left" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M5 12l14 0"></path>
            <path d="M5 12l4 4"></path>
            <path d="M5 12l4 -4"></path>
         </svg>`;
      }
    });
  };

  const slides = useMemo(() => {
    return listMedia.map((x) => {
      const extensionFile = MediaUtils.getExtension(x.url);
      if (["mp4", "mov"].includes(extensionFile)) {
        return {
          width: 1280,
          height: 720,
          type: "video" as const,
          sources: [
            {
              src: x.url,
              type: `video/${extensionFile}`,
            },
          ],
          title: x.title,
        };
      }
      return {
        src: x.url,
        title: x.title,
      };
    });
  }, [listMedia]);

  return (
    <Lightbox
      open={open}
      // plugins={[Video]}
      close={() => close && close()}
      slides={slides as any}
      render={{
        slide: ({ slide }: any) => {
          return (
            <>
              <div className={slide.type === "video" ? "lightbox-slide video" : "lightbox-slide"}>
                {slide.type === "video" ? (
                  <video
                    controls
                    width={titleRef.current ? slide.width - ((titleRef.current.clientHeight + 16) * slide.width / slide.height) : slide.width}
                    height={titleRef.current ? slide.height - titleRef.current.offsetHeight - 16 : slide.height}
                    className="lightbox-media"
                  >
                    {slide.sources.map((source: any, idx: any) => (
                      <source key={idx} src={source.src} type={source.type} />
                    ))}
                  </video>
                ) : (
                  <img
                    src={slide.src}
                    alt={slide.title || ""}
                    className="lightbox-media"
                  />
                )}

              </div>
              {slide.title && (
                <div ref={titleRef} className="lightbox-title">
                  <pre>{slide.title}</pre>
                </div>
              )}
            </>
          );
        },
      }}
      index={index}
      video={{
        controls: true,
      }}
      className={
        (open ? "custom-lightbox" : "") +
        (slides.length === 1 ? " yarl__sigle" : "")
      }
    />
  );
};

export default AppLightbox;
