import { Button, Divider, Dropdown, Input, Modal } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { SocialApi } from "../../../../../../apis/social.api";
import { ColorTag } from "../../../../../../constants/app.enums";
import useClient from "../../../../../../hooks/useClient";
import useService from "../../../../../../hooks/useService";
import { TagModel } from "../../../../../../models/content.model";
import { listTagState } from "../../../../../../states/social";
import SVGIcons from "../../../../../icons/svgs";
import TagSocial from "../tag-social";
import "./index.scss";

interface AddTagProps {
  onSelectTag: (tagSelect: TagModel, isDelete?: boolean) => void;
}

const AddTag = (props: AddTagProps) => {
  const { onSelectTag } = props;
  const { t } = useTranslation();
  const { clientId } = useClient();
  const { SocialService } = useService();
  const [mode, setMode] = useState<"add" | "normal" | "edit">("normal");
  const [colorSelected, setColorSelected] = useState<string>(ColorTag.Blue);
  const [searchInput, setSearchInput] = useState<string>("");
  const [errorText, setErrorText] = useState<string>("");
  const [tagEdit, setTagEdit] = useState<TagModel>();
  const [loading, setLoading] = useState(false);

  const listTag = useRecoilValue(listTagState);

  const onChangeSearch = (e: any) => {
    const text = e.target.value;
    setSearchInput(text);
    setErrorText("");
  };

  const createTag = async () => {
    if (!searchInput) return setErrorText(t("social.content.tagNameRequired"));

    if (mode === "add") {
      if (listTag.find((tag) => tag.name === searchInput))
        return setErrorText(t("social.content.tagNameExist"));
      setLoading(true);
      try {
        await SocialApi.createTag({
          name: searchInput,
          color: colorSelected,
          backgroundColor: "",
          clientId,
        });
        clientId && (await SocialService.getListTag(clientId));
      } catch (error) {}
      setLoading(false);
      back();
    }
    if (mode === "edit") {
      if (
        listTag.find(
          (tag) => tag.name !== tagEdit?.name && tag.name === searchInput
        )
      )
        return setErrorText(t("social.content.tagNameExist"));
      if (tagEdit) {
        setLoading(true);
        try {
          await SocialApi.editTag({
            id: tagEdit?.id.toString(),
            name: searchInput,
            color: colorSelected,
            clientId,
          });
          clientId && (await SocialService.getListTag(clientId));
          onSelectTag(tagEdit, true);
        } catch (error) {}
        setLoading(false);
      }
      back();
    }
  };

  const removeTag = async (tag: TagModel) => {
    await SocialApi.removeTag(tag.id);
    clientId && (await SocialService.getListTag(clientId));
    onSelectTag(tag, true);
  };

  const back = () => {
    setMode("normal");
    setSearchInput("");
    setErrorText("");
  };

  const editTag = (tag: TagModel) => {
    setMode("edit");
    setTagEdit(tag);
    setSearchInput(tag.name);
    setColorSelected(tag.color);
  };

  const modalRemoveTag = (tag: TagModel) => {
    Modal.confirm({
      title: t("social.content.deleteTagTitle", { name: tag.name }),
      content: (
        <span
          dangerouslySetInnerHTML={{
            __html: t("social.content.deleteTagDescription"),
          }}
        ></span>
      ),
      okText: t("common.delete"),
      cancelText: t("common.cancel"),
      cancelButtonProps: { type: "text" },
      icon: "",
      className: "confirm-modal confirm-delete-modal confirm-delete-tag-modal",
      centered: true,
      onOk: () => removeTag(tag),
    });
  };

  const RenderAddTagDropdown = (): React.ReactElement => {
    const currentTag = listTag.filter((tag) => tag.name.includes(searchInput));
    return (
      <div className="add-tag-dropdown">
        <div className="search-tag">
          <Input
            placeholder={
              mode === "normal"
                ? t("social.content.searchTag")
                : t("social.content.tagName")
            }
            onChange={onChangeSearch}
            value={searchInput}
          ></Input>
        </div>
        {mode === "normal" ? (
          <>
            <div className="list-tag">
              {currentTag.length > 0
                ? currentTag.map((tag) => {
                    return (
                      <div className="list-tag-item" key={tag.name}>
                        <TagSocial
                          text={tag.name}
                          color={tag.color}
                          size="medium"
                          onClick={() => onSelectTag(tag)}
                        />
                        <span onClick={() => modalRemoveTag(tag)}>
                          <SVGIcons.TrashIcon />
                        </span>
                        <span onClick={() => editTag(tag)}>
                          <SVGIcons.EditIcon />
                        </span>
                      </div>
                    );
                  })
                : searchInput && (
                    <div className="no-tag">
                      {t("social.content.noTagFound")}
                    </div>
                  )}
            </div>
            <Divider />
            <div className="add-new-tag">
              <Button
                type="text"
                className="color-blue"
                onClick={() => setMode("add")}
              >
                {currentTag.length === 0 && searchInput
                  ? t("social.content.addNewTagFor", { name: searchInput })
                  : t("social.content.addNewTag")}
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="list-color">
              {Object.values(ColorTag).map((color: string) => {
                return (
                  <div
                    className={`color-tag ${
                      colorSelected === color ? "color-tag-selected" : ""
                    }`}
                    style={{ backgroundColor: color }}
                    onClick={() => setColorSelected(color)}
                    key={color}
                  >
                    <SVGIcons.CheckedNotRoundedIcon />
                  </div>
                );
              })}
            </div>
            {errorText && <div className="error-text">{errorText}</div>}
            <div className="group-btn">
              <Button type="text" onClick={back}>
                {t("common.back")}
              </Button>
              <Button type="primary" onClick={createTag} loading={loading}>
                {mode === "add" ? t("common.create") : t("common.save")}
              </Button>
            </div>
          </>
        )}
      </div>
    );
  };

  const onOpenChange = (open: any) => !open && setMode("normal");

  return (
    <Dropdown
      trigger={["click"]}
      onOpenChange={onOpenChange}
      destroyPopupOnHide={true}
      menu={{
        items: [
          {
            label: '',
            key: 'AddTag',
          },
        ],
      }}
      dropdownRender={RenderAddTagDropdown}
    >
      <div className="add-tag-social">
        <SVGIcons.PlusIcon />
        <span>{t("social.content.addTag")}</span>
      </div>
    </Dropdown>
  );
};

export default AddTag;
