import { Button, Divider, Dropdown, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { SocialApi } from "../../../../../../apis/social.api";
import { MAXIMUM_HASHTAG } from "../../../../../../constants/app-constants";
import useClient from "../../../../../../hooks/useClient";
import { HashtagModel } from "../../../../../../models/content.model";
import { listHashtagState } from "../../../../../../states/social";
import SVGIcons from "../../../../../icons/svgs";
import "./index.scss";

interface HashtagProps {
  onUpdateHashTags: (hashtags: string) => void;
}

const HashtagDropdown = (props: HashtagProps) => {
  const { onUpdateHashTags } = props;
  const { clientId } = useClient();
  const { t } = useTranslation();
  const [mode, setMode] = useState<"add" | "normal" | "edit">("normal");
  const [searchInput, setSearchInput] = useState<string>("");
  const [hashtagInput, setHashtagInput] = useState<string>("");
  const [errorText, setErrorText] = useState<string>("");
  const [hashtagEdit, setHashtagEdit] = useState<HashtagModel>();
  const [loading, setLoading] = useState(false);

  const [listHashtag, setListHashtTag] = useRecoilState(listHashtagState);

  const onChangeSearch = (e: any) => {
    const text = e.target.value;
    setSearchInput(text);
    setErrorText("");
  };

  const onChangeHashtag = (e: any) => {
    const text = e.target.value;
    setHashtagInput(text);
  };

  const onChooseHashtag = (hashtag: HashtagModel) => {
    onUpdateHashTags(hashtag.hashTags);
  };

  const createTag = async () => {
    if (!searchInput)
      return setErrorText(t("social.content.hashtagNameRequired"));
    if (mode === "add") {
      if (listHashtag.find((tag) => tag.name === searchInput))
        return setErrorText(t("social.content.hashtagNameExist"));
      setLoading(true);
      try {
        await SocialApi.createHashtag({
          name: searchInput,
          hashTags: hashtagInput,
          clientId,
        });
        await initListHashtag();
      } catch (error) {}
      setLoading(false);
      back();
    }
    if (mode === "edit") {
      if (
        listHashtag.find(
          (hashtag) =>
            hashtag.name !== hashtagEdit?.name && hashtag.name === searchInput
        )
      )
        return setErrorText(t("social.content.hashtagNameExist"));

      if (hashtagEdit) {
        setLoading(true);
        try {
          await SocialApi.editHashtag({
            id: hashtagEdit?.id.toString(),
            name: searchInput,
            hashTags: hashtagInput,
          });
          await initListHashtag();
        } catch (error) {}
        setLoading(false);
      }
      back();
    }
  };

  const removeTag = async (hashtag: HashtagModel) => {
    await SocialApi.removeHashtag(hashtag.id);
    await initListHashtag();
  };

  const back = () => {
    setMode("normal");
    setSearchInput("");
    setHashtagInput("");
    setErrorText("");
  };

  const editTag = (hashtag: HashtagModel) => {
    setMode("edit");
    setHashtagEdit(hashtag);
    setSearchInput(hashtag.name);
    setHashtagInput(hashtag.hashTags);
  };

  const modalRemoveTag = (hashtag: HashtagModel) => {
    Modal.confirm({
      title: t("social.content.deleteHashTagTitle", {
        name: hashtag.name,
      }),
      content: (
        <span
          dangerouslySetInnerHTML={{
            __html: t("social.content.deleteHashTagDescription"),
          }}
        ></span>
      ),
      okText: t("common.delete"),
      cancelText: t("common.cancel"),
      cancelButtonProps: { type: "text" },
      icon: "",
      className: "confirm-modal confirm-delete-modal confirm-delete-tag-modal",
      centered: true,
      onOk: () => removeTag(hashtag),
    });
  };

  const RenderHashtagDropdown = (): React.ReactElement => {
    const currentHashTag = listHashtag.filter((hashtag) =>
      hashtag.name.includes(searchInput)
    );
    return (
      <div className="add-hash-tag-dropdown">
        <div className="search-tag">
          <Input
            placeholder={t(
              mode === "normal"
                ? "social.content.searchHashtag"
                : "social.content.groupName"
            )}
            onChange={onChangeSearch}
            value={searchInput}
          ></Input>
        </div>
        {mode === "normal" ? (
          <>
            <div className="list-hash-tag">
              {currentHashTag.length > 0
                ? currentHashTag.map((hashtag) => {
                    return (
                      <div className="list-hash-tag-item" key={hashtag.id}>
                        <div
                          className="list-hash-tag-item__left"
                          onClick={() => onChooseHashtag(hashtag)}
                        >
                          <div className="group-name">{hashtag.name}</div>
                          <div className="hashtag">{hashtag.hashTags}</div>
                        </div>
                        <span onClick={() => modalRemoveTag(hashtag)}>
                          <SVGIcons.TrashIcon />
                        </span>
                        <span onClick={() => editTag(hashtag)}>
                          <SVGIcons.EditIcon />
                        </span>
                      </div>
                    );
                  })
                : searchInput && (
                    <div className="no-tag">
                      {t("social.content.noHashTagFound")}
                    </div>
                  )}
            </div>
            <Divider />
            <div className="add-new-hash-tag">
              <Button
                type="text"
                className="color-blue"
                onClick={() => setMode("add")}
              >
                {currentHashTag.length === 0 && searchInput
                  ? t("social.content.addNewHashtagFor", { name: searchInput })
                  : t("social.content.addNewHashtag")}
              </Button>
            </div>
          </>
        ) : (
          <>
            <Input.TextArea
              placeholder="Hashtags"
              onChange={onChangeHashtag}
              value={hashtagInput}
            />
            <div className="maximum-hashtag">
              {t("social.content.maximumHashtag", { amount: MAXIMUM_HASHTAG })}
            </div>
            {errorText && <div className="error-text">{errorText}</div>}
            <div className="group-btn">
              <Button type="text" onClick={back}>
                {t("common.back")}
              </Button>
              <Button type="primary" onClick={createTag} loading={loading}>
                {mode === "add" ? t("common.create") : t("common.save")}
              </Button>
            </div>
          </>
        )}
      </div>
    );
  };

  const onOpenChange = (open: any) => !open && setMode("normal");

  const initListHashtag = async () => {
    const { data } = await SocialApi.getListHashtag({ clientId });
    setListHashtTag(data);
  };

  useEffect(() => {
    initListHashtag();
  }, []);

  return (
    <Dropdown
      menu={{
        items: [
          {
            label: '',
            key: 'Hashtag',
          },
        ],
      }}
      trigger={["click"]}
      onOpenChange={onOpenChange}
      destroyPopupOnHide={true}
      dropdownRender={RenderHashtagDropdown}
    >
      <div>
        <SVGIcons.HashIcon />
      </div>
    </Dropdown>
  );
};

export default HashtagDropdown;
