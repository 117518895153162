import { Button, Input, Select, Spin, Tooltip } from 'antd';
import { useState } from 'react';
import useFetchCampaigns from '../../../hooks/useFetchCampaigns';
import { OrgCampaign } from '../../../models/zoho.model';
import SVGIcons from '../../icons/svgs';
import "./index.scss";
import { useTranslation } from 'react-i18next';

interface AppSelectCampaignProps {
    onChange: (values: number) => void;
    value?: number;
    orgId: number;
    disabled?: boolean;
}

const AppSelectCampaign = (props: AppSelectCampaignProps) => {
    const { onChange, value, orgId, disabled } = props
    const [campaign, setSearchCampaign] = useState<string>("");
    const { campaigns, loading, refetch } = useFetchCampaigns(orgId);
    const { t } = useTranslation();

    function displayCampaign(option: OrgCampaign) {
        const { name } = option;
        const indexSearch = name
            .toLocaleLowerCase()
            .indexOf(campaign.toLocaleLowerCase());
        return (
            <div className="campaign__display">
                <div className="campaign__display-name">
                    {name.substring(0, indexSearch)}
                    <span className="campaign__display-highlight">
                        {name.substring(indexSearch, indexSearch + campaign.length)}
                    </span>
                    {name.substring(indexSearch + campaign.length)}
                </div>
            </div>
        );
    }

    const onSearchCampaign = (e: any) => {
        setSearchCampaign(e.target.value);
    };


    function sortCampaigns() {
        const sortedCampaigns = [];
        if (campaigns && campaigns?.length > 0) {
            for (const item of campaigns) {
                if (value) {
                    if (item.id === value) {
                        sortedCampaigns.unshift(item);
                    }
                    else {
                        sortedCampaigns.push(item);
                    }
                }
                else {
                    sortedCampaigns.push(item);
                }
            }
        }
        return sortedCampaigns;

    }

    const getSelectedValue = () => {
        if (value) {
            return campaigns?.find(x => x.id === value)?.id
        }
    }

    return (
        <div className='campaign'>
            <Select
                loading={loading}
                value={getSelectedValue()}
                placeholder={t("salesAnalytics.clientCampaignPlaceholder")}
                className='campaign__select'
                onChange={(value) => onChange(value)}
                disabled={disabled}
                dropdownRender={(menu) => (
                    <>
                        <div className="campaign__search">
                            <Input
                                className='campaign__input'
                                value={campaign}
                                size="small"
                                suffix={
                                    <span className="campaign__icon">
                                        <SVGIcons.SearchIcon />
                                    </span>
                                }
                                onChange={onSearchCampaign}
                                onBlur={() => setSearchCampaign("")}
                            />
                            <Tooltip
                                overlayClassName='custom-tooltip'
                                title={t("salesAnalytics.refreshData")}
                                placement='topRight'
                                align={{offset: [10, 0]}}
                            >
                                <Button
                                    type="default"
                                    className="refresh-btn"
                                    onClick={refetch}
                                >
                                    <SVGIcons.RefreshIcon />
                                </Button>
                            </Tooltip>
                        </div>
                        <Spin spinning={loading}>{menu}</Spin>
                    </>
                )}
            >
                {sortCampaigns()
                    ?.filter((item) =>
                        item.name
                            .toLocaleLowerCase()
                            .includes(campaign.toLocaleLowerCase())
                    )
                    .map((option, index) => {
                        return (
                            <Select.Option
                                value={option.id}
                                key={index}
                            >
                                {displayCampaign(option)}
                            </Select.Option>
                        );
                    })}
            </Select>
        </div>
    )
}

export default AppSelectCampaign