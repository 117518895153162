import moment from "moment";
import { atom, DefaultValue, selector } from "recoil";
import { DATE_TIME_FORMAT } from "../constants/app-constants";
import { APP_STATE_KEYS } from "../constants/app-state.constants";
import {
  SocialType,
  SubmitType,
  ContentErrorType,
  ServiceType,
  PostStatusType,
  ApprovalStatusEnum,
  SocialExtendReelType,
  GmbActionType,
} from "../constants/app.enums";
import {
  GetListContentRequestModel,
  HashtagModel,
  ListContentModel,
  SocialContentModel,
  TagModel,
} from "../models/content.model";
import { NetworkModel } from "../models/social.model";

export interface SocialContentState extends SocialContentModel {
  disabled?: boolean;
  profile?: NetworkModel;
  errors?: ContentErrorType[];
  platformExtendReel: SocialExtendReelType;
  gmbActionUrlError?: string;
}
interface ServiceState {
  isFetched: boolean;
  data: ServiceType[]
}

export interface CreateContentState {
  isSyncContent: boolean;
  platformContents: SocialContentState[];
  tags: TagModel[];
  status: SubmitType | PostStatusType;
  statusPost: PostStatusType;
  scheduleOn?: string;
  publishedDate?: string;
  date?: string;
  createdDate?: string;
  currentSocial: SocialExtendReelType;
  loadingSubmit: boolean;
  errors?: ContentErrorType[];
  id?: number;
  requireApproval: boolean;
  approvalStatus: ApprovalStatusEnum;
  showWriteWithAI?: boolean;
  contentAIGenerated?: string;
  gmbActionUrlError?: string;
  eventPostModalStatus?: "add" | "edit";
}

const contentValueState = atom<CreateContentState>({
  key: APP_STATE_KEYS.CREATE_CONTENT_STATE,
  default: {
    isSyncContent: true,
    tags: [],
    status: SubmitType.Save,
    statusPost: PostStatusType.Drafted,
    currentSocial: SocialType.Facebook,
    loadingSubmit: false,
    scheduleOn: moment().local().format(),
    platformContents: [],
    errors: [],
    requireApproval: false,
    approvalStatus: ApprovalStatusEnum.None,
    showWriteWithAI: false,
    contentAIGenerated: "",
  },
});

interface ListEnableContentState {
  listEnableContent: SocialContentModel[];
}

const listEnableContentState = atom<ListEnableContentState>({
  key: APP_STATE_KEYS.LIST_ENABLE_CONTENT,
  default: {
    listEnableContent: [],
  },
});

const listPlatformContentSelector = selector<SocialContentState[]>({
  key: "listEnableContentSelector",
  get: ({ get }) => {
    const { platformContents } = get(contentValueState);
    return platformContents;
  },
  set: ({ set, get }, newValue) => {
    const state = get(contentValueState);

    let newPlatformContents: SocialContentState[] = [];
    if (newValue && !(newValue instanceof DefaultValue))
      newPlatformContents = [...newValue];

    set(contentValueState, {
      ...state,
      platformContents: [...newPlatformContents],
    });
  },
});

const currentContentSelector = selector<SocialContentState | undefined>({
  key: "currentContentSelector",
  get: ({ get }) => {
    const { currentSocial } = get(contentValueState);
    const listPlatformContent = get(listPlatformContentSelector);
    const currentContent = listPlatformContent.find(
      (content) => content?.platformExtendReel === currentSocial
    );
    return currentContent;
  },
});

const triggerFetchProfilesState = atom<{ fetchCount: number }>({
  key: APP_STATE_KEYS.TRIGGER_FETCH_PROFILES,
  default: {
    fetchCount: 0,
  },
});

const listProfileState = atom<{ profiles: NetworkModel[]; clientId?: number }>({
  key: APP_STATE_KEYS.LIST_PROFILE,
  default: {
    profiles: [],
  },
});

const listProfileSelector = selector<NetworkModel[]>({
  key: "listProfileSelector",
  get: ({ get }) => {
    const { profiles } = get(listProfileState);
    return profiles;
  },
  set: ({ set, get }, newValue) => {
    const listProfile = get(listProfileState);

    let profileUpdated: NetworkModel[] = [];
    if (newValue && !(newValue instanceof DefaultValue)) {
      profileUpdated = newValue;
    }

    set(listProfileState, {
      ...listProfile,
      profiles: profileUpdated,
    });
  },
});

interface ListContentState extends ListContentModel {
  loading: boolean;
  rangeDate: {
    startDate: string;
    endDate: string;
  };
  query: GetListContentRequestModel;
}

const listContentState = atom<ListContentState>({
  key: APP_STATE_KEYS.LIST_CONTENT,
  default: {
    error: 0,
    drafted: 0,
    posted: 0,
    scheduled: 0,
    totalRecords: 0,
    totalPosts: 0,
    records: [],
    query: {
      pageSize: 12,
      pageIndex: 1,
      keyword: "",
    },
    loading: false,
    rangeDate: {
      endDate: moment().format(DATE_TIME_FORMAT.isoDate),
      startDate: moment().add(-1, "months").format(DATE_TIME_FORMAT.isoDate),
    },
  },
});

const listContentCalendarState = atom<ListContentState>({
  key: APP_STATE_KEYS.LIST_CONTENT_CALENDAR,
  default: {
    error: 0,
    drafted: 0,
    posted: 0,
    scheduled: 0,
    totalPosts: 0,
    totalRecords: 0,
    records: [],
    query: {
      pageSize: 12,
      pageIndex: 1,
      keyword: "",
    },
    loading: false,
    rangeDate: {
      endDate: moment().format(DATE_TIME_FORMAT.isoDate),
      startDate: moment().add(-1, "months").format(DATE_TIME_FORMAT.isoDate),
    },
  },
});

const listTagState = atom<TagModel[]>({
  key: APP_STATE_KEYS.LIST_TAG,
  default: [],
});

const listPostTypeState = atom<number[]>({
  key: APP_STATE_KEYS.LIST_POST_TYPE,
  default: [],
});

const listHashtagState = atom<HashtagModel[]>({
  key: APP_STATE_KEYS.LIST_HASH_TAG,
  default: [],
});

const errorsContentSelector = selector<ContentErrorType[]>({
  key: "errorsContentSelector",
  get: ({ get }) => {
    const { platformContents } = get(contentValueState);
    return platformContents.reduce((result: ContentErrorType[], content) => {
      if (!content || content.disabled) return result;
      return [...result, ...(content.errors || [])];
    }, []);
  },
});

const accessServiceState = atom<ServiceState>({
  key: APP_STATE_KEYS.ACCESS_SERVICES,
  default: {
    data: [],
    isFetched: false
  },
});

const requiredGuestUserState = atom<boolean>({
  key: "requiredGuestUser",
  default: false,
});

// contentId or parent comment id
const showCommentMobileState = atom<number | undefined>({
  key: "showCommentMobile",
  default: undefined,
});

// "contentId" or "contentId-parentId"
const listCommentingState = atom<string[]>({
  key: "listCommentingState",
  default: [],
});

const newCommentState = atom<boolean>({
  key: "newComment",
  default: false,
});

const triggerFetchBulkApprovalState = atom<boolean>({
  key: APP_STATE_KEYS.SOCIAL_FETCH_BULK_APPROVAL,
  default: undefined,
});

export {
  contentValueState,
  triggerFetchProfilesState,
  listProfileState,
  listProfileSelector,
  listEnableContentState,
  listContentState,
  listContentCalendarState,
  listTagState,
  listHashtagState,
  listPlatformContentSelector,
  currentContentSelector,
  errorsContentSelector,
  accessServiceState,
  requiredGuestUserState,
  showCommentMobileState,
  listPostTypeState,
  newCommentState,
  listCommentingState,
  triggerFetchBulkApprovalState
};
