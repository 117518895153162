import { Button, Tooltip } from "antd";
import { useRecoilState, useRecoilValue } from "recoil";
import { ErrorContentText } from "../../../../../../constants/app-constants";
import {
  ContentErrorType,
  PlatformStatusType,
  SocialType,
} from "../../../../../../constants/app.enums";
import { isMobileSelector } from "../../../../../../states/common";
import {
  contentValueState,
  currentContentSelector,
  errorsContentSelector,
  listPlatformContentSelector,
  SocialContentState,
} from "../../../../../../states/social";
import SVGIcons from "../../../../../icons/svgs";
import "./index.scss";

export const contentTabRender = (
  socialType?: SocialType,
  isReels?: boolean
) => {
  switch (socialType) {
    case SocialType.Facebook:
      return (
        <span>
          <SVGIcons.FacebookImage />
          {isReels ? "Reel" : "Post"}
        </span>
      );
    case SocialType.Instagram:
      return (
        <span>
          <SVGIcons.InstagramImage />
          {isReels ? "Reel" : "Post"}
        </span>
      );
    case SocialType.Tiktok:
      return (
        <span>
          <SVGIcons.TiktokImage />
          Video
        </span>
      );
    case SocialType.Youtube:
      return (
        <span>
          <SVGIcons.YoutubeImage />
          {isReels ? "Short" : "Post"}
        </span>
      );
    case SocialType.Google:
      return (
        <span>
          <SVGIcons.GoogleImage />
          Post
        </span>
      );
    case SocialType.LinkedIn:
      return (
        <span>
          <SVGIcons.LinkedInImage />
          Post
        </span>
      );
    default:
      return "";
  }
};

interface Props {
  isReviewing?: boolean;
}
const ContentTab = (props: Props) => {
  const { isReviewing } = props;
  const isMobile = useRecoilValue(isMobileSelector);

  const errorsContent = useRecoilValue(errorsContentSelector);
  const listPlatformContent = useRecoilValue(listPlatformContentSelector);
  const currentContent = useRecoilValue(currentContentSelector);
  const [contentValue, setContentValue] = useRecoilState(contentValueState);
  const { isSyncContent, currentSocial } = contentValue;

  const onChangeTab = (value: number) => {
    setContentValue({ ...contentValue, currentSocial: value });
  };
  const getTitleWarningLimit = (content?: SocialContentState) => {
    const errors = content?.errors;
    // warning when publish error
    if (
      !errors?.length &&
      !errorsContent.length &&
      (content || currentContent)?.status === PlatformStatusType.Error
    )
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: (content || currentContent)?.errorMessage || "",
          }}
        ></span>
      );
    // warning when validate content
    let newErrorsContent: ContentErrorType[] = [];
    if (!isSyncContent) {
      newErrorsContent = errors || [];
    } else {
      newErrorsContent = [...errorsContent];
      if (
        newErrorsContent.some((error) =>
          [
            ContentErrorType.RequiredPostFB,
            ContentErrorType.RequiredPostInsta,
            ContentErrorType.RequiredPostTiktok,
          ].includes(error)
        )
      ) {
        newErrorsContent = newErrorsContent.filter(
          (error) =>
            ![
              ContentErrorType.RequiredPostFB,
              ContentErrorType.RequiredPostInsta,
              ContentErrorType.RequiredPostTiktok,
            ].includes(error)
        );
        newErrorsContent = [ContentErrorType.RequiredPost, ...newErrorsContent];
      }
    }
    if (!newErrorsContent.length) return "";
    return (
      <div>
        {newErrorsContent.map((errorType) => {
          return <div key={errorType}>{ErrorContentText[errorType]}</div>;
        })}
      </div>
    );
  };

  if (currentContent?.platform === undefined) return <></>;
  if (isSyncContent) return <></>;

  if (isReviewing) {
    if (
      ![SocialType.Facebook, SocialType.Instagram].includes(
        currentContent.platform
      )
    )
      return <></>;

    const reelContent = listPlatformContent.find(
      (c) => c.isReels && c.platform === currentContent.platform
    );
    if (!reelContent?.post && !reelContent?.medias?.length) return <></>;

    if (isMobile) {
      return (
        <div className="content-editor-tab-mobile">
          <Button
            type="default"
            className={`tab-post ${!currentContent.isReels ? "active" : ""}`}
            onClick={() =>
              setContentValue({
                ...contentValue,
                currentSocial: currentContent.platform,
              })
            }
          >
            Post
          </Button>
          <Button
            type="default"
            className={`tab-reel ${currentContent.isReels ? "active" : ""}`}
            onClick={() =>
              setContentValue({
                ...contentValue,
                currentSocial: currentContent.platform + 10,
              })
            }
          >
            Reel
          </Button>
        </div>
      );
    }
  }

  return (
    <div className="content-editor-tab">
      {listPlatformContent
        .filter((content) => {
          return !content?.disabled;
        })
        .map((content: SocialContentState, index) => {
          return (
            <Tooltip title={getTitleWarningLimit(content)} placement="top" key={index}>
              <Button
                onClick={() => onChangeTab(content.platformExtendReel)}
                className={`${
                  currentSocial === content.platformExtendReel ? "selected" : ""
                }`}
              >
                {contentTabRender(content?.platform, content?.isReels)}
                {getTitleWarningLimit(content) && <SVGIcons.AlertCircle />}
              </Button>
            </Tooltip>
          );
        })}
    </div>
  );
};

export default ContentTab;
