export const AdminPrefixRouter = "/admin";
export const AdminViewSitePrefixRouter = `${AdminPrefixRouter}/view-site`;

export const ROUTE_PATHS = {
  Home: "/",
  SalesAnalytics: "/sales-analytics",
  SignIn: "/sign-in",
  SignUp: "/sign-up",
  ForgotPassword: "/forgot-password",
  ResetPassword: "/reset-password",
  UpdatePassword: "/update-password",
  UpdatePasswordSuccess: "/update-password-success",
  CreateMemberSuccess: "/create-member-success",
  AccountVerification: "/account-verification",
  EmailVerification: "/verify-email",
  Contacts: "/contacts",
  Listings: "/listings",
  Inbox: "/inbox",
  Reviews: "/reviews",
  GeneralSettings: "/general-settings",
  AddProfiles: "/add-profiles",
  Social: {
    Dashboard: "/social/dashboard",
    Calendar: "/social/calendar",
    Content: "/social/content",
    Approvals: "/social/approvals",
    ConnectProfiles: "/social/connect-profiles",
    ConnectedProfilesSuccess: "/social/connected-profiles-success",
    ReviewContent: "/social/review-content",
    ApprovalRequest: "/social/approval-request",
    OverViewTab: "/social/dashboard?tab=overview",
  },
  TeamInvitation: "/team-invitation/:memberId",
  SelectTeam: "/select-team",
  SelectCompany: "/select-company",
  ShortLink: "/shrt/:code",
  Reports: "/reports",
  UsageAndBilling: "/usage-and-billing",
  MediaLibrary: "/media-library",
  ZohoReturn: "/zoho/return",

  Stripe: "/stripe",

  Docs: {
    SetUpDashboard: 'https://docs.rocketlevel.com/dashboard/set-up-insights-hub-dashboard',
    ConnectZohoCrm: 'https://docs.rocketlevel.com/dashboard/set-up-sales-analytics-dashboard'
  },

  //admin
  AdminHome: AdminPrefixRouter,
  AdminSignIn: `${AdminPrefixRouter}/sign-in`,
  ClientSite: `${AdminPrefixRouter}/client-sites`,
  UserList: `${AdminPrefixRouter}/users`,
  UserGroups: `${AdminPrefixRouter}/user-groups`,
  RolePermission: `${AdminPrefixRouter}/role-and-permission`,
  AdminChangePassword: `${AdminPrefixRouter}/change-password`,
  AdminUpdatePassword: `${AdminPrefixRouter}/update-password`,
  AdminUpdatePasswordSuccess: `${AdminPrefixRouter}/update-password-success`,
  AdminForgotPassword: `${AdminPrefixRouter}/forgot-password`,
  AdminResetPassword: `${AdminPrefixRouter}/reset-password`,
  AdminZohoReturn: `${AdminPrefixRouter}/zoho/return`,

  //Error
  ClientNotFound: `/404`,
  AdminNotFound: `${AdminPrefixRouter}/404`,
  NotFound: "*",
};
