import {
    Button,
    Collapse,
    Drawer,
    Switch,
    Tooltip
} from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthApi } from "../../../../apis/auth.api";
import { BoardType, ShouldOpenDrawer } from "../../../../constants/app.enums";
import useClient from "../../../../hooks/useClient";
import useZoho from "../../../../hooks/useZoho";
import SaleAnalyticIntegrationCollapse from "../../../common/integration-collapse/sale-analytic-integration";
import showNotification from "../../../common/notification";
import SVGIcons from "../../../icons/svgs";
import "./index.scss";
import { ROUTE_PATHS } from "../../../../constants/router.constants";

type Props = {
    isShow: boolean;
    setIsShow: (show: boolean) => void;
}

const { Panel } = Collapse;

const enum EditSalesAnalyticsDashboardKey {
    DashboardSettings = "0"
}

function EditSalesAnalyticsDashboard(props: Props) {
    const { isShow, setIsShow } = props;
    const { t } = useTranslation();
    const [activeKey, setActiveKey] = useState([]);
    const [showSalesTrendIndicator, setShowSalesTrendIndicator] = useState(false);
    const { client, setClient, clientId } = useClient();
    const [loading, setLoading] = useState(false);
    const { connectZoho, connectZohoLoading, zohoClients } = useZoho();

    const onChange = (value: any) => {
        setActiveKey(value);
    };

    useEffect(() => {
        setShowSalesTrendIndicator(client?.showSalesTrendIndicator || false);
    }, [client]);

    const onSave = async () => {
        if (!client) return;
        try {
            setLoading(true);
            await AuthApi.saveDashboardSettings(client?.id, BoardType.SALES_ANALYTICS, showSalesTrendIndicator);
            if (client) {
                setClient({
                    ...client,
                    showSalesTrendIndicator: showSalesTrendIndicator
                });
                showNotification("success", t(`common.yourChangesSaveSuccess`));
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Drawer
            className="app-edit-drawer"
            title={t("salesAnalytics.editDashboardSalesAnalytics")}
            open={isShow}
            width={540}
            onClose={() => setIsShow(false)}
            maskClosable={false}
            destroyOnClose={true}
            footer={
                <div className="drawer-footer-wrapper">
                    <Button type="text" onClick={() => setIsShow(false)}>
                        {t("clientSites.btnClose")}
                    </Button>
                </div>
            }
        >
            <div className="edit-drawer-content">
                <p className="description">{t("salesAnalytics.manageDashboardSettings")}
                    {" "}
                    <a className="learnMore" target="_blank" rel="noreferrer" href={ROUTE_PATHS.Docs.ConnectZohoCrm}>
                        {t("salesAnalytics.learnMore")}
                    </a>
                </p>

                <Collapse
                    className="site-collapse-custom-collapse"
                    bordered={false}
                    expandIconPosition={"end"}
                    activeKey={activeKey}
                    onChange={onChange}
                >
                    <Panel
                        header={
                            <>
                                <div className="title">
                                    {t("salesAnalytics.dashboardSettings")}
                                </div>
                                <div className="description">
                                    {t("salesAnalytics.adjustTheKey")}
                                </div>

                            </>
                        }
                        key={EditSalesAnalyticsDashboardKey.DashboardSettings}
                    >
                        <div className="trend-indicators" >
                            <div className="wrapper">
                                <div className="left" >
                                    <div>{t("generalSettings.trendIndicators")}</div>
                                    <Tooltip
                                        overlayClassName="custom-tooltip"
                                        placement="top"
                                        title={t("generalSettings.trendIndicatorTooltip")}
                                    >
                                        <div className="icon">
                                            <span>?</span>
                                        </div>
                                    </Tooltip>
                                </div>
                                <div>
                                    <Switch
                                        checked={showSalesTrendIndicator}
                                        onChange={(value) => setShowSalesTrendIndicator(value)}
                                    />
                                </div>
                            </div>
                            <div className="saveBtn" >
                                <Button
                                    loading={loading}
                                    onClick={onSave}
                                    className="save-btn primary-btn"
                                    disabled={showSalesTrendIndicator === client?.showSalesTrendIndicator}
                                    type="primary">
                                    {t("common.save")}
                                </Button>
                            </div>
                        </div>
                    </Panel>
                </Collapse>
                {
                    clientId && <SaleAnalyticIntegrationCollapse isSalesAnalytics={true} clientId={clientId} />
                }
                <Button disabled={zohoClients && zohoClients.length >= 3} loading={connectZohoLoading} onClick={() => connectZoho(false, undefined, ShouldOpenDrawer.SalesAnalyticsDashboard)}  >
                    <SVGIcons.ZohoIcon /> {t("salesAnalytics.connectAccount")}
                </Button>
            </div>
        </Drawer>
    );
}

export default EditSalesAnalyticsDashboard;
