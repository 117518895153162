import { atom } from "recoil";
import { APP_STATE_KEYS } from "../constants/app-state.constants";
import {
  BookingCountModel,
  CallCountModel,
  CallResultTypeModel,
  CallStatusReportItemModel,
  ClientAccess,
  ConnectedBookingDateStateModel,
  ConnectionRateStateModel,
  OrgCampaign,
  RecentBookedDemoModel,
  TotalCallsModel,
  TotalDemoBookedModel,
  TotalLeadWorkedModel,
  ZohoClientInfo,
  ZohoReport,
  ZohoReportCard,
} from "../models/zoho.model";


interface TotalLeadWorkedState {
  loading: boolean;
  data: TotalLeadWorkedModel | undefined;
}

interface ConnectedBookingDateState {
  loading: boolean;
  data: ConnectedBookingDateStateModel[] | undefined;
}

interface CallStatusState {
  loading: boolean;
  data: {
    callResultTypes: CallResultTypeModel[];
    reportItem: CallStatusReportItemModel[];
  } | undefined;
}

interface RecentBookedDemoState {
  loading: boolean;
  data: RecentBookedDemoModel[] | undefined;
}

interface ReportCardState {
  loading: boolean;
  data: ZohoReportCard[] | undefined;
}
interface TotalCallState {
  loading: boolean;
  data: TotalCallsModel | undefined;
}

interface ConnectionRateState {
  loading: boolean;
  data: ConnectionRateStateModel[] | undefined;
}
interface TotalDemoBookedState {
  loading: boolean;
  data: TotalDemoBookedModel | undefined;
}

interface ConnectedState {
  isConnected: boolean;
}

interface ZohoStateModel {
  triggerReloadReports: number; 
}

// V2
interface ZohoReportsCardState {
  loading: boolean;
  data: ZohoReport[] | undefined;
}

const saleAnalyticZohoState = atom<ZohoStateModel>({
  key: APP_STATE_KEYS.ZOHO,
  default: {
    triggerReloadReports: 0,
  },
});


const saleAnalyticsTotalLeadWorkedState = atom<TotalLeadWorkedState>({
  key: APP_STATE_KEYS.SALES_ANALYTICS_TOTAL_LEAD_WORKED,
  default: {
    data: undefined,
    loading: false,
  },
});

const saleAnalyticsConnectedBookingDateState = atom<ConnectedBookingDateState>({
  key: APP_STATE_KEYS.SALES_ANALYTICS_CONNECTED_BOOKING_DATE,
  default: {
    data: undefined,
    loading: false,
  },
});

const saleAnalyticsCallStatusState = atom<CallStatusState>({
  key: APP_STATE_KEYS.SALES_ANALYTICS_CALL_STATUS,
  default: {
    data: undefined,
    loading: false,
  },
});

const saleAnalyticsRecentBookedDemoState = atom<RecentBookedDemoState>({
  key: APP_STATE_KEYS.SALES_ANALYTICS_RECENT_BOOKED_DEMO,
  default: {
    data: undefined,
    loading: false,
  },
});

const saleAnalyticsTotalCallsState = atom<TotalCallState>({
  key: APP_STATE_KEYS.SALES_ANALYTICS_TOTAL_CALLS,
  default: {
    data: undefined,
    loading: false,
  },
});

const saleAnalyticsTotalDemoBookedState = atom<TotalDemoBookedState>({
  key: APP_STATE_KEYS.SALES_ANALYTICS_TOTAL_DEMO_BOOKED,
  default: {
    data: undefined,
    loading: false,
  },
});

const saleAnalyticsConnectionRateState = atom<ConnectionRateState>({
  key: APP_STATE_KEYS.SALES_ANALYTICS_CONNECTION_RATE,
  default: {
    data: undefined,
    loading: false,
  },
});

const saleAnalyticsDateRangeState = atom({
  key: APP_STATE_KEYS.SALES_ANALYTICS_DATE_RANGE_STATE,
  default: {
    startDate: "",
    endDate: "",
    totalDays: 0,
    value: "",
  },
});

const zohoClientsState = atom<ZohoClientInfo[]>({
  key: APP_STATE_KEYS.ZOHO_CLIENTS_STATE,
  default: [],
});

const reportCardState = atom<ReportCardState>({
  key: APP_STATE_KEYS.ZOHO_REPORT_CARD,
  default: {
    data: undefined,
    loading: true,
  },
});

const connectedState = atom<ConnectedState>({
  key: APP_STATE_KEYS.ZOHO_CONNECTED,
  default: {
    isConnected: false,
  },
});

const clientAccessState = atom<ClientAccess | undefined>({
  key: APP_STATE_KEYS.ZOHO_CLIENT_ACCESS,
  default: {
    zohoClientId: undefined,
    zohoOrgId: undefined,
  },
});

// V2
const zohoReportsCardState = atom<ZohoReportsCardState>({
  key: APP_STATE_KEYS.ZOHO_REPORTS_CARD,
  default: {
    data: undefined,
    loading: false,
  },
});


export {
  saleAnalyticsDateRangeState,
  saleAnalyticsCallStatusState,
  saleAnalyticsTotalLeadWorkedState,
  saleAnalyticsTotalCallsState,
  saleAnalyticsTotalDemoBookedState,
  saleAnalyticsConnectionRateState,
  saleAnalyticsConnectedBookingDateState,
  connectedState,
  reportCardState,
  saleAnalyticZohoState,
  zohoClientsState,
  clientAccessState,
  zohoReportsCardState,
  saleAnalyticsRecentBookedDemoState
};
