import React from "react";
import useClient from "../../../hooks/useClient";
import SVGIcons from "../../icons/svgs";
import { BoardType } from "../../../constants/app.enums";

const Volatility: React.FC<{ value: number | undefined, boardType?: BoardType }> = ({ value, boardType }) => {
  value = value ?? 0;
  const { client } = useClient();

  if (boardType === BoardType.INSIGHTS_HUB && !client?.showInsigntTrendIndicator) {
    return null;
  }
  
  if (boardType === BoardType.SALES_ANALYTICS && !client?.showSalesTrendIndicator) {
    return null;
  }

  if (Math.floor(value) > 0) {
    return (
      <span className="text-success">
        {Math.floor(value)}% <SVGIcons.TrendUpIcon />
      </span>
    );
  }

  if (Math.floor(value) === 0) {
    return (
      <span className="text-warning">
        0% <SVGIcons.MinusIcon />
      </span>
    );
  }

  if (Math.floor(value) < 0) {
    return (
      <span className="text-error">
        {Math.floor(0 - value)}% <SVGIcons.TrenDownIcon />
      </span>
    );
  }

  return null;
};

export default Volatility;
