import { Badge, Button, Modal, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { SocialApi } from "../../../../../apis/social.api";
import { PermissionSocial, SocialType } from "../../../../../constants/app.enums";
import { ROUTE_PATHS } from "../../../../../constants/router.constants";
import useClient from "../../../../../hooks/useClient";
import useClientPlan from "../../../../../hooks/useClientPlan";
import useSocial from "../../../../../hooks/useSocial";
import { NetworkModel } from "../../../../../models/social.model";
import { listProfileSelector } from "../../../../../states/social";
import { CommonUtils } from "../../../../../utils/common";
import SocialUtils from "../../../../../utils/social";
import showNotification from "../../../../common/notification";
import SVGIcons from "../../../../icons/svgs";
import AvatarSocial from "../../social-content/components/avatar-social";
import "./index.scss";

const SocialProfilesTable = () => {
  const { t } = useTranslation();
  const { checkEndedTrialPlan } = useClientPlan()

  const { clientId, hasPermissionSocial } = useClient();
  const { connectProfile, loading, loadingConnect, retrieveProfiles } =
    useSocial();

  const [openModal, setOpenModal] = useState(false);
  const [isCopyLink, setIsCopyLink] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [listProfile, setListProfile] = useRecoilState(listProfileSelector);
  const [getConnectLinkLoading, setConnectLinkLoading] = useState(false);
  const [connectLink, setConnectLink] = useState("");
  const [reauthLoading, setReauthloading] = useState<number[]>([]);
  const [searchParams] = useSearchParams();
  const isResyncSearch = searchParams.get("isResync") === "true";

  useEffect(() => {
    if (!isResyncSearch) {
      retrieveProfiles();
    }
  }, [])

  const reauth = async (id: number) => {
    setReauthloading(pre => {
      pre.push(id);
      return pre;
    })

    await connectProfile();

    setReauthloading(pre => {
      return pre.filter(x => x !== id);
    })
  }

  const remove = (profile: NetworkModel) => {
    const { id, platform } = profile;
    Modal.confirm({
      title: t("social.setting.removeProfileModalTitle", {
        platform: SocialUtils.getSocialName(platform),
      }),
      content: (
        <div className="remove-profile-modal-content">
          <p>{t("social.setting.removeProfileModalContent1")}</p>
          <div className="warning">
            <div>
              <SVGIcons.AlertTriangleFillIcon />
              {t("social.setting.removeProfileModalWarning1")}
            </div>
            <div>
              <SVGIcons.AlertTriangleFillIcon />
              {t("social.setting.removeProfileModalWarning3")}
            </div>
          </div>
          <p>{t("social.setting.removeProfileModalContent2")}</p>
        </div>
      ),
      okText: t("common.remove"),
      cancelText: t("common.cancel"),
      cancelButtonProps: { type: "text" },
      icon: "",
      className: "confirm-modal confirm-delete-modal",
      centered: true,
      onOk: () => removeProfile(id),
    });
  };

  const removeProfile = async (id: number) => {
    setLoadingTable(true);
    try {
      const profile = listProfile?.find((x) => x.id === id);
      if (!profile) {
        setLoadingTable(false);
        return;
      }
      await SocialApi.deletePlatform(id, clientId);
      const newProfiles = listProfile?.filter((x) => x.id !== id);
      setListProfile(newProfiles || []);

      const platform = SocialUtils.getSocialName(profile?.platform);
      showNotification(
        "delete",
        t("social.setting.removeProfileWarning", { platform: platform })
      );
    } catch (error) { }
    setLoadingTable(false);
  };

  const getLinkToConnect = async () => {
    setConnectLinkLoading(true);
    try {
      const res = await SocialApi.getConnectProfileCode(clientId);
      const url = `${window.location.origin}${ROUTE_PATHS.Social.ConnectProfiles}?confirmationCode=${res.data}`;
      const shortenLink = await CommonUtils.shortenUrl({
        url
      });
      if (shortenLink) {
        setConnectLink(
          shortenLink
        );
      } else {
        setConnectLink(
          url
        );
      }

      setOpenModal(true);
    } catch { }
    setConnectLinkLoading(false);
  };

  const copyLink = () => {
    navigator.clipboard.writeText(connectLink);
    setIsCopyLink(true);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "50%",
      colSpan: 1,
      render: (_: any, record: NetworkModel) => {
        const { name, platform, userImage, profileUrl } = record;
        return (
          <div
            className="column-name-setting"
            onClick={() => window.open(profileUrl)}
            key={name}
          >
            <div className="avatar">
              <AvatarSocial avatar={userImage} socialType={platform} />
            </div>
            <div className="name">{name}</div>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "25%",
      render: (_: any, record: NetworkModel) => {
        return (
          record.isError ?
            <div className="column-status-setting">
              <Badge color="#f59f00" text="Error" />
              <Tooltip title={"Trouble connecting this profile. Please re-authorize."} placement="right">
                <div className="warning-limit">
                  <SVGIcons.AlertCircle />
                </div>
              </Tooltip>
            </div> :
            <div className="column-status-setting">
              <Badge color="#9bc13c" text="Connected" />
            </div>
        );
      },
    },
    {
      key: "action",
      width: "25%",
      render: (_: any, record: NetworkModel) => {
        return (
          hasPermissionSocial(PermissionSocial.CanManage) ?
            <div className="group-action-setting">
              <Button
                type="text"
                className="color-blue"
                onClick={() => {
                  checkEndedTrialPlan(() => {
                    reauth(record.id)
                  });
                }}
                loading={reauthLoading && reauthLoading.includes(record.id)}
              >
                <SVGIcons.KeyIcon /> {t("social.setting.reAuthorize")}
              </Button>

              <Button
                type="text"
                className="color-sunset"
                onClick={() => remove(record)}
                loading={loadingTable}
              >
                <SVGIcons.TrashIcon /> {t("social.setting.remove")}
              </Button>
            </div> : <></>
        );
      },
    },
  ];
  return (
    <div className="social-profiles-table">
      <Table
        className="main-table"
        columns={columns}
        dataSource={listProfile}
        pagination={false}
        loading={loadingTable || loading}
        rowKey="id"
        locale={{ emptyText: "No profiles connected yet." }}
      />
      <div className="add-profile">
        <Button
          type="default"
          onClick={getLinkToConnect}
          loading={getConnectLinkLoading}
          disabled={!hasPermissionSocial(PermissionSocial.CanManage)}
        >
          <SVGIcons.LinkIcon /> {t("social.setting.getLinkToConnect")}
        </Button>
        {listProfile.length < Object.keys(SocialType).filter(key => isNaN(Number(key))).length && (
          <Button
            className="save-btn"
            type="primary"
            onClick={() => {
              checkEndedTrialPlan(() => {
                connectProfile()
              });
            }}
            loading={loadingConnect}
            disabled={!hasPermissionSocial(PermissionSocial.CanManage)}
          >
            <SVGIcons.PlusIcon /> {t("social.setting.addProfileBtn")}
          </Button>
        )}
      </div>

      <Modal
        open={openModal}
        title={t(`social.setting.getLinkToConnect`)}
        className="custom-move-action-modal custom-create-modal"
        onCancel={() => setOpenModal(false)}
        footer={null}
      >
        <div className="modal-get-link-connect">
          <p>{t(`social.setting.getLinkModalDescription`)}</p>
          <div className="get-link-block">
            <Button className="btn-link-icon">
              <SVGIcons.LinkIcon />
            </Button>
            <Button className="btn-link-text">{connectLink}</Button>
            <Tooltip title={isCopyLink ? "Link copied!" : "Copy link to share"}>
              <Button type="primary" className="btn-copy" onClick={copyLink}>
                <SVGIcons.CopyIcon /> {t("common.copy")}
              </Button>
            </Tooltip>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SocialProfilesTable;
