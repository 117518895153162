import { Button, Form, Modal, Switch } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { ZohoApi } from "../../../../../apis/zoho.api";
import { IntegrationStatus, ZClientConnectStatus, ZohoSyncStatus } from "../../../../../constants/app.enums";
import useAdminSite from "../../../../../hooks/useAdminSite";
import useClient from "../../../../../hooks/useClient";
import useZoho from "../../../../../hooks/useZoho";
import { ZohoClientInfo } from "../../../../../models/zoho.model";
import { clientSitesState } from "../../../../../states/clientSites";
import { saleAnalyticZohoState } from "../../../../../states/zoho";
import DateUtils from "../../../../../utils/date.utils";
import showNotification from "../../../../common/notification";
import AppSelectCampaign from "../../../../controls/app-select-campaign";
import IntegrationAlert from "../alert";
import "./index.scss";

interface Props {
  accountInfo: ZohoClientInfo;
  isSalesAnalytics?: boolean;
}

function ZohoCRMPanel(props: Props) {
  const { accountInfo, isSalesAnalytics } = props;
  const { t } = useTranslation();
  const { timezoneId, client, isAdminViewSite } = useClient();
  const [form] = Form.useForm();
  const [saveLoading, setSaveLoading] = useState(false);
  const [campaignId, setCampaignId] = useState<number>();

  const {
    checkConnectedZoho,
    checkConnectedLoading,
    connectZoho,
    connectZohoLoading,
    status,
    setStatus,
    getZohoClient,
    zohoClients,
    setZohoClients,
  } = useZoho();
  const { clientSelected } = useRecoilValue(clientSitesState);

  const [zohoState, setZohoState] = useRecoilState(saleAnalyticZohoState);
  const isAdmin = useAdminSite();
  const [activate, setActivate] = useState(false);

  async function testConnection() {
    await checkConnectedZoho(accountInfo.zohoClientId);
    await getZohoClient(accountInfo.clientId);
  }

  const removeAccount = () => {
    Modal.confirm({
      title: "You are about to remove Zoho CRM account",
      content: (
        <div className="remove-decs">
          <div>
            You are about to remove <b>{accountInfo.email}</b> from RocketLevel.
          </div>
          <div>What will happen:</div>
          <ul>
            <li>
              Dashboard cards associated with this account will be emptied
            </li>
            <li>All historical data from this account will be lost</li>
          </ul>
          <div>
            The action cannot be undone. Are you sure you want to proceed?{" "}
          </div>
        </div>
      ),
      okText: t("common.removeConnectionBtn"),
      cancelText: t("common.cancel"),
      cancelButtonProps: { type: "text" },
      icon: "",
      className: "confirm-modal confirm-delete-modal remove-account-modal",
      centered: true,
      onOk: async () => {
        try {
          await ZohoApi.deleteZohoClient(accountInfo.zohoClientId, accountInfo.clientId);
          setZohoState({
            ...zohoState,
            triggerReloadReports: zohoState.triggerReloadReports + 1,
          });
        } catch (error) { }
        showNotification(
          "delete",
          t("generalSettings.removedAccountZoho", { email: accountInfo.email })
        );
        getZohoClient(accountInfo.clientId);
      },
    });
  };

  const renderStatus = () => {
    switch (accountInfo.status) {
      case ZClientConnectStatus.Unused:
        return <span>Unused</span>;
      case ZClientConnectStatus.Active:
        return <span className="color-secondary">Active</span>;
      case ZClientConnectStatus.Error:
        return <span className="color-sunset">Error</span>;
      default:
        break;
    }
  };


  const onSyncData = async () => {
    const cId = isAdmin && !isAdminViewSite ? clientSelected?.id : client?.id;
    if (!cId) return;
    try {
      setStatus(ZohoSyncStatus.ZohoSyncing);
      await ZohoApi.syncLatest(cId, accountInfo.orgId);
      await checkPendingSyncJobs(cId);
    } catch (error) {
      setStatus(ZohoSyncStatus.ZohoSynced);
    }
  };

  const checkPendingSyncJobs = (clientId: number) => {
    let attempts = 0;
    let intervalId: NodeJS.Timeout;
    const MAX_ATTEMPTS = 10;
    const DELAY = 15000;

    const request = async () => {
      attempts++;
      try {

        const { data } = await ZohoApi.getPendingSyncJobs(clientId, accountInfo.orgId);

        if (data.connectionFailed) {
          clearInterval(intervalId);
          setStatus(IntegrationStatus.ConnectionZohoLostError);
          return data;
        }

        const allJobsSuccess = data.jobs.every(x => x.status === ZohoSyncStatus.SUCCESS);

        if (allJobsSuccess) {
          clearInterval(intervalId);

          setZohoState({
            ...zohoState,
            triggerReloadReports: zohoState.triggerReloadReports + 1,
          });

          if (data.latestJob.status === ZohoSyncStatus.FAILED) {
            setStatus(ZohoSyncStatus.ZohoSyncFailed);
          } else {
            setStatus(ZohoSyncStatus.ZohoSynced);
          }
          return data;
        }

        if (
          data.jobs.filter(x => x.status === ZohoSyncStatus.NEW).length > 0 ||
          data.jobs.filter(x => x.status === ZohoSyncStatus.RUNNING).length > 0
        ) {
          setStatus(ZohoSyncStatus.ZohoSyncing);
          if (attempts >= MAX_ATTEMPTS) {
            clearInterval(intervalId);
            setStatus(IntegrationStatus.None);
            return data;
          }
        }
      } catch (error) {
        clearInterval(intervalId);
        setStatus(IntegrationStatus.None);
        throw error;
      }
    };

    const promise = request();

    intervalId = setInterval(request, DELAY);

    return promise;
  };

  const handleSubmit = async () => {
    const { campaignId } = form.getFieldsValue();

    const activeZohoClients = zohoClients.filter((zohoClient) => zohoClient.isActive && zohoClient.zohoClientId !== accountInfo.zohoClientId);
    if (activate && activeZohoClients.length >= 1) {
      showNotification("error", t("salesAnalytics.oneZohoCRMCanBeActiveMsg"));
      return;
    }

    try {
      setSaveLoading(true)
      await ZohoApi.saveCampaign(accountInfo.clientId, accountInfo.zohoClientId, campaignId, activate);
      setZohoState({
        ...zohoState,
        triggerReloadReports: zohoState.triggerReloadReports + 1,
      });

      const newZohoClients = zohoClients.map((zohoClient) => {
        if (zohoClient.zohoClientId === accountInfo.zohoClientId) {
          return { ...zohoClient, isActive: activate };
        }
        return zohoClient;
      });
      setZohoClients(newZohoClients);

      showNotification("success", t("salesAnalytics.savedMessage"));
    } finally {
      setSaveLoading(false)
    }
  }

  const onChangeActivate = (value: boolean) => {
    setActivate(value);
  }

  useEffect(() => {
    setZohoState({
      ...zohoState,
      triggerReloadReports: 0,
    });
  }, [zohoState.triggerReloadReports]);

  useEffect(() => {
    if (accountInfo && accountInfo.status === ZClientConnectStatus.Error && accountInfo.statusMessage !== ZohoSyncStatus.ZohoMissingOwner && accountInfo.statusMessage !== ZohoSyncStatus.ZohoSyncFailed) {
      setStatus(IntegrationStatus.ConnectionZohoLostError);
    }
  }, [accountInfo])

  useEffect(() => {
    if (accountInfo) {
      setActivate(accountInfo.isActive);
      setCampaignId(accountInfo.zohoCampaignId);
    }
  }, [accountInfo])

  return (
    <div className="integration-form__section">
      <IntegrationAlert
        status={status || accountInfo.statusMessage}
        onReAuthenticate={() => connectZoho(true)}
        onSyncDataZoho={onSyncData}
        reAuthenticateLoading={connectZohoLoading}
      />
      <div className="account-info">
        {
          isSalesAnalytics && <div className="account-info-item">
            <div className="label">{t("salesAnalytics.activate")}</div>
            <Switch
              checked={activate}
              onChange={(value) => onChangeActivate(value)}
            />
          </div>
        }
        <div className="account-info-item">
          <div className="label">{t("generalSettings.connectionStatus")}</div>
          <div className="value">{renderStatus()}</div>
        </div>
        <div className="account-info-item">
          <div className="label">{t("generalSettings.account")}</div>
          <div className="value">{accountInfo?.email || "-"}</div>
        </div>
        <div className="account-info-item">
          <div className="label">{t("salesAnalytics.organization")}</div>
          <div className="value">{accountInfo?.orgName}</div>
        </div>

        <div className="account-info-item">
          <div className="label">{t("generalSettings.connectedBy")}</div>
          <div className="value">{accountInfo?.createdBy || "-"}</div>
        </div>
        <div className="account-info-item">
          <div className="label">{t("generalSettings.connectedOn")}</div>
          <div className="value">
            {accountInfo?.createdOn ? DateUtils.getDateWithTimezone(
              accountInfo?.createdOn,
              timezoneId
            ).format("MMMM DD,YYYY hh:mm a") : "-"}
          </div>
        </div>
        <div className="account-info-item">
          <div className="label">{t("generalSettings.lastSynced")}</div>
          <div className="value">
            {accountInfo?.latestSyncOn ?
              DateUtils.getDateWithTimezone(
                accountInfo?.latestSyncOn,
                timezoneId
              ).format("MMMM DD,YYYY hh:mm a") : "-"}
          </div>
        </div>
        <div className="account-info-item">
          <div className="label">{t("generalSettings.lastSyncedBy")}</div>
          <div className="value">{accountInfo?.latestSyncBy || "-"}</div>
        </div>
      </div>



      {
        isSalesAnalytics && <Form
          name="createSiteForm"
          layout="vertical"
          onFinish={handleSubmit}
          form={form}
          autoComplete="off"
          initialValues={{ campaignId: accountInfo.zohoCampaignId }}
        >
          <Form.Item
            label={t("salesAnalytics.clientCampaign")}
            name="campaignId"
            rules={[
              { required: true, message: t("salesAnalytics.campaignRequired") },
            ]}
          >
            <AppSelectCampaign
              disabled={!activate || (accountInfo.isAdminConnect && !isAdmin)}
              onChange={(value: number) => setCampaignId(value)}
              orgId={accountInfo.orgId}
            />
          </Form.Item>
        </Form>
      }

      <div className="group-btn">
        <Button type="text" className="remove-btn" onClick={removeAccount}>
          {t("common.remove")}
        </Button>
        <div className="right-btns" >
          <Button
            className="secondary-btn"
            type="primary"
            loading={checkConnectedLoading}
            onClick={testConnection}
          >
            {t("clientSites.testConnection")}
          </Button>

          {
            isSalesAnalytics && <Button
              className="save-btn primary-btn"
              type="primary"
              onClick={() => form.submit()}
              loading={saveLoading}
              disabled={activate === accountInfo.isActive && campaignId === accountInfo.zohoCampaignId}
            >
              {t("common.save")}
            </Button>
          }
        </div>
      </div>
    </div>
  );
}

export default ZohoCRMPanel;
